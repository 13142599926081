import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import BudgetRequestForm from '../components/BudgetRequest/BudgetRequestForm';
import { TitleInstitutionalPages } from '../components/InstitucionalPages/InstitucionalPages';
import StoreLocations from '../components/StoreLocations';
import { MainContainer } from '../styles';

const ContactsPage = () => {
  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box textAlign='center'>
              <TitleInstitutionalPages variant='h1'>
                Contactos
              </TitleInstitutionalPages>
              <Typography marginTop={2}>
                Fale connosco, temos uma equipa ao seu dispor.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box marginTop={{ xs: 3, md: 6 }}>
              <StoreLocations />
            </Box>
          </Grid>
          <Grid
            xs={12}
            md={6}
            mdOffset={3}
          >
            <Box marginTop={{ xs: 5, md: 6 }}>
              <BudgetRequestForm />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ContactsPage;
