import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Icon,
  Switch,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { FiGrid, FiList } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { ProductsListContext } from '../../contexts/ProductsListContext';
import { setPriceVisibility } from '../../state/userSlice';
import {
  CUSTOM_THEME_COLORS,
  SETTINGS,
  TRANSITION_DURATION,
} from '../../variables';
import ProductFilterPopover from '../ProductFilterPopover';
import ProductsSortBy from './ProductsSortBy';

const SetLayoutButton = styled(Icon)(({ active }) => ({
  backgroundColor: active === 'true' && CUSTOM_THEME_COLORS.gray,
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  cursor: 'pointer',
  transition: `background-color ${TRANSITION_DURATION}ms ease`,
  '&:hover': {
    backgroundColor: CUSTOM_THEME_COLORS.gray,
  },
}));

const ProductsListToolbar = () => {
  const { pagination, filters, layout, setLayout } =
    useContext(ProductsListContext);

  const [cookies] = useCookies(['userToken']);
  const dispatch = useDispatch();

  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const user = useSelector((state) => state.user);
  const { priceIsVisible } = user;

  const SORT_OPTIONS = [
    {
      id: 'name:asc',
      text: 'Nome A-Z',
    },
    {
      id: 'name:desc',
      text: 'Nome Z-A',
    },
    {
      id: 'price:asc',
      text: 'Preço Ascendente',
    },
    {
      id: 'price:desc',
      text: 'Preço Descendente',
    },
    // {
    //   id: 'date:desc',
    //   text: 'Mais Recente',
    // },
    // {
    //   id: 'date:asc',
    //   text: 'Mais Antigo',
    // },
  ];

  return (
    <>
      {!MOBILE_DEVICES ? (
        <Box
          sx={{
            display: 'flex',
            margin: '0 0 0 auto',
            justifyContent: 'flex-end',
          }}
        >
          {SETTINGS.functionalities.hidePrices && !!cookies.userToken && (
            <>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={!priceIsVisible} />}
                  title='Ocultar preço'
                  label='Ocultar preço'
                  slotProps={{ typography: { variant: 'small' } }}
                  onChange={() => dispatch(setPriceVisibility(!priceIsVisible))}
                  sx={{ marginRight: 0, marginLeft: 1 }}
                />
              </FormGroup>
              <Divider
                orientation='vertical'
                flexItem
                sx={{
                  borderColor: CUSTOM_THEME_COLORS.gray,
                  marginX: 2,
                }}
              />
            </>
          )}
 
          <ProductsSortBy options={SORT_OPTIONS} />

          <Divider
            orientation='vertical'
            flexItem
            sx={{
              borderColor: CUSTOM_THEME_COLORS.gray,
              marginLeft: 1,
              marginRight: 2,
            }}
          />

          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <SetLayoutButton
              title='Ver em grelha'
              active={layout === 'grid' ? 'true' : 'false'}
              onClick={() => setLayout('grid')}
            >
              <FiGrid size={16} />
            </SetLayoutButton>

            <SetLayoutButton
              title='Ver em lista'
              active={layout === 'list' ? 'true' : 'false'}
              onClick={() => setLayout('list')}
            >
              <FiList size={16} />
            </SetLayoutButton>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: { xs: '100%', md: 'initial' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 3,
            marginTop: 1,
          }}
        >
          <ProductFilterPopover
            availableFilters={filters.list}
            activeFilters={filters.active}
            setPage={pagination.onChange}
          />
          <ProductsSortBy options={SORT_OPTIONS} />
        </Box>
      )}
    </>
  );
};

export default ProductsListToolbar;
