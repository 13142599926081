import {
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { capitalize, kebabCase } from 'lodash';
import React, { useContext } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useParams, useSearchParams } from 'react-router-dom';
import { ProductsListContext } from '../contexts/ProductsListContext';
import { ProductsFilters } from '../styles';
import { FONT_STYLE } from '../variables';

const ProductFilter = ({ index, filter, activeFilters }) => {
  const { id, title, options } = filter;
  const { subcategorySlug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeOptions = activeFilters[id] || [];

  const { setPagination } = useContext(ProductsListContext);

  const getFirstFilterAvailable = index === 0 ? filter.id : null;
  const getActiveFilter = Boolean(
    Object.keys(activeFilters).find((filterName) => filterName === id)
  );

  function handleFilterChange(checked, optionSelected) {
    if (checked) {
      const paramAlreadyExists = searchParams.has(id);
      if (paramAlreadyExists) {
        const oldValue = searchParams.get(id);
        searchParams.set(id, [oldValue].concat(optionSelected));
      } else {
        searchParams.set(id, optionSelected);
      }
    } else {
      const oldValue = searchParams.get(id);
      const oldValueArray = oldValue.split(',');
      const oldValueFiltered = oldValueArray.filter(
        (e) => e !== optionSelected
      );
      if (oldValueFiltered.length > 0) {
        searchParams.set(id, oldValueFiltered);
      } else {
        searchParams.delete(id);
      }
    }

    const pageSearchParam = searchParams.get('page');
    if (pageSearchParam) {
      //  reset pagination number to 1
      setPagination((prevState) => ({
        ...prevState,
        current: 1,
      }));
      //  remove 'page' param from 'url search params'
      searchParams.delete('page');
    }

    setSearchParams(searchParams);
  }

  return (
    <ProductsFilters
      defaultExpanded={!!getFirstFilterAvailable || getActiveFilter}
    >
      <AccordionSummary
        expandIcon={
          <FiChevronDown
            size={20}
            color='#000'
          />
        }
      >
        <Typography
          sx={{
            fontSize: '0.9rem',
            fontWeight: FONT_STYLE.medium,
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>

      <Divider />

      <AccordionDetails>
        <FormGroup>
          {options.map((option, index) => {
            const optionName = kebabCase(option);
            const optionTitle = capitalize(option);
            return (
              <FormControlLabel
                key={`filter-${optionName}-${index}`}
                label={optionTitle}
                title={optionTitle}
                control={
                  <Checkbox
                    disabled={subcategorySlug && subcategorySlug === optionName}
                    checked={activeOptions.includes(optionName)}
                    onChange={(e) =>
                      handleFilterChange(e.target.checked, optionName)
                    }
                  />
                }
                sx={{
                  marginRight: 0,
                  '.MuiCheckbox-root': {
                    paddingTop: 0.75,
                    paddingBottom: 0.75,
                  },
                  '.MuiFormControlLabel-label': {
                    fontSize: '0.9rem',
                    lineHeight: '1.4',
                  },
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              />
            );
          })}
        </FormGroup>
      </AccordionDetails>
    </ProductsFilters>
  );
};

export default React.memo(ProductFilter);
