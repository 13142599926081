import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { TitleInstitutionalPages } from '../components/InstitucionalPages/InstitucionalPages';
import { MainContainer } from '../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../variables';

const Distribution = () => {
  const [selectedDistrict, setSelectedDistrict] = useState();

  const content = [
    {
      id: 0,
      image: `${process.env.PUBLIC_URL}/distribution-viana-do-castelo.jpg`,
      districtName: 'Viana do Castelo',
      cities: [
        {
          name: 'Arcos de Valdevez',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: true,
            thursday: false,
            friday: false,
          },
        },
        {
          name: 'Caminha',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
        {
          name: 'Melgaço',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
        {
          name: 'Monção',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
        {
          name: 'Paredes de Coura',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
        {
          name: 'Ponte da Barca',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: true,
            thursday: false,
            friday: false,
          },
        },
        {
          name: 'Ponte de Lima',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: true,
            thursday: false,
            friday: false,
          },
        },
        {
          name: 'Valença',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
        {
          name: 'Viana do Castelo',
          weekDays: {
            monday: false,
            tuesday: true,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
        {
          name: 'Vila Nova de Cerveira',
          weekDays: {
            monday: false,
            tuesday: true,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
      ],
    },
    {
      id: 1,
      image: `${process.env.PUBLIC_URL}/distribution-braga.jpg`,
      districtName: 'Braga',
      cities: [
        {
          name: 'Amares',
          weekDays: {
            monday: true,
            tuesday: false,
            wednesday: true,
            thursday: false,
            friday: true,
          },
        },
        {
          name: 'Barcelos',
          weekDays: {
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
          },
        },
        {
          name: 'Braga',
          weekDays: {
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
          },
        },
        {
          name: 'Cabeceiras de Basto',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
        {
          name: 'Celorico de Basto',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
        {
          name: 'Esposende',
          weekDays: {
            monday: true,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: true,
          },
        },
        {
          name: 'Fafe',
          weekDays: {
            monday: true,
            tuesday: false,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
        {
          name: 'Guimarães',
          weekDays: {
            monday: true,
            tuesday: false,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
        {
          name: 'Póvoa de Lanhoso',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
        {
          name: 'Terras Bouro',
          weekDays: {
            monday: true,
            tuesday: false,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
        {
          name: 'Vieira do Minho',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: true,
            thursday: false,
            friday: false,
          },
        },
        {
          name: 'Vila Nova de Famalicão',
          weekDays: {
            monday: true,
            tuesday: false,
            wednesday: true,
            thursday: false,
            friday: true,
          },
        },
        {
          name: 'Vila Verde',
          weekDays: {
            monday: true,
            tuesday: false,
            wednesday: true,
            thursday: false,
            friday: true,
          },
        },
        {
          name: 'Vizela',
          weekDays: {
            monday: true,
            tuesday: false,
            wednesday: false,
            thursday: true,
            friday: true,
          },
        },
        {
          name: 'Montalegre',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: true,
            friday: false,
          },
        },
      ],
    },
    {
      id: 2,
      image: `${process.env.PUBLIC_URL}/distribution-porto.jpg`,
      districtName: 'Porto',
      cities: [
        {
          name: 'Ermesinde',
          weekDays: {
            monday: true,
            tuesday: false,
            wednesday: true,
            thursday: false,
            friday: true,
          },
        },
        {
          name: 'Maia',
          weekDays: {
            monday: true,
            tuesday: false,
            wednesday: true,
            thursday: false,
            friday: true,
          },
        },
        {
          name: 'Porto',
          weekDays: {
            monday: true,
            tuesday: false,
            wednesday: true,
            thursday: false,
            friday: true,
          },
        },
        {
          name: 'Póvoa de Varzim',
          weekDays: {
            monday: false,
            tuesday: true,
            wednesday: false,
            thursday: false,
            friday: true,
          },
        },
        {
          name: 'Santo Tirso',
          weekDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: true,
          },
        },
        {
          name: 'Valongo',
          weekDays: {
            monday: true,
            tuesday: false,
            wednesday: true,
            thursday: false,
            friday: true,
          },
        },
        {
          name: 'Vila das Aves',
          weekDays: {
            monday: false,
            tuesday: true,
            wednesday: false,
            thursday: false,
            friday: false,
          },
        },
        {
          name: 'Vila do Conde',
          weekDays: {
            monday: false,
            tuesday: true,
            wednesday: false,
            thursday: false,
            friday: true,
          },
        },
      ],
    },
  ];

  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box textAlign='center'>
              <TitleInstitutionalPages variant='h1'>
                Distribuição
              </TitleInstitutionalPages>
              <Typography marginTop={2}>
                Atualmente presentes nos seguintes distritos
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: '100%',
                  sm: 'auto auto',
                  md: 'auto auto auto',
                },
                gap: 2.5,
                marginTop: 4,
              }}
            >
              {content.map(({ id, image, districtName }, index) => {
                return (
                  <Card
                    key={`distribution-card-${index}`}
                    sx={{
                      position: 'relative',
                    }}
                  >
                    {image && (
                      <CardMedia
                        component='img'
                        image={image}
                        sx={{
                          filter: 'brightness(60%)',
                          aspectRatio: '3/2',
                        }}
                      />
                    )}

                    <CardContent
                      sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      {districtName && (
                        <Typography
                          sx={{
                            fontSize: '1.75rem',
                            fontWeight: FONT_STYLE.black,
                            color: 'white',
                          }}
                        >
                          {districtName}
                        </Typography>
                      )}
                      <Button
                        title='Veja os dias de distribuição'
                        variant='contained'
                        sx={{
                          marginTop: 2,
                        }}
                        onClick={() =>
                          setSelectedDistrict(
                            content.find((district) => district.id === id)
                          )
                        }
                      >
                        Veja os dias de distribuição
                      </Button>
                    </CardContent>
                  </Card>
                );
              })}
            </Box>
          </Grid>

          {!!selectedDistrict && (
            <Grid
              xs={12}
              mdOffset={2}
              md={8}
            >
              <Box
                sx={{
                  marginTop: 4,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.75rem',
                    fontWeight: FONT_STYLE.black,
                    textAlign: 'center',
                  }}
                >
                  {selectedDistrict.districtName}
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{ marginTop: 2 }}
                >
                  <Table
                    size='small'
                    sx={{
                      '& .MuiTableCell-root': {
                        whiteSpace: 'nowrap',
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: '190px' }}></TableCell>
                        <TableCell>Segunda-Feira</TableCell>
                        <TableCell>Terça-Feira</TableCell>
                        <TableCell>Quarta-Feira</TableCell>
                        <TableCell>Quinta-Feira</TableCell>
                        <TableCell>Sexta-Feira</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedDistrict.cities.map(
                        ({ name, weekDays }, index) => (
                          <TableRow key={`distribution-city-days-${index}`}>
                            <TableCell
                              sx={{
                                fontWeight: 700,
                                color: CUSTOM_THEME_COLORS.primary,
                                textAlign: 'left !important',
                              }}
                            >
                              {name}
                            </TableCell>
                            <TableCell>
                              {weekDays.monday && <FiCheck size={20} />}
                            </TableCell>
                            <TableCell>
                              {weekDays.tuesday && <FiCheck size={20} />}
                            </TableCell>
                            <TableCell>
                              {weekDays.wednesday && <FiCheck size={20} />}
                            </TableCell>
                            <TableCell>
                              {weekDays.thursday && <FiCheck size={20} />}
                            </TableCell>
                            <TableCell>
                              {weekDays.friday && <FiCheck size={20} />}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default Distribution;
