import { Box, Dialog, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addShoppingList } from '../../../api/user';
import { setShoppingLists } from '../../../state/shoppingListsSlice';
import { StyledTextButton } from '../../../styles';
import PopupNotification from '../../PopupNotification/PopupNotification';
import kebabCase from 'lodash/kebabCase';
import { FONT_STYLE } from '../../../variables';

const CreateShoppingListModal = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [newShoppingListName, setNewShoppingListName] = useState('');

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: 'Nova lista de compras adicionada',
    errorMessage: '',
  });

  const handleCloseDialog = () => {
    setNewShoppingListName('');
    setIsOpen(false);
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (!newShoppingListName) return;

    const shoppingListNameSlugified = kebabCase(newShoppingListName);

    addShoppingList(shoppingListNameSlugified)
      .then((lists) => {
        dispatch(setShoppingLists(lists));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));

        setNewShoppingListName('');
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));

            setNewShoppingListName('');
          }
        }
      );

    setIsOpen(false);
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => handleCloseDialog()}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '400px',
            padding: 3,
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: FONT_STYLE.bold,
            textTransform: 'uppercase',
          }}
        >
          Nova Lista de Compras
        </Typography>

        <Box
          component='form'
          onSubmit={handleSubmit}
        >
          <TextField
            label='Nome'
            name='newShoppingListName'
            value={newShoppingListName}
            inputProps={{ pattern: '^[a-zA-Z][^$]+' }}
            onChange={(e) => setNewShoppingListName(e.target.value)}
            fullWidth
            required
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              marginTop: 3,
            }}
          >
            <StyledTextButton
              variant='contained'
              title='Cancelar'
              color='secondary'
              onClick={() => handleCloseDialog()}
            >
              Cancelar
            </StyledTextButton>

            <StyledTextButton
              variant='contained'
              title='Adicionar'
              type='submit'
            >
              Adicionar
            </StyledTextButton>
          </Box>
        </Box>
      </Dialog>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default CreateShoppingListModal;
