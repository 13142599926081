import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { IconContext } from 'react-icons';
import { BsCheckCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { sendBudgetRequest } from '../../api';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../variables';
import PopupNotification from '../PopupNotification/PopupNotification';

const BudgetRequestForm = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: 'Pedido submetido com sucesso',
    errorMessage: '',
  });
  const [formData, setFormData] = useState({
    name: undefined,
    email: undefined,
    phone: undefined,
    message: undefined,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    sendBudgetRequest(formData)
      .then(() => {
        setIsLoading(false);
        setShowConfirmation(true);
        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
      })
      .catch(({ response: { status, data } }) => {
        const errorMessage =
          data?.msg || data?.errors?.[0]?.nestedErrors?.[0]?.msg;
        if (status === 401) {
          navigate('/login');
        } else if (status === 400 || status > 401) {
          setIsLoading(false);
          setNotificationProps((prevState) => ({
            ...prevState,
            isOpened: true,
            type: 'error',
            errorMessage: errorMessage,
          }));
        }
      });
  };

  const ConfirmationMessage = () => {
    return (
      <Box
        sx={{
          marginTop: 3,
        }}
      >
        <IconContext.Provider
          value={{
            style: { color: CUSTOM_THEME_COLORS.lightGreen, margin: 1 },
          }}
        >
          <BsCheckCircle size={50} />
        </IconContext.Provider>
        <Typography
          sx={{
            marginTop: 1,
            fontWeight: FONT_STYLE.bold,
          }}
        >
          Pedido submetido com sucesso
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <Typography variant='h2'>Pedido de Orçamento</Typography>
      <Typography marginTop={2}>
        Mais informações? Preencha o seguinte formulário. <br /> Entraremos em
        contacto com a maior brevidade possível.
      </Typography>

      {showConfirmation ? (
        <ConfirmationMessage />
      ) : (
        <Box
          component='form'
          onSubmit={handleSubmit}
          autoComplete='off'
          sx={{
            width: { md: '75%' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginX: 'auto',
            marginTop: 1,
          }}
        >
          <TextField
            fullWidth
            type='text'
            name='name'
            required
            label='Nome'
            value={formData.name}
            onChange={handleChange}
            error={formData.name === ''}
            helperText={formData.name === '' && 'Campo obrigatório.'}
          />
          <TextField
            fullWidth
            type='email'
            name='email'
            required
            label='Email'
            value={formData.email}
            onChange={handleChange}
            error={formData.email === ''}
            helperText={formData.email === '' && 'Campo obrigatório.'}
          />
          <TextField
            fullWidth
            type='phone'
            name='phone'
            required
            label='Contacto'
            value={formData.phone}
            onChange={handleChange}
            error={formData.phone === ''}
            helperText={formData.phone === '' && 'Campo obrigatório.'}
          />
          <TextField
            name='message'
            label='Mensagem'
            required
            multiline
            rows={3}
            defaultValue=''
            fullWidth
            value={formData.message}
            onChange={handleChange}
            error={formData.message === ''}
            helperText={formData.message === '' && 'Campo obrigatório.'}
          />
          <LoadingButton
            variant='contained'
            type='submit'
            title='Submeter pedido'
            loading={isLoading}
            sx={{
              marginTop: 3,
            }}
          >
            Submeter pedido
          </LoadingButton>
        </Box>
      )}

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </Box>
  );
};

export default BudgetRequestForm;
