import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Divider,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { MainContainer } from '../styles';

const Faqs = () => {
  const faqsContent = [
    {
      title: 'Como pode conhecer a gama de produtos que comercializamos',
      description: `<p>Nas nossas instalações dispomos de um amplo mostruário, onde os clientes podem conhecer toda a nossa gama de artigos. Caso não seja possível a deslocação à nossa empresa, dispomos de uma equipa de comerciais que acompanhará todos os nossos clientes de forma rápida e eficaz.</p>
      <p>Como pode solicitar o nosso contacto para obter informações ?</p>
      <p>Caso pretenda ser contactado por nós, deve enviar-nos um email para geral@imporvelas.com ou contactar-nos através dos seguintes números de telefone 253 628 372.</p>
      <p>O departamento comercial da IMPORVELAS entrará em contacto num prazo máximo de 24 horas.</p>`,
    },
    {
      title: 'Como pode aceder aos nossos preços',
      description: `<p>Poderá ter acesso aos nosso preços, assim que fizer chegar um e-mail para geral@imporvelas.com com as referência que pretende e as respetivas quantidades.</p>
      <p>De momento não nos é possivel ter uma tabela fixa devido aos preços serem inconstantes.</p>
      <p>Todos os artigos serão facturados incluindo o valor do IVA à taxa em vigor.</p>`,
    },
    {
      title: 'Que condições são necessárias para poder comprar na IMPORVELAS',
      description: `<p>A IMPORVELAS apenas trabalha com profissionais e por isso torna-se necessário solicitar algumas informações, para que nos seja possível garantir um serviço exclusivo a profissionais:</p>
      <ul>
      <li>Nome Completo (como Registado nas Finanças);</li>
      <li>Morada Completa;</li>
      <li>Nº Fiscal / Contribuinte;</li>
      <li>Nº Telefónico;</li>
      <li>E-mail.</li>
      </ul>`,
    },
  ];

  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Typography
              variant='h1'
              sx={{ textAlign: 'center', marginBottom: 3 }}
            >
              Faqs
            </Typography>
          </Grid>
          <Grid
            xs={12}
            md={10}
            mdOffset={1}
            lg={8}
            lgOffset={2}
          >
            {faqsContent.map((item) => {
              return (
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <FiChevronDown
                        size={20}
                        color='#000'
                      />
                    }
                  >
                    <Typography fontWeight={600}>{item.title}</Typography>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default Faqs;
