import { Box, Button, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  FONT_STYLE,
  LOGIN_FORM_TITLE,
  LOGIN_PASS_RECOVERY_TEXT,
  LOGIN_REGISTER_TEXT,
  PAGES_SLUGS,
} from '../../variables';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <Box
      sx={{
        width: { xs: '80vw', md: '30vw' },
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography sx={{ textAlign: 'center', fontWeight: FONT_STYLE.bold }}>
        {LOGIN_FORM_TITLE}
      </Typography>

      <LoginForm />

      <Typography
        component={Link}
        variant='link'
        to='recuperar-palavra-passe'
        title={LOGIN_PASS_RECOVERY_TEXT}
        sx={{ fontSize: '0.9rem', textAlign: 'center' }}
      >
        {LOGIN_PASS_RECOVERY_TEXT}
      </Typography>

      <Divider sx={{ width: '100%', marginY: 2 }} />

      <Box
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}
      >
        <Box>
          <Typography sx={{ textAlign: 'center' }}>
            {LOGIN_REGISTER_TEXT}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              marginTop: 2,
            }}
          >
            <Button
              component={Link}
              to={PAGES_SLUGS.createUser}
              variant='contained'
              color='secondary'
              title='Nova conta cliente'
            >
              Nova conta cliente
            </Button>
          </Box>
        </Box>
        <Box>
          <Typography sx={{ textAlign: 'center' }}>
            Já é cliente Frescas Surpresas?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              marginTop: 2,
            }}
          >
            <Button
              component={Link}
              to={PAGES_SLUGS.createFastUser}
              variant='contained'
              color='secondary'
              title='Registo rápido'
            >
              Registo rápido
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
