import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';
import { COMPANY_INFO, FONT_STYLE, FOOTER_MENUS } from '../../variables';
import { ContactUsContent } from './ContactUs';

const StyledAccordion = styled(Accordion)(() => ({
  borderBottom: '1px solid rgba(255, 255, 255, 0.40)',
  color: 'white',
}));

const StyledLabel = styled(Typography)(() => ({
  fontWeight: FONT_STYLE.bold,
  textAlign: { xs: 'center', md: 'initial' },
}));

const StyledLink = styled(Typography)(({ theme }) => ({
  display: 'block',
  color: 'inherit',
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  '.MuiAccordionSummary-expandIconWrapper': {
    color: 'white',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(3)}`,
}));

const FooterMobileAccordion = () => {
  const {
    address: { street, zipCode, city },
    mobile,
    phone,
    fax,
    email,
  } = COMPANY_INFO;
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ marginTop: 2.5 }}>
      <StyledAccordion
        disableGutters
        expanded={expanded === 'aboutUs'}
        onChange={handleChange('aboutUs')}
      >
        <StyledAccordionSummary
          expandIcon={expanded === 'aboutUs' ? <FiMinus /> : <FiPlus />}
        >
          <StyledLabel>{COMPANY_INFO.name}</StyledLabel>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          {FOOTER_MENUS.menu1.map((menuItem, index) => (
            <StyledLink
              key={`footer-menu-${menuItem.title}-${index}`}
              component={menuItem.internalLink ? RouterLink : 'a'}
              to={menuItem.internalLink ? menuItem.url : null}
              href={!menuItem.internalLink ? menuItem.url : null}
              target={!menuItem.internalLink ? menuItem.target : null}
              title={menuItem.title}
              variant='link'
              marginBottom={0.5}
            >
              {menuItem.title}
            </StyledLink>
          ))}
        </StyledAccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        disableGutters
        expanded={expanded === 'customerSupport'}
        onChange={handleChange('customerSupport')}
      >
        <StyledAccordionSummary
          expandIcon={expanded === 'customerSupport' ? <FiMinus /> : <FiPlus />}
        >
          <StyledLabel>Informações</StyledLabel>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          {FOOTER_MENUS.menu2.map((menuItem, index) => (
            <StyledLink
              key={`footer-menu-${menuItem.title}-${index}`}
              component={menuItem.internalLink ? RouterLink : 'a'}
              to={menuItem.internalLink ? menuItem.url : null}
              href={!menuItem.internalLink ? menuItem.url : null}
              target={!menuItem.internalLink ? menuItem.target : null}
              title={menuItem.title}
              variant='link'
              marginBottom={0.5}
            >
              {menuItem.title}
            </StyledLink>
          ))}
        </StyledAccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        disableGutters
        expanded={expanded === 'contactUs'}
        onChange={handleChange('contactUs')}
      >
        <StyledAccordionSummary
          expandIcon={expanded === 'contactUs' ? <FiMinus /> : <FiPlus />}
        >
          <StyledLabel>Contacte-nos</StyledLabel>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <ContactUsContent>
            {street} <br /> {zipCode}
            <br />
            {city}
          </ContactUsContent>

          {mobile.value && (
            <Box sx={{ marginTop: 1.5 }}>
              <ContactUsContent
                component={'a'}
                href={`tel:${mobile.value}`}
                title={mobile.label}
              >
                <strong>Tel. </strong>
                {mobile.label}
              </ContactUsContent>
              <Typography variant='xsmall'>
                (Chamada para a rede móvel nacional)
              </Typography>
            </Box>
          )}

          {phone.value && (
            <Box sx={{ marginTop: 1.5 }}>
              <ContactUsContent
                component={'a'}
                href={`tel:${phone.value}`}
                title={phone.label}
              >
                <strong>Telf. </strong>
                {phone.label}
              </ContactUsContent>
              <Typography variant='xsmall'>
                (Chamada para a rede fixa nacional)
              </Typography>
            </Box>
          )}

          {fax.value && (
            <ContactUsContent sx={{ marginTop: 1.5 }}>
              <strong>Fax. </strong>
              {fax.label}
            </ContactUsContent>
          )}

          {email && (
            <ContactUsContent
              component={'a'}
              href={`mailto:${email}`}
              title={email}
              sx={{
                marginTop: 1.5,
              }}
            >
              <strong>E.</strong> {email}
            </ContactUsContent>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default FooterMobileAccordion;
