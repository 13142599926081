import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { DELIVERY_METHODS, FONT_STYLE } from '../../../../variables';
import DeliveryAtAddress from './DeliveryAtAddress';
import DeliveryAtStore from './DeliveryAtStore';

const DetailsForm = ({
  shipping,
  onShippingChange,
  address,
  onStoreChange,
  store,
  onObservationsChange,
  paymentTime,
  onAddressChange,
  role,
  clientAddresses,
}) => {
  const isDeliveryAtAddress = shipping === DELIVERY_METHODS.ADDRESS;
  const isDeliveryAtStore = shipping === DELIVERY_METHODS.STORE;

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '80%', md: 'auto' },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <FormControl>
        <Typography
          variant='h3'
          sx={{
            fontWeight: FONT_STYLE.bold,
            marginBottom: 1,
          }}
        >
          Pagamento
        </Typography>

        <TextField
          label='Método De Pagamento'
          defaultValue='Transferência Bancária'
          InputProps={{
            disabled: true,
          }}
        />

        <TextField
          label='Prazo de Pagamento'
          defaultValue={`${paymentTime} dias`}
          InputProps={{
            disabled: true,
          }}
        />

        <TextField
          id='outlined-multiline-static'
          label='Observações'
          multiline
          rows={3}
          defaultValue=''
          onChange={onObservationsChange}
        />

        <Box sx={{ marginTop: 4 }}>
          <Box
            sx={{
              marginBottom: 1,
            }}
          >
            <Typography
              variant='h3'
              sx={{
                fontWeight: FONT_STYLE.bold,
              }}
            >
              Envio
            </Typography>
            <Typography
              variant='small'
              sx={{
                marginTop: 1,
              }}
            >
              Poderá escolher apenas um método de envio.
            </Typography>
          </Box>

          <RadioGroup
            value={shipping}
            onChange={onShippingChange}
            name='shipping-options'
          >
            {DELIVERY_METHODS.ADDRESS && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormControlLabel
                  value={DELIVERY_METHODS.ADDRESS}
                  control={<Radio />}
                  title='Entrega em morada'
                  label='Entrega em morada'
                />
                {isDeliveryAtAddress && (
                  <DeliveryAtAddress
                    address={address}
                    onAddressChange={onAddressChange}
                    role={role}
                    clientAddresses={clientAddresses}
                  />
                )}
              </Box>
            )}

            {DELIVERY_METHODS.STORE && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormControlLabel
                  value={DELIVERY_METHODS.STORE}
                  control={<Radio />}
                  title='Levantamento em armazém'
                  label='Levantamento em armazém'
                />
                {isDeliveryAtStore && (
                  <DeliveryAtStore
                    store={store}
                    onStoreChange={onStoreChange}
                  />
                )}
              </Box>
            )}
          </RadioGroup>
        </Box>
      </FormControl>
    </Box>
  );
};

export default DetailsForm;
