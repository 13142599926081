import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { MainContainer } from '../styles';

const AlternativeDisputeResolution = () => {
  const pageContent = `<p>Em caso de litígio de consumo, o consumidor pode recorrer à Plataforma Europeia de Resolução de Litígios em Linha, disponível em http://ec.europa.eu/consumers/odr ou às seguintes entidades de resolução alternativa de litígios de consumo (ao abrigo da Lei n.º 144/2015, de 8 de setembro):</p>
  <p><strong>Centro de Informação, Mediação e Arbitragem de Consumo (Tribunal Arbitral de Consumo)<br>
Braga</strong><br>
Rua D. Afonso Henriques, nº 1 (Ed. Junta de Freguesia da Sé)<br>
4700-030 Braga<br>
<strong>Tel:</strong> 253 617 604 / Fax: 253 617 605<br>
<strong>E-mail:</strong> geral@ciab.pt<br>
<strong>Web:</strong> <a href="http://www.ciab.pt/pt/" target="_blank" rel="noopener">www.ciab.pt</a></p>`;

  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Typography
              variant='h1'
              sx={{ textAlign: 'center', marginBottom: 3 }}
            >
              Resolução Alternativa de Litígios
            </Typography>
          </Grid>
          <Grid container>
            <Grid
              xs={12}
              md={10}
              mdOffset={1}
              lg={8}
              lgOffset={2}
            >
              <Typography dangerouslySetInnerHTML={{ __html: pageContent }} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default AlternativeDisputeResolution;
