import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FONT_STYLE, SETTINGS } from '../../../variables';
import Quantity from '../../Quantity/Quantity';
import AddShoppingListButton from '../../UserAccount/ShoppingLists/AddShoppingListButton';
import ProductVariantsDetails from '../ProductVariants/ProductVariantsDetails';

const ProductCheckout = ({
  product,
  hasVariants,
  addToCart,
  productsForCart,
  setProductsForCart,
}) => {
  const userRole = useSelector((state) => state.user.role);
  const addToCartDisabled = hasVariants && productsForCart.totalUnits === 0;

  const [updatedProductQuantity, setUpdatedProductQuantity] = useState({
    // SIMPLE PRODUCT
    ...product,
    quantity: {
      ...product.quantity,
      [SETTINGS.defaults.quantityType]: 1,
    },
  });

  useEffect(() => {
    if (hasVariants) return;

    //  IF 'simple product' » ADD/UPDATE 'simple product' inside array
    setProductsForCart((prevState) => ({
      ...prevState,
      products: [updatedProductQuantity],
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProductQuantity]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: hasVariants ? 'space-between' : 'flex-start',
          alignItems: hasVariants ? 'flex-start' : 'center',
          gap: { xs: 5, md: 7 },
          width: '100%',
          marginTop: hasVariants ? 2 : 3,
        }}
      >
        {!!hasVariants ? (
          <ProductVariantsDetails
            productsForCart={productsForCart}
            setProductsForCart={setProductsForCart}
          />
        ) : (
          <Box>
            <Typography
              sx={{
                marginBottom: 1,
                fontWeight: FONT_STYLE.bold,
              }}
            >
              Quantidade
            </Typography>

            <Quantity
              product={updatedProductQuantity}
              setUpdatedProductQuantity={setUpdatedProductQuantity}
            />
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 2,
          }}
        >
          {!!hasVariants && (
            <Typography
              variant='xsmall'
              sx={{
                textAlign: 'right',
              }}
            >
              * Preço sem IVA
            </Typography>
          )}
          <Box
            sx={{
              display: 'inline-flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: { xs: 'center', md: 'initial' },
              alignItems: {
                xs: 'flex-start',
                sm: hasVariants ? 'flex-start' : 'flex-end',
              },
              gap: 2,
            }}
          >
            {SETTINGS.functionalities.shoppingList && userRole !== 'seller' && (
              <AddShoppingListButton productId={product.id} />
            )}

            <Button
              variant='contained'
              title='Adicionar ao carrinho'
              onClick={() => addToCart()}
              disabled={!!addToCartDisabled}
            >
              Adicionar ao carrinho
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProductCheckout;
