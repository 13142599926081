import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { IconContext } from 'react-icons';
import { AiOutlineWarning } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { CUSTOM_THEME_COLORS, PAGES_SLUGS } from '../../variables';
import Modal, { ModalActions, ModalContent } from '../Modal';

const DelayedPaymentsModal = ({ modalIsOpened, setModalIsOpened }) => {
  return (
    <Modal
      isOpened={modalIsOpened}
      setIsOpened={() => setModalIsOpened(false)}
      muiComponentNativeProps={{
        PaperProps: {
          sx: {
            maxWidth: '100%',
            width: '400px',
            padding: 4,
            alignItems: 'center',
          },
        },
      }}
    >
      <ModalContent
        sx={{
          textAlign: 'center',
          marginTop: 0,
          marginBottom: 3,
        }}
      >
        <IconContext.Provider
          value={{ style: { color: CUSTOM_THEME_COLORS.orange } }}
        >
          <AiOutlineWarning size={50} />
        </IconContext.Provider>
        <Typography
          sx={{ marginTop: { xs: 1 } }}
          dangerouslySetInnerHTML={{
            __html: 'Verificamos que existe(m) fatura(s) em aberto.<br> De forma a prosseguir, poderá realizar o pagamento de forma rápida, cómoda e segura através da sua área de cliente.',
          }}
        />
      </ModalContent>
      <ModalActions
        sx={{
          justifyContent: 'space-between',
          marginTop: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'flex-end',
          }}
        >
          <Button
            title='Ver conta corrente'
            variant='contained'
            color='secondary'
            component={Link}
            to={`${PAGES_SLUGS.account}/${PAGES_SLUGS.myAccount.receipts}`}
            onClick={() => setModalIsOpened(false)}
          >
            Ver conta corrente
          </Button>
        </Box>
      </ModalActions>
    </Modal>
  );
};

export default DelayedPaymentsModal;
