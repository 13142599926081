import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../variables';

const ProductSpecificationsContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  '&::before': {
    content: '""',
    width: 'calc(100vw + 50%)',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '-50%',
    backgroundColor: CUSTOM_THEME_COLORS.lightGray,
    zIndex: '-1',
  },
}));

const ProductSpecifications = ({ specifications }) => {
  return (
    <ProductSpecificationsContainer
      sx={{
        marginTop: { xs: 4, md: 7 },
        paddingY: { xs: 3, md: 4 },
      }}
    >
      {specifications.map((param, index) => (
        <Box
          key={`product-specification-${index}`}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'initial' },
            paddingY: { xs: 1, md: 1.5 },
          }}
        >
          <Box sx={{ width: '100%', maxWidth: { xs: '100%', md: '300px' } }}>
            <Typography
              sx={{
                fontWeight: FONT_STYLE.bold,
              }}
            >
              {param.title}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ marginTop: { xs: 1, md: 0 } }}>
              {param.value}
            </Typography>
          </Box>
        </Box>
      ))}
    </ProductSpecificationsContainer>
  );
};

export default ProductSpecifications;
