/*
|--------------------------------
| method to split a string through our necessities
|--------------------------------
*/
export const splitString = (str, maxLength) => {
  var buff = '';
  var numOfLines = Math.floor(str.length / maxLength);
  for (var i = 0; i < numOfLines + 1; i++) {
    buff += str.substr(i * maxLength, maxLength);
    if (i !== numOfLines) {
      buff += '\n';
    }
  }
  return buff;
};
