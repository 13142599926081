import { Box, Typography } from '@mui/material';
import React from 'react';
import { formatCurrency } from '../../constants/utils';
import { BadgeDiscount } from '../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../variables';

const ProductPrices = ({ details }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {details.hasVariants && (
        <Typography
          variant='xsmall'
          sx={{
            lineHeight: 1,
            fontWeight: FONT_STYLE.medium,
            placeSelf: 'flex-end',
          }}
        >
          desde
        </Typography>
      )}

      <Typography
        sx={{
          lineHeight: 1,
          textDecoration: details.onSale && 'line-through',
          fontWeight: FONT_STYLE.bold,
        }}
      >
        {formatCurrency(details.price)}
      </Typography>

      {!!details.onSale && (
        <>
          <Typography
            sx={{
              lineHeight: 1,
              fontWeight: FONT_STYLE.bold,
              color: CUSTOM_THEME_COLORS.red,
            }}
          >
            {formatCurrency(details.salePrice)}
          </Typography>
          {!!details.saleAmount && (
            <BadgeDiscount>{`${details.saleAmount}%`}</BadgeDiscount>
          )}
        </>
      )}
    </Box>
  );
};

export default React.memo(ProductPrices);
