import { Box, styled } from '@mui/material';
import React from 'react';
import { CUSTOM_THEME_COLORS } from '../variables';

export const Badge = styled(Box)(({ theme, badgetype }) => ({
  position: 'absolute',
  top: '0',
  left: '0',
  fontSize: '0.7rem',
  color: 'white',
  padding: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  backgroundColor: badgetype.new
    ? CUSTOM_THEME_COLORS.green
    : badgetype.campaign
    ? CUSTOM_THEME_COLORS.primary
    : null,
}));

const ProductBadge = ({ badge }) => {
  return (
    <Badge badgetype={badge}>
      {badge.campaign ? 'Em Campanha' : badge.new ? 'Novidade' : null}
    </Badge>
  );
};

export default ProductBadge;
