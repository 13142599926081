import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ProductCardContext } from '../../contexts/ProductCardContext';
import { imageOnError } from '../../helpers';
import { productDetailsInterface } from '../../helpers/productDetailsInterface';
import {
  CUSTOM_THEME_COLORS,
  FONT_STYLE,
  PAGES_SLUGS,
  TRANSITION_DURATION,
} from '../../variables';
import ProductBadge from '../ProductBadge';
import ProductStock from '../ProductStock';
import ProductPrices from './ProductPrices';

const ProductListCard = ({
  product,
  showPrice = true,
  showStockAvailability = true,
}) => {
  const theme = useTheme();

  const {
    addToCart,
    setProductVariants,
    setIsVariationsModalOpen,
    customerDiscount,
  } = useContext(ProductCardContext);

  const productDetails = productDetailsInterface(product);

  const {
    slug,
    title,
    images,
    description,
    reference,
    badge,
    variants,
    itemsPerBox,
  } = product;

  const hasVariants = variants.length;

  return (
    <Card
      raised
      sx={{
        marginY: 3,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        title={title}
        component={Link}
        to={`${PAGES_SLUGS.productDetail}/${slug}`}
        sx={{
          position: 'relative',
          maxWidth: '160px',
          flex: 1,
          overflow: 'hidden',
          border: `1px solid ${CUSTOM_THEME_COLORS.gray}`,
          borderRadius: theme.shape.borderRadius / 10,
        }}
      >
        {badge && <ProductBadge badge={badge} />}

        <CardMedia
          component='img'
          image={images?.[0]}
          onError={imageOnError}
          alt={title}
          sx={{ maxWidth: '100%', objectFit: 'contain', aspectRatio: '1' }}
        />
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 2,
          padding: `${theme.spacing(2)} 0 ${theme.spacing(2)} ${theme.spacing(
            3
          )} !important`,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 4,
              marginBottom: 0.5,
            }}
          >
            <Typography
              sx={{
                display: 'flex',
                color: 'black',
                fontWeight: FONT_STYLE.black,
                textDecoration: 'none',
                transition: `color ${TRANSITION_DURATION}ms ease`,
                '&:hover': {
                  color: CUSTOM_THEME_COLORS.primary,
                },
              }}
              title={title}
              component={Link}
              to={`${PAGES_SLUGS.productDetail}/${slug}`}
            >
              {title}
            </Typography>
            {showStockAvailability && (
              <ProductStock stock={productDetails.stock} />
            )}
          </Box>

          {reference && (
            <Typography
              sx={{ color: CUSTOM_THEME_COLORS.darkGray }}
              variant='xsmall'
            >{`Ref: ${reference}`}</Typography>
          )}

          {!!itemsPerBox && (
            <Typography
              variant='xsmall'
              sx={{
                color: CUSTOM_THEME_COLORS.darkGray,
              }}
            >
              Embalagem: {itemsPerBox} uni.
            </Typography>
          )}

          {!description && (
            <Typography
              variant='small'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                lineClamp: 3,
                WebkitBoxOrient: 'vertical',
                marginTop: 1.5,
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: 2,
          }}
        >
          {showPrice && (
            <ProductPrices
              details={productDetails}
              customerDiscount={customerDiscount}
            />
          )}
          <Box>
            <Button
              variant='contained'
              title='Adicionar ao carrinho'
              onClick={() =>
                hasVariants
                  ? (setProductVariants(variants),
                    setIsVariationsModalOpen(true))
                  : addToCart(product)
              }
            >
              Adicionar ao carrinho
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductListCard;
