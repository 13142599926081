import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getBuyAuthorization } from '../api/user';
import CartProgressIndicator from '../components/Cart/CartProgressIndicator';
import CartSummary from '../components/Cart/FirstStepSummary/CartSummary';
import OrderConfirmation from '../components/Cart/OrderConfirmation';
import OrderDetails from '../components/Cart/SecondStepOrderDetails/OrderDetails';
import { setCartObject } from '../state/cartSlice';
import { MainContainer } from '../styles';

const CartPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [orderConfirmationMessage, setOrderConfirmationMessage] = useState('');

  const state = useSelector((state) => state);
  const { cart: cartObject, user } = state;
  const { cart, subtotal, delayedPayments } = cartObject;
  const { id, selectedClient, role } = user;
  const customerId = selectedClient?.id || id;

  function handleActiveStep(step) {
    const element = document.querySelector('.app');
    element.scrollTo({ top: 0, behavior: 'smooth' });
    setActiveStep(step);
  }

  useEffect(() => {
    getBuyAuthorization(customerId)
      .then((data) => {
        if (data.delayedPayments !== delayedPayments) {
          dispatch(
            setCartObject({
              delayedPayments: data.delayedPayments,
            })
          );
        }
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid xs={12}>
            {cart.length === 0 && activeStep !== 2 ? (
              <Box sx={{ textAlign: 'center' }}>
                <Typography>O seu carrinho está vazio</Typography>
                <Button
                  title='Voltar à homepage'
                  variant='contained'
                  color='secondary'
                  component={Link}
                  to='/'
                  sx={{ marginTop: 2 }}
                >
                  Voltar à homepage
                </Button>
              </Box>
            ) : (
              <>
                <CartProgressIndicator activeStep={activeStep} />

                {activeStep === 0 && (
                  <CartSummary
                    setActiveStep={handleActiveStep}
                    cartItems={cart}
                    cartSubtotal={subtotal}
                    selectedClient={customerId}
                    role={role}
                    delayedPayments={delayedPayments}
                  />
                )}

                {activeStep === 1 && (
                  <OrderDetails
                    setActiveStep={handleActiveStep}
                    cart={cartObject}
                    setOrderConfirmationMessage={setOrderConfirmationMessage}
                  />
                )}

                {activeStep === 2 && <OrderConfirmation message={orderConfirmationMessage} />}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default CartPage;
