import { Box, Grid, Link } from '@mui/material';
import React from 'react';
import { IconContext } from 'react-icons';
import { COMPANY_INFO } from '../../variables';
import { FooterTitles } from './Footer';

const SocialMedia = () => {
  return (
    <Grid
      item
      xs={10}
      md={3}
      sx={{
        margin: { xs: '0 auto', md: '0' },
        textAlign: { xs: 'center', md: 'left' },
      }}
    >
      <Box>
        <FooterTitles variant='h4'>Redes Sociais</FooterTitles>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'initial' },
          }}
        >
          <IconContext.Provider value={{ style: { color: 'white' } }}>
            {COMPANY_INFO.socialMedia.map((socialItem) => (
              <Link
                key={`footer-socialMedia-${socialItem.title}`}
                title={socialItem.title}
                href={socialItem.url}
                target='_blank'
                rel='noreferrer'
                underline='none'
                sx={{
                  display: 'flex',
                  marginX: 0.75,
                  '&:first-of-type': {
                    marginLeft: { xs: 0.75, md: 0 },
                  },
                  '&:hover': {
                    opacity: 0.75,
                  },
                }}
              >
                {socialItem.icon}
              </Link>
            ))}
          </IconContext.Provider>
        </Box>
      </Box>
    </Grid>
  );
};

export default SocialMedia;
