import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { FiInfo } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../../../api/products';
import { productDetailsInterface } from '../../../helpers/productDetailsInterface';
import {
  setCartSubtotal,
  setCartTaxes,
  setCartTotal,
  setDiscount,
  updateCart,
} from '../../../state/cartSlice.js';
import { CUSTOM_THEME_COLORS, PAGES_SLUGS } from '../../../variables';
import PopupNotification from '../../PopupNotification/PopupNotification';
import ProductStock from '../../ProductStock';
import ProductVariants from '../ProductVariants/ProductVariants';
import ProductCheckout from './ProductCheckout';
import ProductPrices from './ProductPrices';

const ProductDetails = ({ product, userIsLogged, customerId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const campaignProducts = useSelector((state) => state.cart.campaignProducts);

  const {
    title,
    description,
    reference,
    itemsPerBox,
    variants,
    variationField,
    badge,
  } = product;
  const variationName = variationField;

  const productDetails = productDetailsInterface(product);

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: '',
    errorMessage: '',
  });

  const [productsForCart, setProductsForCart] = useState({
    products: [],
    totalUnits: 0,
    total: 0,
  });

  const addToCart = () => {
    const productAlreadyInCampaign = campaignProducts.some(
      (campaign) => campaign.offerProductId === product.id
    );

    //  IF this product already exists associated to a campaign » alert user & return
    if (productAlreadyInCampaign) {
      setNotificationProps((prevState) => ({
        ...prevState,
        isOpened: true,
        type: 'info',
        infoMessage:
          'Este produto já se encontra associado a uma campanha ativa',
      }));
      return;
    }

    addProductsToCart(productsForCart.products, customerId)
      .then(({ cart_items, total_discount, taxes, subtotal, total }) => {
        dispatch(updateCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTaxes(taxes));
        dispatch(setCartSubtotal(subtotal));
        dispatch(setCartTotal(total));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
          successMessage: 'Produto adicionado ao carrinho',
        }));
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));
          }
        }
      );
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
        }}
      >
        {!!badge.campaign && (
          <Typography
            component={Link}
            to={PAGES_SLUGS.campaigns}
            title='Campanha +info'
            variant='link'
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.75,
              fontWeight: 500,
              color: CUSTOM_THEME_COLORS.primary,
              marginBottom: 2,
            }}
          >
            <FiInfo size={16} />
            <Typography variant='xsmall'>
              Campanha
              <Box
                component='span'
                sx={{
                  fontWeight: 700,
                  paddingLeft: 0.5,
                }}
              >
                +info
              </Box>
            </Typography>
          </Typography>
        )}

        <Typography variant='h1'>{title}</Typography>

        {reference && (
          <Typography
            marginTop={1}
            sx={{ fontSize: '.85em' }}
          >{`Ref: ${reference}`}</Typography>
        )}

        {!productDetails.hasVariants && (
          <Box sx={{ marginTop: 1 }}>
            <ProductStock stock={productDetails.stock} />
          </Box>
        )}

        {description && (
          <Typography
            marginTop={2}
            sx={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}

        {itemsPerBox && (
          <Typography marginTop={2}>
            Embalagem com
            <Box
              component='span'
              fontWeight={600}
            >
              {` ${itemsPerBox} ${itemsPerBox > 1 ? 'unidades' : 'unidade'}`}
            </Box>
          </Typography>
        )}

        {productDetails.hasVariants ? (
          <Box
            sx={{
              marginTop: 3,
            }}
          >
            <ProductVariants
              variants={variants}
              variationName={variationName}
              userIsLogged={userIsLogged}
              productsForCart={productsForCart}
              setProductsForCart={setProductsForCart}
            />
          </Box>
        ) : (
          userIsLogged && <ProductPrices details={productDetails} />
        )}

        {userIsLogged ? (
          <ProductCheckout
            product={product}
            hasVariants={productDetails.hasVariants}
            addToCart={addToCart}
            productsForCart={productsForCart}
            setProductsForCart={setProductsForCart}
          />
        ) : (
          <Box sx={{ marginTop: 3 }}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: `Por favor, faça <a href=${PAGES_SLUGS.login} title='Login' style="color:${CUSTOM_THEME_COLORS.primary}; font-weight:600">Login</a> para visualizar o preço do produto.`,
              }}
            />
          </Box>
        )}
      </Box>
      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default ProductDetails;
