import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { FooterLink } from '../../styles';
import { FOOTER_MENUS } from '../../variables';
import { FooterTitles } from './Footer';

const CustomerSupport = () => {
  return (
    <Grid
      item
      xs={3}
    >
      <FooterTitles variant='h4'>Informações</FooterTitles>

      {FOOTER_MENUS.menu2.map((menuItem, index) => (
        <FooterLink
          key={`footer-menu-${menuItem.title}-${index}`}
          component={menuItem.internalLink ? Link : 'a'}
          to={menuItem.internalLink ? menuItem.url : null}
          href={!menuItem.internalLink ? menuItem.url : null}
          target={!menuItem.internalLink ? menuItem.target : null}
          title={menuItem.title}
          variant='link'
          marginBottom={0.75}
        >
          {menuItem.title}
        </FooterLink>
      ))}
    </Grid>
  );
};

export default CustomerSupport;
