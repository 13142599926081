import { Button, Divider, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { FONT_STYLE, PAGES_SLUGS } from '../../../variables';

const NavbarMenuItem = styled(MenuItem)(({ theme }) => ({
  width: '100%',
  minHeight: 'initial',
  fontSize: '0.9rem',
  fontWeight: FONT_STYLE.medium,
  textTransform: 'none',
  padding: `${theme.spacing(1.25)} ${theme.spacing(2.5)} `,
}));

const UserMenu = ({
  isMenuOpen,
  setIsMenuOpen,
  userRef,
  accountLinks,
  handleLogout,
}) => {
  //  filter links of 'userMenu'
  const userMenu = accountLinks.filter((item) => item.userMenu);

  return (
    <Menu
      anchorEl={userRef.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
    >
      {userMenu.map(({ title, url }) => {
        return (
          <li key={`userMenu-${url}`}>
            <>
              <NavbarMenuItem
                component={Link}
                to={`${PAGES_SLUGS.account}/${url}`}
                title={title}
                onClick={() => setIsMenuOpen(false)}
              >
                {title}
              </NavbarMenuItem>
              <Divider
                sx={{ marginTop: '0!important', marginBottom: '0!important' }}
              />
            </>
          </li>
        );
      })}
      <NavbarMenuItem
        component={Button}
        title='Terminar sessão'
        onClick={handleLogout}
      >
        Terminar sessão
      </NavbarMenuItem>
    </Menu>
  );
};

export default UserMenu;
