import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { IconContext } from 'react-icons';
import { BsCheckCircle } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCart, setCartSubtotal, setCartTotal } from '../../state/cartSlice';
import { StyledTextButton } from '../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE, PAGES_SLUGS } from '../../variables';

const OrderConfirmation = ({ message }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    //  reset subtotal cart
    dispatch(setCartSubtotal(0));
    //  reset total cart
    dispatch(setCartTotal(0));
    //  reset cart items
    dispatch(setCart([]));
  }, [dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: { xs: 5, md: 0 },
      }}
    >
      <IconContext.Provider
        value={{ style: { color: CUSTOM_THEME_COLORS.lightGreen } }}
      >
        <BsCheckCircle size={50} />
      </IconContext.Provider>

      <Typography
        sx={{
          marginTop: { xs: 2, md: 4 },
          '& ul': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        }}
        dangerouslySetInnerHTML={{ __html: message }}
      />

      <Typography sx={{ marginTop: { xs: 1 } }}>
        De seguida, irá receber um email com todos os detalhes da sua encomenda.
      </Typography>
      <Typography sx={{ marginTop: { xs: 1 } }}>
        Poderá efetuar a liquidação das suas faturas de forma simples e cómoda
        através da sua Área de Cliente » &nbsp;
        <Typography
          component={Link}
          to={`${PAGES_SLUGS.account}/${PAGES_SLUGS.myAccount.receipts}`}
          title='Conta Corrente'
          variant='link'
          sx={{
            color: CUSTOM_THEME_COLORS.primary,
            fontWeight: FONT_STYLE.bold,
          }}
        >
          Conta Corrente
        </Typography>
        .
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          marginY: { xs: 2, md: 4 },
        }}
      >
        <StyledTextButton
          title='Voltar à homepage'
          variant='contained'
          color='secondary'
          component={Link}
          to='/'
        >
          Voltar à homepage
        </StyledTextButton>

        <StyledTextButton
          title='Ver encomendas'
          variant='contained'
          component={Link}
          to={`${PAGES_SLUGS.account}/${PAGES_SLUGS.myAccount.orders}`}
        >
          Ver Encomendas
        </StyledTextButton>
      </Box>
    </Box>
  );
};

export default OrderConfirmation;
