import axios from 'axios';
import { API_URL, SETTINGS } from '../variables';
import { getHeaders } from './login';
import { mapProduct } from './mapApiInterface';

//  GET

function getCart(cartId) {
  return axios
    .get(`${API_URL}/cart/${cartId}`, getHeaders())
    .then(({ data: { cart } }) => {
      const { cart_items } = cart;
      const mappingProducts = cart_items?.map((product) => mapProduct(product));
      return { ...cart, cart_items: mappingProducts };
    });
}

function getBuyAuthorization(customerId) {
  return axios
    .get(`${API_URL}/account/check-status?customerId=${customerId}`, getHeaders())
    .then(({ data }) => data);
}

function getUser() {
  return axios
    .get(`${API_URL}/user`, getHeaders())
    .then(({ data: { user } }) => user);
}

function getUsers() {
  return axios
    .get(`${API_URL}/user/users`, getHeaders())
    .then(({ data: { users } }) => users);
}

function getPreviousOrders(customerId) {
  return axios
    .get(`${API_URL}/orders?customerId=${customerId}`, getHeaders())
    .then(({ data: { orders } }) => orders);
}

function getReceipts(customerId) {
  return axios
    .get(`${API_URL}/orders/account?customerId=${customerId}`, getHeaders())
    .then(({ data: { account } }) => ({
      ...account,
      docs: account.docs.map((doc, index) => ({
        id: index,
        ...doc,
      })),
    }));
}

function getReceiptInvoice(docId, docType) {
  return axios
    .get(`${API_URL}/orders/document/${docType}/${docId}`, {
      responseType: 'blob',
      ...getHeaders(),
    })
    .then(({ data }) => data);
}

function getProductUserHistory(customerId) {
  return axios
    .get(`${API_URL}/user/product-history/${customerId}`, getHeaders())
    .then(({ data: { productHistory } }) => {
      return productHistory.map((product) => mapProduct(product));
    });
}

function getProductsPurchaseHistory({ searchParams = {} }) {
  const urlSearchParams = new URLSearchParams({
    sortBy: SETTINGS.defaults.productList.sortBy,
    ...searchParams,
  }).toString();
  return axios
    .get(`${API_URL}/user/purchase-history?${urlSearchParams}`, getHeaders())
    .then(({ data: { products, productsTotal } }) => {
      const displayedProducts = products.map((product) => mapProduct(product));
      const displayedFilters = [];
      return { displayedProducts, displayedFilters, productsTotal };
    });
}

// POST

function setCustomer(customerId) {
  return axios
    .post(`${API_URL}/user/customers/select-customer`, customerId, getHeaders())
    .then(({ data: { message } }) => message);
}

function addUser(newUserData) {
  return axios
    .post(`${API_URL}/user/create/user`, newUserData, getHeaders())
    .then(({ data: { addresses } }) => addresses);
}

function updateUser(newUserData) {
  return axios
    .post(`${API_URL}/user/update/user`, newUserData, getHeaders())
    .then(({ data: user }) => user);
}

function addAddress(newDeliveryAddress) {
  return axios
    .post(`${API_URL}/user/create/address`, newDeliveryAddress, getHeaders())
    .then(({ data: { addresses } }) => addresses);
}

function updateAddress(updatedAddress) {
  return axios
    .post(`${API_URL}/user/update/address`, updatedAddress, getHeaders())
    .then(({ data: { addresses } }) => addresses);
}

function addProductToUserHistory(reference, customerId) {
  return axios
    .post(
      `${API_URL}/user/product-history`,
      { reference, userId: customerId },
      getHeaders()
    )
    .then(({ data }) => data);
}

function duplicateOrder(customerId, orderId) {
  return axios
    .post(`${API_URL}/orders/duplicate`, { customerId, orderId }, getHeaders())
    .then(({ data }) => data);
}

function getMbPaymentData(docData) {
  return axios
    .post(`${API_URL}/account/mb-reference`, docData, getHeaders())
    .then(({ data }) => data);
}

//  SHOPPING LIST

function getShoppingLists() {
  return axios
    .get(`${API_URL}/shopping-lists`, getHeaders())
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) => mapProduct(product)),
      }))
    );
}

function addShoppingList(name) {
  return axios
    .post(`${API_URL}/shopping-lists/create`, { name }, getHeaders())
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) => mapProduct(product)),
      }))
    );
}

function deleteShoppingList(id) {
  return axios
    .post(`${API_URL}/shopping-lists/${id}/delete`, {}, getHeaders())
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) => mapProduct(product)),
      }))
    );
}

function deleteProductFromShoppingList(listId, productId) {
  return axios
    .post(
      `${API_URL}/shopping-lists/${listId}/remove`,
      { product_id: productId },
      getHeaders()
    )
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) => mapProduct(product)),
      }))
    );
}

export {
  addAddress,
  addProductToUserHistory,
  addShoppingList,
  addUser,
  deleteProductFromShoppingList,
  deleteShoppingList,
  duplicateOrder,
  getCart,
  getPreviousOrders,
  getProductUserHistory,
  getProductsPurchaseHistory,
  getReceipts,
  getReceiptInvoice,
  getShoppingLists,
  getUser,
  getUsers,
  setCustomer,
  updateAddress,
  updateUser,
  getMbPaymentData,
  getBuyAuthorization
};
