import { Box, Typography } from '@mui/material';
import React from 'react';
import { formatCurrency } from '../../constants/utils';
import { BadgeDiscount } from '../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../variables';

const ProductPrices = ({ details, customerDiscount }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            sx={{
              fontSize: '0.85rem',
              fontWeight: FONT_STYLE.bold,
              marginBottom: 1,
            }}
          >
            Preço
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {details.hasVariants && (
              <Typography
                variant='xsmall'
                sx={{
                  lineHeight: 1,
                  placeSelf: 'flex-end',
                }}
              >
                desde
              </Typography>
            )}

            <Typography
              sx={{
                lineHeight: 1,
                textDecoration: details.onSale && 'line-through',
                fontWeight: FONT_STYLE.medium,
              }}
            >
              {formatCurrency(details.price)}
            </Typography>

            {!!details.onSale && (
              <>
                <Typography
                  sx={{
                    lineHeight: 1,
                    color: CUSTOM_THEME_COLORS.red,
                    fontWeight: FONT_STYLE.black,
                  }}
                >
                  {formatCurrency(details.salePrice)}
                </Typography>
                {!!details.saleAmount && (
                  <BadgeDiscount>{`${details.saleAmount}%`}</BadgeDiscount>
                )}
              </>
            )}
          </Box>
        </Box>

        {!!!details.onSale && customerDiscount !== 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.85rem',
                fontWeight: FONT_STYLE.bold,
                marginBottom: 1,
              }}
            >
              Desconto cliente
            </Typography>
            <BadgeDiscount>{`${customerDiscount}%`}</BadgeDiscount>
          </Box>
        )}
      </Box>
    </>
  );
};

export default React.memo(ProductPrices);
