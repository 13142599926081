import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateCartProducts } from '../api/checkout';
import { getCampaigns } from '../api/products';
import CampaignsCard from '../components/Campaigns/CampaignsCard';
import CampaignsModal from '../components/Campaigns/CampaignsModal';
import Loading from '../components/Loading';
import PopupNotification from '../components/PopupNotification/PopupNotification';
import {
  setCartObject,
  setCartSubtotal,
  setCartTaxes,
  setCartTotal,
  setDiscount,
  updateCart,
} from '../state/cartSlice';
import { MainContainer } from '../styles';

function Campaigns() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reduxStorage = useSelector((state) => state);
  const {
    user: { selectedClient, id },
    cart: { campaignProducts },
  } = reduxStorage;
  const customerId = selectedClient?.id || id;

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [campaigns, setCampaigns] = useState(null);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    successMessage: 'Campanha adicionada ao carrinho',
    infoMessage: 'Esta campanha já se encontra ativa',
  });

  const addToCart = (campaign, productsArray) => {
    const campaignProduct = productsArray[0];
    const offerProduct = productsArray[1];

    const hasActiveCampaigns = !!campaignProducts.length;

    //  IF exists campaigns active
    if (hasActiveCampaigns) {
      const campaignAlreadyApplied = campaignProducts.some(
        (campaign) => campaign.offerProductId === offerProduct.id
      );

      //  check if this campaign has already applied
      if (campaignAlreadyApplied) {
        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'info',
        }));
        return;
      }
    }

    updateCartProducts(productsArray, customerId)
      .then(({ cart_items, total_discount, taxes, subtotal, total }) => {
        //  apply new campaign
        dispatch(
          setCartObject({
            campaignProducts: [
              ...campaignProducts,
              {
                campaignProductId: campaignProduct.id,
                minQuantity: campaign.qttSale,
                offerProductId: offerProduct.id,
              },
            ],
          })
        );

        dispatch(updateCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTaxes(taxes));
        dispatch(setCartSubtotal(subtotal));
        dispatch(setCartTotal(total));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login', msg);
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));
          }
        }
      );
  };

  useEffect(() => {
    setIsLoading(true);

    getCampaigns()
      .then((data) => {
        setCampaigns(data);
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || campaigns === null) {
    return <Loading />;
  }

  return (
    <>
      <MainContainer>
        <Container>
          <Box textAlign='center'>
            <Typography variant='h1'>Campanhas</Typography>
            <Typography marginTop={2}>
              Aqui poderá usufruir das melhores campanhas, aos melhores preços.
            </Typography>
          </Box>
          <Grid
            container
            spacing={3}
            marginTop={{ xs: 2, md: 4 }}
          >
            {!!campaigns.length ? (
              campaigns.map((campaign, index) => (
                <CampaignsCard
                  key={`campaign-card-${campaign.title}-${index}`}
                  campaign={campaign}
                  addToCart={addToCart}
                  setIsModalOpen={setIsModalOpen}
                  setModalContent={setModalContent}
                />
              ))
            ) : (
              <Grid>
                <Typography>
                  De momento não existem campanhas disponíveis.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Container>
      </MainContainer>
      <CampaignsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalContent={modalContent}
      />
      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
}

export default Campaigns;
