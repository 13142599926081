import { Box, Popper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { VscChevronRight } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { CategoriesButton } from '../../../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../../../variables';

const CategoriesMenu = ({
  setIsCategoriesMenuOpen,
  isCategoriesMenuOpen,
  anchorEl,
  categoriesTree,
}) => {
  const [subCategoriesTree, setSubCategoriesTree] = useState([]);
  const [activeCategory, setActiveCategory] = useState('');

  return (
    <Popper
      open={isCategoriesMenuOpen}
      anchorEl={anchorEl}
      disablePortal
      keepMounted
      sx={{
        boxShadow: '0px 3px 5px 1px rgba(0,0,0,0.1)',
        inset: '100% auto auto auto !important',
        transform: 'none !important',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* collections */}
        {categoriesTree.map((collection) => (
          <Box key={`collection-${collection?.id}`}>
            <CategoriesButton
              component={Link}
              to={collection?.url}
              title={collection?.title}
              active={activeCategory === collection?.id ? 1 : 0}
              onMouseEnter={() => {
                setSubCategoriesTree(collection?.subcategories);
                setActiveCategory(collection?.id);
              }}
              onClick={() => {
                setIsCategoriesMenuOpen(false);
              }}
            >
              {collection?.title}
              {!!collection?.subcategories.length && (
                <VscChevronRight size={14} />
              )}
            </CategoriesButton>
            {/* categories */}
            {activeCategory === collection?.id && (
              <Box
                sx={{
                  minWidth: '25vw',
                  height: '100%',
                  position: 'absolute',
                  top: '0',
                  left: '100%',
                  backgroundColor: 'white',
                  boxShadow: '0px 3px 5px 1px rgba(0,0,0,0.1)',
                  paddingY: 2.5,
                  paddingX: 4,
                  fontSize: '0.9rem',
                  color: 'black',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.1rem',
                    fontWeight: FONT_STYLE.black,
                    color: CUSTOM_THEME_COLORS.primary,
                  }}
                >
                  {collection?.title}
                </Typography>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns:
                      subCategoriesTree.length > 10 ? 'auto auto' : 'auto',
                    gap: 1.25,
                    marginTop: 2,
                  }}
                >
                  {subCategoriesTree.map((category) => (
                    <Typography
                      variant='link'
                      key={`category-${category?.id}`}
                      component={Link}
                      to={category?.url}
                      title={category?.title}
                      onClick={() => {
                        setIsCategoriesMenuOpen(false);
                      }}
                    >
                      {category?.title}
                    </Typography>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Popper>
  );
};

export default CategoriesMenu;
