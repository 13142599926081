/*
|--------------------------------
| get product details with all validations
|--------------------------------
*/
export const productDetailsInterface = (product) => {
  const {
    variants,
    saleAmount,
    //  unit
    price,
    onSale,
    salePrice,
    stock,
    //  box
    sellsBox,
    boxPrice,
    onSaleBox,
    boxSalePrice,
    stockPerBox,
  } = product;
  let variantsSortedAscByPrice = [];
  const hasVariants = !!variants.length;

  if (hasVariants) {
    variantsSortedAscByPrice = [...variants].sort((a, b) =>
      a.onSaleBox ? a.boxSalePrice - b.boxSalePrice : a.boxPrice - b.boxPrice
    )[0];
  }

  return {
    hasVariants: hasVariants,
    saleAmount: saleAmount,
    price: hasVariants
      ? //  variant
        variantsSortedAscByPrice.itemsPerBox
        ? variantsSortedAscByPrice.boxPrice
        : variantsSortedAscByPrice.price
      : //  simple
      sellsBox
      ? boxPrice
      : price,
    onSale: hasVariants
      ? //  variant
        variantsSortedAscByPrice.sellsBox
        ? variantsSortedAscByPrice.onSaleBox
        : variantsSortedAscByPrice.onSale
      : //  simple
      sellsBox
      ? onSaleBox
      : onSale,
    salePrice: hasVariants
      ? //  variant
        variantsSortedAscByPrice.sellsBox
        ? variantsSortedAscByPrice.boxSalePrice
        : variantsSortedAscByPrice.salePrice
      : //  simple
      sellsBox
      ? boxSalePrice
      : salePrice,
    stock: hasVariants
      ? //  variant
        variantsSortedAscByPrice.sellsBox
        ? variantsSortedAscByPrice.stockPerBox
        : variantsSortedAscByPrice.stock
      : //  simple
      sellsBox
      ? stockPerBox
      : stock,
  };
};
