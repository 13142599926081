import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { Link } from 'react-router-dom';
import { TitleInstitutionalPages } from '../components/InstitucionalPages/InstitucionalPages';
import { MainContainer } from '../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE, PAGES_SLUGS } from '../variables';

const BusinessAreas = () => {
  const theme = useTheme();

  const content = [
    {
      image: `${process.env.PUBLIC_URL}/business-area-1.webp`,
      title: 'Hotelaria',
      description: `Delicioso pão para o pequeno-almoço; destacamos o de cereais, alfarroba, o de malte, pão de forma, abóbora e nozes, chapata, pão de água, as broas variadas, o saloio fatiado, o da mealhada, as focaccias. Uma alargada variedade de pães de qualidade para apresentar. Quanto aos croissants mantemos os mesmos critérios; a qualidade e a variedade: croissant com manteiga, com margarina, direto ao forno, de chocolate, creme de ovo, de cereais, misto, combinado, de frango. Dentro dos formatos vai encontrar o que precisa. Julgamos satisfazer e poder surpreender os gostos mais exigentes. Mas para não ficar assim, temos os bolos de pão-de-ló de diferentes sabores que são uma excelente escolha para iniciar o dia ou no decorrer dele. Como prefere?`,
      button: {
        title: 'Contactos',
        url: PAGES_SLUGS.contacts,
      },
    },
    {
      image: `${process.env.PUBLIC_URL}/business-area-2.webp`,
      title: 'Restauração',
      description: `Estamos cá para um momento de partilha e ajudarmos a escolher iguarias e dicas para o seu cantinho muito especial. Para entradas temos as miniaturas folhadas: de espinafres, requeijão e nozes, de cogumelos, de frango, de atum, marisco, misto, alheira e grelos, salsicha. Acrescentamos os rissóis e bolinhos, salgadinhos muito apetecíveis e bem-vindos à mesa. Eis que surgiram outros mais curiosos: os croquetes de polvo, de espinafres, as gambas com pão japonês, as chamuças de legumes, as delícias de atum, o pastel de chaves. Estes mimos podemos também servi-los no leve para casa, num pequeno-almoço tardio/quase almoço, num lanche, num pós jantar ou ceia. Um docinho para o fim, as nossas sobremesas e gelados que pode escolher um diferente de cada vez pois temos realmente muitas. Que momentos podemos ter: o buffet romântico, os eventos profissionais como seminários, conferências, formações, os eventos especiais onde envolve um maior número de pessoas, as escolas, hospitais, prisões, hotéis que não tem restaurante, assim o nosso mundo é mais vasto do que parece. Acompanhe a refeição com um bonito pão, pode ser pão branco ou não, mas também a baguete, o rústico, o camposa, o saloio, a barra, as broas e uma baguete de alho?`,
      button: {
        title: 'Contactos',
        url: PAGES_SLUGS.contacts,
      },
    },
    {
      image: `${process.env.PUBLIC_URL}/business-area-3.webp`,
      title: 'Café, Pastelaria e Padaria',
      description: `Já pensou em melhorar a apresentação do seu espaço com ideias que não são dispendiosas. A decoração temática atrai o caminhante de fora para dentro. Podemos alegrar um espaço, junto a uma janela ou vidro, colocando uma prateleira com alguns produtos, flores, imagens alusivas a épocas festivas, frases positivas, ou propor um jogo. Mas também um logotipo apelativo, montras bem expostas, podem apelar para o público entrar. Isto são pequenos alertas que podem ajudar. A partir dai a qualidade/variedade deve manter fiel a clientela. Por vezes mimar quem nos é fiel também ajuda. No que nos diz respeito temos o que precisa para ter a qualidade e variedade no seu estabelecimento.`,
      button: {
        title: 'Contactos',
        url: PAGES_SLUGS.contacts,
      },
    },
    {
      image: `${process.env.PUBLIC_URL}/business-area-4.webp`,
      title: 'Área alimentar',
      description: `A pensar no momento de fazer escolhas para colocar no seu espaço, pensamos na qualidade, em alimentos nutricionalmente equilibrados e olhando pela vertente prática, as embalagens são otimizadas. Temos variedade pelo que a escolha pode iniciar pelas entradas com salgados, empadas, refeições prontas, pães diferenciados, hambúrgueres, pizzas, sandwiches já preparadas, sobremesas, épocas festivas, e para um momento único os gelados. Estamos ao dispor para o servir.`,
      button: {
        title: 'Contactos',
        url: PAGES_SLUGS.contacts,
      },
    },
  ];

  return (
    <MainContainer>
      <Container>
        <Grid container>
          <Grid xs={12}>
            <TitleInstitutionalPages variant='h1'>
              Áreas de Negócio
            </TitleInstitutionalPages>
          </Grid>
          <Grid xs={12}>
            {content.map(({ image, title, description, button }, index) => {
              const isEven = index % 2 !== 0;
              return (
                <Card
                  key={`business-card-${index}`}
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: '100%', md: '50% 50%' },
                    marginY: { xs: 5, md: 8 },
                    '&:first-of-type': {
                      marginTop: 2,
                    },
                    '&:last-child': {
                      marginBottom: 0,
                    },
                  }}
                >
                  {image && (
                    <CardMedia
                      component='img'
                      image={image}
                      sx={{
                        order: { md: isEven ? '99' : 'initial' },
                        aspectRatio: '3/2',
                        height: '100%',
                      }}
                    />
                  )}

                  <CardContent
                    sx={{
                      backgroundColor: isEven
                        ? CUSTOM_THEME_COLORS.lightGray
                        : CUSTOM_THEME_COLORS.primary,
                      paddingTop: { xs: 4, md: 7 },
                      paddingBottom: {
                        xs: `${theme.spacing(4)} !important`,
                        md: `${theme.spacing(7)} !important`,
                      },
                      paddingLeft: { xs: 4, md: 7 },
                      paddingRight: { xs: 4, md: 7 },
                    }}
                  >
                    {title && (
                      <Typography
                        sx={{
                          fontSize: { xs: '1.5rem', md: '2rem' },
                          lineHeight: '1.15',
                          fontWeight: FONT_STYLE.black,
                          color: isEven ? 'black' : 'white',
                        }}
                      >
                        {title}
                      </Typography>
                    )}

                    {description && (
                      <Typography
                        sx={{
                          fontSize: { xs: '0.9rem', md: '1rem' },
                          lineHeight: '1.75',
                          color: isEven ? 'black' : 'white',
                          marginTop: 3,
                        }}
                      >
                        {description}
                      </Typography>
                    )}

                    {button && (
                      <Button
                        component={Link}
                        to={button.url}
                        title={button.title}
                        variant='text'
                        sx={{
                          color: isEven ? 'white' : CUSTOM_THEME_COLORS.primary,
                          backgroundColor: isEven
                            ? CUSTOM_THEME_COLORS.primary
                            : 'white',
                          marginTop: 3,
                          '&:hover': {
                            backgroundColor: isEven
                              ? CUSTOM_THEME_COLORS.primary
                              : 'white',
                          },
                        }}
                      >
                        {button.title}
                      </Button>
                    )}
                  </CardContent>
                </Card>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default BusinessAreas;
