import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getProduct } from '../api/products';
import { addProductToUserHistory } from '../api/user';
import Breadcrumbs from '../components/Breadcrumbs';
import Loading from '../components/Loading';
import ProductDetails from '../components/ProductPage/ProductDetails/ProductDetails';
import ProductImages from '../components/ProductPage/ProductImages';
import ProductRelated from '../components/ProductPage/ProductRelated';
import ProductSpecifications from '../components/ProductPage/ProductSpecifications';
import ProductTabs from '../components/ProductPage/ProductTabs/ProductTabs';
import { MainContainer } from '../styles';

const ProductPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productSlug } = useParams();

  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const user = useSelector((state) => state.user);
  const { role: userRole, selectedClient, id } = user;
  const customerId = selectedClient?.id || id;

  const [cookies] = useCookies(['userToken']);
  const userIsLogged = !!cookies.userToken;
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const productTabsVisible = false;

  useEffect(() => {
    setIsLoading(true);
    getProduct(productSlug)
      .then((newProduct) => {
        setProduct(newProduct);

        if (userRole === 'customer') {
          addProductToUserHistory(newProduct.reference, customerId);
        }

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    return () => {
      setProduct(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productSlug]);

  if (isLoading) {
    return <Loading />;
  }

  if (!product) {
    return null;
  }

  const images = product?.images;
  const { related, params, datasheet, video, path, badge } = product;

  //  remove params empty & invalid values
  const availableParams = params.filter(
    (param) => param.value && param.value !== undefined && param.value !== null
  );

  return (
    <MainContainer
      overflow='hidden'
      sx={{
        paddingTop: { xs: 3 },
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box marginBottom={2}>
              <Breadcrumbs
                pathArray={path}
                productDetail
              />
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: MOBILE_DEVICES && 'column',
              }}
              gap={{ xs: 4, md: 6 }}
            >
              <Box
                sx={{
                  position: 'relative',
                  maxWidth: '100%',
                  margin: { xs: '0 auto', md: '0' },
                }}
              >
                <ProductImages
                  images={images}
                  badge={badge}
                />
              </Box>

              <ProductDetails
                product={product}
                userIsLogged={userIsLogged}
                customerId={customerId}
              />
            </Box>

            <ProductSpecifications specifications={availableParams} />

            {productTabsVisible && (
              <ProductTabs
                video={video}
                params={availableParams}
                datasheet={datasheet}
              />
            )}

            {related && <ProductRelated related={related} />}
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ProductPage;
