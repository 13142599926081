import axios from 'axios';
import { API_URL, SETTINGS } from '../variables';
import { getHeaders } from './login';
import { mapFilters, mapProduct } from './mapApiInterface';

//  GET
function getProduct(slug) {
  return axios
    .get(`${API_URL}/products/${slug}`, getHeaders())
    .then(({ data: { data: product } }) => mapProduct(product));
}

function getProducts({ searchParams = {} }) {
  const urlSearchParams = new URLSearchParams({
    sortBy: SETTINGS.defaults.productList.sortBy,
    ...searchParams,
  }).toString();
  return axios
    .get(`${API_URL}/products?${urlSearchParams}`, getHeaders())
    .then(({ data: { products, productsTotal, filters } }) => {
      const displayedProducts = products.map((product) => mapProduct(product));
      const displayedFilters = mapFilters(filters);
      return { displayedFilters, displayedProducts, productsTotal };
    });
}

function getLatestProducts() {
  return axios
    .get(
      `${API_URL}/products?sortBy=${SETTINGS.defaults.productList.sortBy}&limit=20`,
      getHeaders()
    )
    .then(({ data: { products } }) => {
      return products.map((product) => mapProduct(product));
    });
}

function getCampaigns() {
  return axios
    .get(`${API_URL}/products/promotions`, getHeaders())
    .then(({ data: { promotions } }) => {
      return promotions.map((promotion) => ({
        ...promotion,
        productOffer: mapProduct(promotion.productOffer),
        productSale: mapProduct(promotion.productSale),
      }));
    });
}

function getSearchedProducts({ searchParams = {} }) {
  const urlSearchParams = new URLSearchParams({
    sortBy: SETTINGS.defaults.productList.sortBy,
    ...searchParams,
  }).toString();
  return axios
    .get(`${API_URL}/search?${urlSearchParams}`, getHeaders())
    .then(({ data: { products: productsReceived, productsTotal } }) => {
      const products = productsReceived.map((product) => mapProduct(product));
      return { products, productsTotal };
    });
}

// POST

function addProductsToCart(newProducts, customerId) {
  return axios
    .post(
      `${API_URL}/cart/add`,
      { products: newProducts, customerId },
      getHeaders()
    )
    .then(({ data: { cart } }) => ({
      ...cart,
      cart_items: cart.cart_items.map((product) => mapProduct(product)),
    }));
}

function addProductToShoppingList(listId, product_id) {
  return axios
    .post(
      `${API_URL}/shopping-lists/${listId}/add`,
      { product_id },
      getHeaders()
    )
    .then(({ data: { lists } }) =>
      lists.map((list) => ({
        ...list,
        products: list.products.map((product) =>
          mapProduct({
            ...product,
            quantity: {
              [SETTINGS.defaults.quantityType]: 1,
            },
          })
        ),
      }))
    );
}

export {
  addProductToShoppingList,
  addProductsToCart,
  getCampaigns,
  getLatestProducts,
  getProduct,
  getProducts,
  getSearchedProducts,
};
