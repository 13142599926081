import React, { createContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../api/products';
import PopupNotification from '../components/PopupNotification/PopupNotification';
import ProductVariantsModal from '../components/ProductVariantsModal';
import {
  setCartSubtotal,
  setCartTaxes,
  setCartTotal,
  setDiscount,
  updateCart,
} from '../state/cartSlice';
import { SETTINGS } from '../variables';

export const ProductCardContext = createContext();

export const ProductCardProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const {
    user: { id, selectedClient, customerDiscount },
    cart: { campaignProducts },
  } = state;
  const customerId = selectedClient?.id || id;

  const [isVariationsModalOpen, setIsVariationsModalOpen] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
  });
  const [productVariants, setProductVariants] = useState([]);
  const [productsForCart, setProductsForCart] = useState({
    products: [],
    totalUnits: 0,
    total: 0,
  });

  const addToCart = (product) => {
    const productsToCart = product
      ? [
          {
            // SIMPLE PRODUCT
            ...product,
            quantity: {
              ...product.quantity,
              [SETTINGS.defaults.quantityType]: 1,
            },
          },
        ]
      : productsForCart.products; // VARIANTS PRODUCT

    const productAlreadyInCampaign = campaignProducts.some(
      (campaign) => campaign.offerProductId === product.id
    );

    //  IF this product already exists associated to a campaign » alert user & return
    if (productAlreadyInCampaign) {
      setNotificationProps((prevState) => ({
        ...prevState,
        isOpened: true,
        type: 'info',
        infoMessage:
          'Este produto já se encontra associado a uma campanha ativa',
      }));
      return;
    }

    addProductsToCart(productsToCart, customerId)
      .then(({ cart_items, total_discount, taxes, subtotal, total }) => {
        dispatch(updateCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTaxes(taxes));
        dispatch(setCartSubtotal(subtotal));
        dispatch(setCartTotal(total));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
          successMessage: 'Produto adicionado ao carrinho',
        }));
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login', msg);
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));
          }
        }
      );
  };

  return (
    <ProductCardContext.Provider
      value={{
        setIsVariationsModalOpen,
        productVariants,
        setProductVariants,
        customerDiscount,
        addToCart,
      }}
    >
      {children}
      <ProductVariantsModal
        isVariationsModalOpen={isVariationsModalOpen}
        setIsVariationsModalOpen={setIsVariationsModalOpen}
        productsForCart={productsForCart}
        setProductsForCart={setProductsForCart}
      />
      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </ProductCardContext.Provider>
  );
};
