import {
  Box,
  Button,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  styled,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { HiOutlineMenu } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { IconButton } from '../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE, NAVBAR_LINKS } from '../../variables';
import CompanyLogo from '../CompanyLogo';

const SubmenuButton = styled(Button)(() => ({
  width: '100%',
  justifyContent: 'space-between !important',
}));

const SubmenuArrowButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
}));

const MenuMobile = () => {
  const theme = useTheme();
  const categories = useSelector((state) => state.categories.categories);
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const [isCategoriesMenuOpened, setIsCategoriesMenuOpened] = useState(false);
  const [isSubcategoriesMenuOpened, setIsSubcategoriesMenuOpened] =
    useState(false);
  const [isProductTypesMenuOpened, setIsProductTypesMenuOpened] =
    useState(false);

  useEffect(() => {
    if (isMobileMenuOpened === false) {
      setIsCategoriesMenuOpened(false);
      setIsSubcategoriesMenuOpened(false);
    }
    if (isSubcategoriesMenuOpened) {
      setIsProductTypesMenuOpened(false);
    }
  }, [isSubcategoriesMenuOpened, isMobileMenuOpened]);

  const ProductTypesMenu = ({ productTypes, index }) => {
    return (
      <Collapse
        in={isProductTypesMenuOpened[index]}
        timeout='auto'
        unmountOnExit
        sx={{
          width: '100%',
        }}
      >
        <List>
          {productTypes.map(({ title, url }) => (
            <ListItem
              key={`menu-productTypes-${title}`}
              sx={{
                paddingY: 0,
              }}
            >
              <ListItemButton
                component={Link}
                to={url}
                onClick={() => setIsMobileMenuOpened(false)}
              >
                {title}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    );
  };

  const SubcategoriesMenu = ({ subcategories, index }) => {
    return (
      <Collapse
        in={isSubcategoriesMenuOpened[index]}
        timeout='auto'
        unmountOnExit
        sx={{
          width: '100%',
        }}
      >
        <List>
          {subcategories.map(
            ({ title, url, productTypes }, subcategoriesIndex) => (
              <ListItem
                key={`menu-subcategories-${title}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingY: 0,
                  paddingRight: 0,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <ListItemButton
                    component={Link}
                    to={url}
                    onClick={() => setIsMobileMenuOpened(false)}
                    sx={{
                      fontWeight: isProductTypesMenuOpened[subcategoriesIndex]
                        ? 600
                        : null,
                    }}
                  >
                    {title}
                  </ListItemButton>
                  <SubmenuArrowButton
                    onClick={() =>
                      setIsProductTypesMenuOpened({
                        [subcategoriesIndex]:
                          !isProductTypesMenuOpened[subcategoriesIndex],
                      })
                    }
                  >
                    {isProductTypesMenuOpened[subcategoriesIndex] ? (
                      <FiChevronUp size={16} />
                    ) : (
                      <FiChevronDown size={16} />
                    )}
                  </SubmenuArrowButton>
                </Box>
                <ProductTypesMenu
                  productTypes={productTypes}
                  index={subcategoriesIndex}
                />
              </ListItem>
            )
          )}
        </List>
      </Collapse>
    );
  };

  const CategoriesMenu = ({ categories }) => {
    return (
      <Collapse
        in={isCategoriesMenuOpened}
        timeout='auto'
        unmountOnExit
        sx={{
          width: '100%',
        }}
      >
        <List>
          {categories.map(({ title, url, subcategories }, CategoriesIndex) => (
            <ListItem
              key={`menu-categories-${title}`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingY: 0,
                backgroundColor:
                  isSubcategoriesMenuOpened[CategoriesIndex] &&
                  CUSTOM_THEME_COLORS.lightGray,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <ListItemButton
                  component={Link}
                  to={url}
                  onClick={() => setIsMobileMenuOpened(false)}
                  sx={{
                    fontWeight:
                      isSubcategoriesMenuOpened[CategoriesIndex] && 600,
                  }}
                >
                  {title}
                </ListItemButton>
                <SubmenuArrowButton
                  onClick={() =>
                    setIsSubcategoriesMenuOpened({
                      [CategoriesIndex]:
                        !isSubcategoriesMenuOpened[CategoriesIndex],
                    })
                  }
                >
                  {isSubcategoriesMenuOpened[CategoriesIndex] ? (
                    <FiChevronUp size={16} />
                  ) : (
                    <FiChevronDown size={16} />
                  )}
                </SubmenuArrowButton>
              </Box>
              <SubcategoriesMenu
                subcategories={subcategories}
                index={CategoriesIndex}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    );
  };

  return (
    <>
      <IconButton
        startIcon={<HiOutlineMenu size={28} />}
        onClick={() => setIsMobileMenuOpened(true)}
        title='Menu'
      />

      <Drawer
        open={isMobileMenuOpened}
        onClose={() => {
          setIsSubcategoriesMenuOpened(false);
          setIsProductTypesMenuOpened(false);
          setIsMobileMenuOpened(false);
        }}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: '85%',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
          },
        }}
        sx={{
          '& > .MuiListItem-root': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <>
          <Box
            sx={{
              paddingX: 2,
            }}
          >
            <CompanyLogo link={false} />
          </Box>
          <List
            sx={{
              paddingY: 3,
              fontSize: '1.1rem',
            }}
          >
            {/* CATEGORIES MENU */}
            {!!categories.length && (
              <ListItem
                disablePadding
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <ListItemButton
                  component={SubmenuButton}
                  onClick={() =>
                    setIsCategoriesMenuOpened((prevState) => !prevState)
                  }
                  sx={{
                    fontSize: 'inherit',
                  }}
                >
                  Produtos
                  {isCategoriesMenuOpened ? (
                    <FiChevronUp size={16} />
                  ) : (
                    <FiChevronDown size={16} />
                  )}
                </ListItemButton>
                <CategoriesMenu categories={categories} />
              </ListItem>
            )}
            {NAVBAR_LINKS.filter((menu) => menu.menuMobile).map(
              ({ title, url }) => (
                <ListItem
                  disablePadding
                  key={`menu-mobile-${title}`}
                >
                  <ListItemButton
                    component={NavLink}
                    to={url}
                    onClick={() => setIsMobileMenuOpened(false)}
                    sx={{
                      fontSize: 'inherit',
                      '&.active': {
                        fontWeight: FONT_STYLE.medium,
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {title}
                  </ListItemButton>
                </ListItem>
              )
            )}
          </List>
        </>
      </Drawer>
    </>
  );
};

export default MenuMobile;
