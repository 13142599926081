import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { kebabCase } from 'lodash';
import { useEffect, useState } from 'react';
import { GrDocumentPdf } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getMbPaymentData,
  getReceiptInvoice,
  getReceipts,
} from '../../../api/user';
import { ReactComponent as IconMultibanco } from '../../../assets/multibanco.svg';
import { formatCurrency } from '../../../constants/utils';
import { setReceipts } from '../../../state/userSlice';
import { IconButton, MyAccountTitle } from '../../../styles';
import { FONT_STYLE } from '../../../variables';
import Loading from '../../Loading';
import PopupNotification from '../../PopupNotification/PopupNotification';
import PaymentDataModal from './PaymentDataModal';

const AccountReceipts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userState = useSelector((state) => state.user);
  const { id, selectedClient, receipts } = userState;
  const customerId = selectedClient?.id || id;

  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
  });
  const [entities, setEntities] = useState([]);
  const [openAmounts, setOpenAmounts] = useState(0);
  const [paymentData, setPaymentData] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [receiptsToPay, setReceiptsToPay] = useState({
    data: [],
    totalAmount: 0,
  });

  const downLoadPdfFile = (data, fileName) => {
    const anchorElement = document.createElement('a');

    document.body.appendChild(anchorElement);

    anchorElement.style.display = 'none';

    const blob = new Blob([data], {
      type: 'application/pdf',
    });

    const url = window.URL.createObjectURL(blob);

    anchorElement.href = url;
    anchorElement.download = fileName;
    anchorElement.click();

    window.URL.revokeObjectURL(url);
    anchorElement.remove();
  };

  useEffect(() => {
    setIsLoading(true);
    getReceipts(customerId)
      .then(({ docs, totalBalance }) => {
        setIsLoading(false);
        dispatch(setReceipts(docs.reverse()));
        setOpenAmounts(totalBalance);
      })
      .catch(({ response: { status } }) => {
        setIsLoading(false);
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customerId, paymentData]);

  useEffect(() => {
    setEntities(receipts);
  }, [receipts]);

  useEffect(() => {
    const entitiesOfSelectedRows = entities.filter((entity) =>
      selectedRowIds.includes(entity.id)
    );
    setReceiptsToPay({
      data: entitiesOfSelectedRows.map((entity) => ({
        docId: entity.erpId,
        docReference: entity.reference,
        amount: entity.value,
      })),
      totalAmount: entitiesOfSelectedRows.reduce(
        (acc, entity) => acc + entity.value,
        0
      ),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  const getInvoice = ({ erpId, docType, docTypeErp, reference }) => {
    getReceiptInvoice(erpId, docTypeErp)
      .then((data) => {
        downLoadPdfFile(data, `${docType}_${reference}.pdf`);
      })
      .catch(({ response: { status } }) => {
        setIsLoading(false);
        if (status === 401) {
          navigate('/login');
        } else if (status === 400 || status > 401) {
          setNotificationProps((prevState) => ({
            ...prevState,
            isOpened: true,
            type: 'error',
            errorMessage: 'Documento não encontrado',
          }));
        }
      });
  };

  const getMbPayment = (data) => {
    getMbPaymentData(data)
      .then(({ data }) => {
        setPaymentData(data);
        setModalIsOpened(true);
        setSelectedRowIds([]);
      })
      .catch(({ response: { status } }) => {
        setIsLoading(false);
        if (status === 401) {
          navigate('/login');
        } else if (status === 400 || status > 401) {
          setNotificationProps((prevState) => ({
            ...prevState,
            isOpened: true,
            type: 'error',
            errorMessage: 'Não foi possível gerar os dados para pagamento',
          }));
        }
      });
  };

  //  receipts balances
  let invoicedAmounts = 0;
  if (!!entities.length) {
    invoicedAmounts = entities?.reduce(
      (acc, invoice) =>
        parseFloat(Math.fround(acc + invoice?.value).toFixed(2)),
      0
    );
  }
  
  const columns = [
    {
      field: 'reference',
      headerName: 'Número',
      flex: 0.5,
    },
    {
      field: 'docType',
      headerName: 'Documento',
      flex: 0.75,
    },
    {
      field: 'date',
      headerName: 'Data',
      flex: 0.75,
      valueGetter: (date) =>
        !!date.value
          ? new Date(date.value).toLocaleDateString('pt-PT')
          : date.value,
    },
    {
      field: 'status',
      headerName: 'Estado',
      flex: 1,
    },
    {
      field: 'dueDate',
      headerName: 'Data Vencimento',
      flex: 0.75,
      valueGetter: (date) =>
        !!date.value
          ? new Date(date.value).toLocaleDateString('pt-PT')
          : date.value,
    },
    {
      field: 'value',
      headerName: 'Total Documento',
      disableColumnMenu: true,
      sortable: false,
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (total) =>
        !!total.value ? formatCurrency(total.value) : total.value,
    },
    {
      field: 'balance',
      headerName: 'Por Liquidar',
      disableColumnMenu: true,
      sortable: false,
      type: 'number',
      flex: 0.6,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (balance) =>
        !!balance.value ? formatCurrency(balance.value) : balance.value,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) =>
        kebabCase(row.status) === 'liquidado' && (
          <IconButton
            size='small'
            title='Download do documento'
            onClick={() => getInvoice(row)}
          >
            <GrDocumentPdf size={16} />
          </IconButton>
        ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          flex: 3,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box marginBottom={3}>
          <MyAccountTitle>Conta Corrente</MyAccountTitle>
          <Typography variant='small'>
            Consulte aqui todos os seus documentos disponíveis
          </Typography>
        </Box>

        {isLoading ? (
          <Loading />
        ) : (
          <>
            {!!entities.length ? (
              <Box
                sx={{
                  display: 'grid',
                  gap: 4,
                }}
              >
                <Box
                  sx={{
                    display: 'grid',
                    gap: 0.5,
                  }}
                >
                  <Typography>
                    Valores Faturados
                    <Typography
                      component='span'
                      sx={{
                        marginLeft: 1.5,
                        fontWeight: 600,
                      }}
                    >
                      {formatCurrency(invoicedAmounts)}
                    </Typography>
                  </Typography>
                  <Typography>
                    Valores em Aberto
                    <Typography
                      component='span'
                      sx={{
                        marginLeft: 1.5,
                        fontWeight: 600,
                      }}
                    >
                      {formatCurrency(openAmounts)}
                    </Typography>
                  </Typography>
                </Box>

                <Box>
                  <Typography fontWeight={FONT_STYLE.medium}>
                    Para liquidar os seus documentos selecione uma de duas
                    formas:
                  </Typography>
                  <Box component='ul'>
                    <Typography component='li' variant='xsmall'>
                      Clique na checkbox para selecionar de uma vez só todos os
                      documentos em aberto
                    </Typography>
                    <Typography component='li' variant='xsmall'>
                      Selecione documento a documento clicando em cada um
                    </Typography>
                  </Box>
                  <IconButton
                    variant='contained'
                    size='small'
                    title='Gerar referência'
                    disabled={!!!receiptsToPay.data.length}
                    onClick={() => getMbPayment(receiptsToPay.data)}
                    startIcon={<IconMultibanco />}
                    sx={{
                      marginTop: 1.5,
                      gap: 1,
                    }}
                  >
                    Gerar referência
                    <strong>{formatCurrency(receiptsToPay.totalAmount)}</strong>
                  </IconButton>
                </Box>

                <DataGrid
                  rows={entities}
                  columns={columns}
                  autoHeight
                  density='compact'
                  getRowClassName={({ row: { status } }) =>
                    kebabCase(status) === 'por-liquidar'
                      ? 'MuiDataGrid-row--red'
                      : kebabCase(status) === 'aguarda-pagamento'
                      ? 'MuiDataGrid-row--yellow'
                      : ''
                  }
                  checkboxSelection
                  disableRowSelectionOnClick
                  isRowSelectable={({ row: { ableToPay } }) => ableToPay}
                  onRowSelectionModelChange={(rowId) =>
                    setSelectedRowIds([...rowId])
                  }
                  pageSizeOptions={[15, 30, 45]}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 15,
                      },
                    },
                  }}
                  sx={{
                    '& .MuiDataGrid-row--red': {
                      backgroundColor: '#ff000052',
                    },
                    '& .MuiDataGrid-row--yellow': {
                      backgroundColor: '#ffff0052',
                    },
                  }}
                />
              </Box>
            ) : (
              <Typography sx={{ marginY: 2 }}>
                Não há registos para mostrar.
              </Typography>
            )}
          </>
        )}
      </Box>
      {modalIsOpened && (
        <PaymentDataModal
          modalIsOpened={modalIsOpened}
          setModalIsOpened={setModalIsOpened}
          paymentData={paymentData}
        />
      )}
      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default AccountReceipts;
