import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteProductFromShoppingList } from '../../../api/user';
import { formatCurrency } from '../../../constants/utils';
import { productDetailsInterface } from '../../../helpers/productDetailsInterface';
import { setShoppingLists } from '../../../state/shoppingListsSlice';
import { BadgeDiscount, IconButton } from '../../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../../variables';
import SummaryProductInfo from '../../Cart/FirstStepSummary/SummaryTable/SummaryProductInfo';
import PopupNotification from '../../PopupNotification/PopupNotification';

const ShoppingListProducts = ({ index, shoppingLists }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: 'Produto removido',
    errorMessage: '',
  });

  const { _id: id, products } = shoppingLists?.[index];

  const handleDeleteProductFromShoppingList = (productId) => {
    deleteProductFromShoppingList(id, productId)
      .then((lists) => {
        dispatch(setShoppingLists(lists));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));
          }
        }
      );
  };

  return (
    <Box
      sx={{
        marginTop: 4,
      }}
    >
      {shoppingLists?.[index]?.products.length !== 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: { xs: '275px', md: 'initial' } }}>
                    Detalhes
                  </TableCell>
                  <TableCell sx={{ minWidth: { xs: '150px', md: 'initial' } }}>
                    Preço*
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {Array.isArray(products) &&
                  products.map((product) => {
                    const {
                      id,
                      slug,
                      title,
                      brand,
                      reference,
                      itemsPerBox,
                      images,
                    } = product;
                    const productDetails = productDetailsInterface(product);

                    return (
                      <TableRow
                        key={`shoppingList-product-${reference}-${id}`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>
                          <SummaryProductInfo
                            slug={slug}
                            images={images}
                            title={title}
                            reference={reference}
                            brand={brand}
                            itemsPerBox={itemsPerBox}
                          />
                        </TableCell>

                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                textDecoration:
                                  productDetails.onSale && 'line-through',
                              }}
                            >
                              {formatCurrency(productDetails.price)}
                            </Typography>

                            {!!productDetails.onSale && (
                              <>
                                <Typography
                                  sx={{
                                    fontWeight: FONT_STYLE.bold,
                                    color: CUSTOM_THEME_COLORS.red,
                                  }}
                                >
                                  {formatCurrency(productDetails.salePrice)}
                                </Typography>
                                {!!productDetails.saleAmount && (
                                  <BadgeDiscount>{`${productDetails.saleAmount}%`}</BadgeDiscount>
                                )}
                              </>
                            )}
                          </Box>
                        </TableCell>

                        <TableCell>
                          <IconButton
                            onClick={() =>
                              handleDeleteProductFromShoppingList(id)
                            }
                            title='Remover'
                            sx={{
                              minWidth: 'initial',
                            }}
                          >
                            <FiTrash2 size={16} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            variant='xsmall'
            sx={{
              textAlign: 'right',
              marginTop: 1,
            }}
          >
            * Preço sem IVA
          </Typography>
        </>
      ) : (
        <Typography>Não tem produtos associados a esta lista.</Typography>
      )}

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </Box>
  );
};

export default ShoppingListProducts;
