import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../constants/utils';
import { BadgeDiscount } from '../../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../../variables';

const ProductPrices = ({ details }) => {
  const customerDiscount = useSelector(
    (state) => state?.user?.customerDiscount
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          marginTop: 3,
          gap: { xs: 5, md: 8 },
        }}
      >
        <Box>
          <Typography
            sx={{
              marginBottom: 1,
              fontWeight: FONT_STYLE.bold,
            }}
          >
            Preço (s/ IVA)
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: '1.15rem',
                textDecoration: details.onSale && 'line-through',
              }}
            >
              {formatCurrency(details.price)}
            </Typography>
            {!!details.onSale && (
              <>
                <Typography
                  sx={{
                    fontSize: '1.15rem',
                    color: CUSTOM_THEME_COLORS.red,
                    fontWeight: FONT_STYLE.bold,
                  }}
                >
                  {formatCurrency(details.salePrice)}
                </Typography>
                {!!details.saleAmount && (
                  <BadgeDiscount>{`${details.saleAmount}%`}</BadgeDiscount>
                )}
              </>
            )}
          </Box>
        </Box>

        {!!!details.onSale && customerDiscount !== 0 && (
          <Box>
            <Typography
              sx={{
                marginBottom: 1,
                fontWeight: FONT_STYLE.bold,
              }}
            >
              Desconto cliente
            </Typography>
            <BadgeDiscount>{`${customerDiscount}%`}</BadgeDiscount>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProductPrices;
