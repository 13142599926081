import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import kebabCase from 'lodash/kebabCase';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../constants/utils';
import { imageOnError } from '../../../helpers';
import { BadgeDiscount } from '../../../styles';
import { CUSTOM_THEME_COLORS, TRANSITION_DURATION } from '../../../variables';
import ImageZoomModal from '../../ImageZoomModal';
import Quantity from '../../Quantity/Quantity';

const ProductVariantsTable = ({
  variants,
  variationName,
  userIsLogged,
  setUpdatedProductQuantity,
}) => {
  const theme = useTheme();

  const customerDiscount = useSelector(
    (state) => state?.user?.customerDiscount
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState('');

  const variantsHasImages = true;

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          size='small'
          id='product-variants'
        >
          <TableHead>
            <TableRow>
              {variantsHasImages && <TableCell>Imagem</TableCell>}
              <TableCell>Referência</TableCell>
              {variationName && <TableCell>{variationName}</TableCell>}
              <TableCell>Nome</TableCell>
              {userIsLogged && (
                <>
                  <TableCell>Quantidade</TableCell>
                  <TableCell>Desconto cliente</TableCell>
                  <TableCell>Preço</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {variants.map((product) => {
              const {
                reference,
                images,
                title,
                _onSale,
                _onSaleBox,
                _saleAmount,
                _price,
                _salePrice,
                boxPrice,
                _boxSalePrice,
                variationField,
                params,
              } = product;

              const variationValue = params.find(
                (param) => kebabCase(param.title) === kebabCase(variationField)
              )?.value;

              return (
                <TableRow
                  key={`product-variants-${reference}`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 'none' },
                  }}
                >
                  {variantsHasImages && (
                    <TableCell>
                      <Button
                        title='Ver imagem'
                        onClick={() => {
                          setZoomImagePath(images[0]);
                          setModalIsOpen(true);
                        }}
                        sx={{
                          display: 'flex',
                          minWidth: 'initial',
                          padding: 0,
                          marginX: 'auto',
                          overflow: 'hidden',
                          borderRadius: theme.shape.borderRadius / 10,
                          border: `1px solid ${CUSTOM_THEME_COLORS.gray}`,
                          transition: `border-color ${TRANSITION_DURATION}ms ease`,
                          '&:hover': {
                            borderColor: CUSTOM_THEME_COLORS.primary,
                            cursor: 'zoom-in',
                          },
                        }}
                      >
                        <Box
                          component='img'
                          alt={title}
                          src={images[0]}
                          onError={(e) => {
                            imageOnError(e);
                          }}
                          sx={{
                            maxWidth: '48px',
                            aspectRatio: '1',
                            objectFit: 'contain',
                            border: `${theme.spacing(0.5)} solid white`,
                          }}
                        />
                      </Button>
                    </TableCell>
                  )}
                  <TableCell>{reference}</TableCell>
                  {variationName && (
                    <TableCell>
                      {variationValue ? variationValue : '-'}
                    </TableCell>
                  )}
                  <TableCell>{title}</TableCell>
                  {userIsLogged && (
                    <>
                      <TableCell>
                        <Quantity
                          product={product}
                          setUpdatedProductQuantity={setUpdatedProductQuantity}
                          variant
                        />
                      </TableCell>
                      <TableCell>
                        {customerDiscount !== 0 ? (
                          <BadgeDiscount>{`${customerDiscount}%`}</BadgeDiscount>
                        ) : (
                          <Typography>-</Typography>
                        )}
                        {/* {onSale || onSaleBox ? (
                          <BadgeDiscount>{`${saleAmount}%`}</BadgeDiscount>
                        ) : (
                          <Box>-</Box>
                        )} */}
                      </TableCell>
                      <TableCell>
                        <Box>
                          {/* <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography
                              variant='small'
                              sx={{ fontWeight: FONT_STYLE.bold }}
                            >
                              un
                            </Typography>
                            <Typography variant='small'>
                              {formatCurrency(price)}
                            </Typography>
                          </Box> */}
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: 1,
                            }}
                          >
                            {/* <Typography
                              variant='small'
                              sx={{ fontWeight: FONT_STYLE.bold }}
                            >
                              cx
                            </Typography> */}
                            <Typography variant='small'>
                              {formatCurrency(boxPrice)}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      {/* <TableCell>
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography
                              variant='small'
                              sx={{ fontWeight: FONT_STYLE.bold }}
                            >
                              un
                            </Typography>
                            <Typography variant='small'>
                              {formatCurrency(salePrice)}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography
                              variant='small'
                              sx={{ fontWeight: FONT_STYLE.bold }}
                            >
                              cx
                            </Typography>
                            <Typography variant='small'>
                              {formatCurrency(boxSalePrice)}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell> */}
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ImageZoomModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        imagePath={zoomImagePath}
      />
    </>
  );
};

export default React.memo(ProductVariantsTable);
