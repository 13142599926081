import { useState } from 'react';
import { useSelector } from 'react-redux';
import { StyledTextButton } from '../../../styles';
import ShoppingListSelectorModal from './ShoppingListSelectorModal';

const AddShoppingListButton = ({ productId }) => {
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const shoppingLists = useSelector(
    (state) => state.shoppingLists.shoppingLists
  );

  return (
    <>
      <StyledTextButton
        variant='contained'
        color='secondary'
        title='Adicionar à Lista de Compras'
        onClick={() => setModalIsOpened(true)}
      >
        Adicionar à Lista de Compras
      </StyledTextButton>

      <ShoppingListSelectorModal
        isOpen={modalIsOpened}
        setIsOpen={setModalIsOpened}
        shoppingLists={shoppingLists}
        productId={productId}
      />
    </>
  );
};

export default AddShoppingListButton;
