import {
  Box,
  Button,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FiCheck, FiEdit2, FiPercent, FiTrash2 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateCartProducts } from '../../../../api/checkout';
import { formatCurrency } from '../../../../constants/utils';
import {
  setCart,
  setCartObject,
  setCartSubtotal,
  setCartTaxes,
  setCartTotal,
  setDiscount,
} from '../../../../state/cartSlice';
import { BadgeDiscount, IconButton } from '../../../../styles';
import {
  CUSTOM_THEME_COLORS,
  FONT_STYLE,
  SETTINGS,
} from '../../../../variables';
import Quantity from '../../../Quantity/Quantity';
import DiscountSelectorModal from '../DiscountSelectorModal';
import SummaryProductInfo from './SummaryProductInfo';

const SummaryTable = ({ cartItems, cartSubtotal, selectedClient, role }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const campaignProducts = useSelector((state) => state.cart.campaignProducts);

  const showDiscountOptions = role === 'seller';
  const showStockAvailability = true;

  const [isApplyDiscountOpen, setIsApplyDiscountOpen] = useState(false);
  const [iconButton, setIconButton] = useState(null);
  const [editDiscountToProduct, setEditDiscountToProduct] = useState();
  const [updatedProductQuantity, setUpdatedProductQuantity] =
    useState(undefined);

  const checkCampaignProducts = (cartProducts) => {
    if (!!!campaignProducts.length) return cartProducts;

    //  remove a specific campaign
    const removeCampaign = (campaignProduct) => {
      //  get index of campaign to remove
      const indexOfCampaignToRemove = campaignProducts.findIndex(
        (campaign) =>
          campaign.campaignProductId === campaignProduct.campaignProductId &&
          campaign.offerProductId === campaignProduct.offerProductId
      );
      //  new array without campaign to remove
      const campaignsArrayUpdated = campaignProducts.filter(
        (campaign, index) => index !== indexOfCampaignToRemove
      );
      dispatch(
        setCartObject({
          campaignProducts: campaignsArrayUpdated,
        })
      );
    };

    return campaignProducts.flatMap((campaign) => {
      const campaignProduct = cartProducts.find(
        (cartProduct) => cartProduct.id === campaign.campaignProductId
      );
      const offerProduct = cartProducts.find(
        (cartProduct) => cartProduct.id === campaign.offerProductId
      );

      //  IF 'offer product' OR 'campaign product' was removed » remove campaign
      if (
        offerProduct?.quantity?.[SETTINGS.defaults.quantityType] === 0 ||
        campaignProduct?.quantity?.[SETTINGS.defaults.quantityType] === 0
      ) {
        removeCampaign(campaign);
        //  IF we remove 'campaign product' » remove discount from 'offer product'
        if (offerProduct) {
          cartProducts = cartProducts.map((product) => ({
            ...product,
            discount:
              product.id === campaign.offerProductId ? 0 : product.discount,
          }));
        }
        return cartProducts;
      }

      //  IF exists 'campaign product' inside cart
      if (campaignProduct) {
        //  IF 'campaign product' doesn't have minimum quantities
        if (
          campaignProduct?.quantity?.[SETTINGS.defaults.quantityType] <
          campaign.minQuantity
        ) {
          //  remove campaign
          removeCampaign(campaign);

          //  remove 'offering product' from cart
          cartProducts = cartProducts.map((product) => ({
            ...product,
            quantity:
              product.id === campaign.offerProductId
                ? {
                    ...product.quantity,
                    [SETTINGS.defaults.quantityType]: 0,
                  }
                : { ...product.quantity },
          }));
        }
        return cartProducts;
      }
      //  return 'cartProducts' without changes
      return cartProducts;
    });
  };

  //  when some product changes his quantity
  useEffect(() => {
    if (!updatedProductQuantity) return;

    //  update product quantities of product changed
    let updatedProductsQuantities = cartItems.map((product) => ({
      ...product,
      quantity:
        product.id === updatedProductQuantity.id
          ? updatedProductQuantity.quantity
          : product.quantity,
    }));

    //  check campaign products
    updatedProductsQuantities = checkCampaignProducts(
      updatedProductsQuantities
    );

    updateCartProducts(updatedProductsQuantities, selectedClient)
      .then(({ cart_items, total_discount, taxes, subtotal, total }) => {
        dispatch(setCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTaxes(taxes));
        dispatch(setCartSubtotal(subtotal));
        dispatch(setCartTotal(total));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProductQuantity, dispatch]);

  const updateCartItemDiscount = (product, discount, priceWithDiscount) => {
    const productWithDiscount = [
      {
        ...product,
        discountPrice: priceWithDiscount,
        discount: discount,
      },
    ];

    // POST REQUEST » it will trigger a cart object change
    updateCartProducts(productWithDiscount, selectedClient)
      .then(({ cart_items, total_discount, taxes, subtotal, total }) => {
        dispatch(setCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTaxes(taxes));
        dispatch(setCartSubtotal(subtotal));
        dispatch(setCartTotal(total));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  const deleteProductFromCart = (id) => {
    let cartProductsUpdated = cartItems.map((product) => ({
      ...product,
      quantity: {
        unit: product.id === id ? 0 : product.quantity.unit,
        box: product.id === id ? 0 : product.quantity.box,
      },
    }));

    //  check campaign products
    cartProductsUpdated = checkCampaignProducts(cartProductsUpdated);

    updateCartProducts(cartProductsUpdated, selectedClient)
      .then(({ cart_items, total_discount, taxes, subtotal, total }) => {
        dispatch(setCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTaxes(taxes));
        dispatch(setCartSubtotal(subtotal));
        dispatch(setCartTotal(total));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  const deleteAllProductsFromCart = () => {
    let productsQuantitiesToZero = cartItems.map((product) => ({
      ...product,
      quantity: {
        unit: 0,
        box: 0,
      },
    }));

    //  check campaign products
    productsQuantitiesToZero = checkCampaignProducts(productsQuantitiesToZero);

    updateCartProducts(productsQuantitiesToZero, selectedClient)
      .then(({ cart_items, total_discount, taxes, subtotal, total }) => {
        //  clear active campaigns
        dispatch(
          setCartObject({
            campaignProducts: [],
          })
        );
        dispatch(setCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTaxes(taxes));
        dispatch(setCartSubtotal(subtotal));
        dispatch(setCartTotal(total));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ marginY: 1 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: { xs: '275px', md: 'initial' } }}>
                Detalhes
              </TableCell>
              <TableCell sx={{ minWidth: { xs: '140px', md: 'initial' } }}>
                Quantidade
              </TableCell>
              <TableCell
                sx={{
                  minWidth: { xs: '150px', md: 'initial' },
                }}
              >
                Preço*
              </TableCell>
              {showDiscountOptions && (
                <TableCell sx={{ minWidth: { xs: '240px', md: 'initial' } }}>
                  Desconto de Vendedor
                </TableCell>
              )}
              <TableCell
                sx={{
                  minWidth: { xs: '100px', md: 'initial' },
                }}
              >
                Subtotal*
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(cartItems) &&
              cartItems.map((product, index) => {
                const {
                  id,
                  slug,
                  title,
                  brand,
                  reference,
                  quantity,
                  images,
                  discount,
                  saleAmount,
                  //  BOX
                  onSale,
                  price,
                  salePrice,
                  stock,
                  //  UNIT
                  onSaleBox,
                  boxPrice,
                  boxSalePrice,
                  itemsPerBox,
                  stockPerBox,
                } = product;
                const quantityTypeIsUnit = !!quantity.unit;

                const productDetails = {
                  pricePerUnit: quantityTypeIsUnit //  unit price
                    ? onSale
                      ? salePrice
                      : price
                    : onSaleBox //  box price
                    ? boxSalePrice
                    : boxPrice,
                  onSale: quantityTypeIsUnit ? onSale : onSaleBox,
                  price: quantityTypeIsUnit ? price : boxPrice,
                  salePrice: quantityTypeIsUnit ? salePrice : boxSalePrice,
                  hasDiscount: discount > 0,
                  quantity: quantity.unit + quantity.box,
                  stock: quantityTypeIsUnit ? stock : stockPerBox,
                };
                const subtotal =
                  productDetails.pricePerUnit * productDetails.quantity;
                const subtotalWithDiscount = parseFloat(
                  Math.fround(
                    productDetails.pricePerUnit * productDetails.quantity -
                      (productDetails.pricePerUnit *
                        productDetails.quantity *
                        discount) /
                        100
                  ).toFixed(2)
                );

                const isProductOffer = !!campaignProducts.find(
                  (product) => product.offerProductId === id
                );

                return (
                  <TableRow
                    key={`cart-item-${reference}-${index}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {/* designation */}
                    <TableCell>
                      <SummaryProductInfo
                        slug={slug}
                        images={images}
                        title={title}
                        reference={reference}
                        brand={brand}
                        itemsPerBox={itemsPerBox}
                        showStockAvailability={showStockAvailability}
                        stock={productDetails.stock}
                        isProductOffer={isProductOffer}
                      />
                    </TableCell>

                    {/* quantity */}
                    <TableCell>
                      <Quantity
                        product={product}
                        setUpdatedProductQuantity={setUpdatedProductQuantity}
                        disableQuantity={isProductOffer}
                      />
                    </TableCell>

                    {/* your price */}
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            textDecoration:
                              productDetails.onSale && 'line-through',
                          }}
                        >
                          {formatCurrency(productDetails.price)}
                        </Typography>

                        {!!productDetails.onSale && (
                          <>
                            <Typography
                              sx={{
                                fontWeight: FONT_STYLE.bold,
                                color: CUSTOM_THEME_COLORS.red,
                              }}
                            >
                              {formatCurrency(productDetails.salePrice)}
                            </Typography>
                            {!!saleAmount && (
                              <BadgeDiscount>{`${saleAmount}%`}</BadgeDiscount>
                            )}
                          </>
                        )}
                      </Box>
                    </TableCell>

                    {/* seller discount */}
                    {showDiscountOptions && (
                      <TableCell>
                        <IconButton
                          title={
                            productDetails.hasDiscount
                              ? 'Editar desconto'
                              : 'Aplicar desconto'
                          }
                          sx={{
                            gap: 1,
                            color:
                              productDetails.hasDiscount &&
                              CUSTOM_THEME_COLORS.red,
                            fontWeight: productDetails.hasDiscount && 600,
                          }}
                          onMouseEnter={() => {
                            return (
                              productDetails.hasDiscount && setIconButton(index)
                            );
                          }}
                          onMouseLeave={() => {
                            return (
                              productDetails.hasDiscount && setIconButton(null)
                            );
                          }}
                          onClick={() => {
                            setEditDiscountToProduct(product);
                            setIsApplyDiscountOpen(true);
                          }}
                        >
                          <Icon
                            sx={{
                              backgroundColor: CUSTOM_THEME_COLORS.gray,
                              borderRadius: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {productDetails.hasDiscount ? (
                              iconButton === index ? (
                                <FiEdit2 size={12} />
                              ) : (
                                <FiCheck size={12} />
                              )
                            ) : (
                              <FiPercent size={12} />
                            )}
                          </Icon>
                          {productDetails.hasDiscount
                            ? `Desconto ${discount}%`
                            : `Aplicar desconto`}
                        </IconButton>
                      </TableCell>
                    )}

                    {/* subtotal */}
                    <TableCell>
                      <Box
                        sx={{
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            textDecoration:
                              productDetails.hasDiscount && 'line-through',
                          }}
                        >
                          {formatCurrency(subtotal)}
                        </Typography>

                        {productDetails.hasDiscount && (
                          <Typography
                            sx={{
                              color: CUSTOM_THEME_COLORS.red,
                              fontWeight: FONT_STYLE.bold,
                            }}
                          >
                            {formatCurrency(subtotalWithDiscount)}
                          </Typography>
                        )}
                      </Box>
                    </TableCell>

                    <TableCell>
                      <IconButton
                        onClick={() => deleteProductFromCart(id)}
                        title='Remover'
                      >
                        <FiTrash2 size={16} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          alignItems: { sm: 'flex-start' },
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <Box>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            title='Limpar carrinho'
            sx={{
              borderColor: CUSTOM_THEME_COLORS.red,
              color: CUSTOM_THEME_COLORS.red,
              '&:hover': {
                backgroundColor: CUSTOM_THEME_COLORS.red,
              },
            }}
            onClick={() => deleteAllProductsFromCart()}
          >
            Limpar carrinho
          </Button>
        </Box>
        <Box>
          <Typography
            variant='xsmall'
            sx={{
              textAlign: 'right',
            }}
          >
            * Preço sem IVA
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: { sm: 1 },
              marginTop: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: FONT_STYLE.medium,
              }}
            >
              Total da encomenda:
            </Typography>
            <Typography
              sx={{
                fontSize: '1.6rem',
                fontWeight: FONT_STYLE.black,
                color: CUSTOM_THEME_COLORS.primary,
              }}
            >
              {formatCurrency(cartSubtotal)}
            </Typography>
          </Box>
        </Box>
      </Box>

      {isApplyDiscountOpen && (
        <DiscountSelectorModal
          isApplyDiscountOpen={isApplyDiscountOpen}
          setIsApplyDiscountOpen={setIsApplyDiscountOpen}
          updateCartItemDiscount={updateCartItemDiscount}
          product={editDiscountToProduct}
        />
      )}
    </>
  );
};

export default React.memo(SummaryTable);
