import React from 'react';
import { Cookies, CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import Login from './components/Login/Login';
import NewClientForm from './components/Login/NewClientForm';
import PasswordRecovery from './components/Login/PasswordRecovery';
import AccountAccess from './components/UserAccount/AccountAccess/AccountAccess';
import AccountAddresses from './components/UserAccount/AccountAddresses/AccountAddresses';
import AccountInfo from './components/UserAccount/AccountInfo/AccountInfo';
import AccountPurchases from './components/UserAccount/AccountPurchases/AccountPurchases';
// import AccountUsers from './components/UserAccount/AccountUsers';
import { ThemeProvider } from '@mui/material';
import InstitucionalPages from './components/InstitucionalPages/InstitucionalPages';
import NewAccountForm from './components/Login/NewAccountForm';
import AccountReceipts from './components/UserAccount/AccountReceipts/AccountReceipts';
import ProductsHistory from './components/UserAccount/ProductsHistory';
import ShoppingLists from './components/UserAccount/ShoppingLists/ShoppingLists';
import { ProductsListProvider } from './contexts/ProductsListContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AccountPage from './routes/AccountPage';
import BrandPage from './routes/BrandPage';
import BrandsPage from './routes/BrandsPage';
import Campaigns from './routes/Campaigns';
import CartPage from './routes/CartPage';
import ContactsPage from './routes/Contacts';
import HomePage from './routes/HomePage';
import LatestProducts from './routes/LatestProducts';
import LoginPage from './routes/LoginPage';
import MyProducts from './routes/MyProducts';
import NotFoundPage from './routes/NotFoundPage';
import ProductPage from './routes/ProductPage';
import ProductsPage from './routes/ProductsPage';
import SearchPage from './routes/SearchPage';
import store from './state/store';
import { theme } from './styles';
import { NOT_FOUND_ROUTE, PAGES_SLUGS } from './variables';

const ProtectedRoute = ({ children }) => {
  const authentication = new Cookies().get('userToken');

  if (!authentication) {
    return (
      <Navigate
        to='/login'
        replace
      />
    );
  }

  return children ? children : <Outlet />;
};

let persistor = persistStore(store);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
        >
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes>
                <Route
                  path={PAGES_SLUGS.login}
                  element={<LoginPage />}
                >
                  <Route
                    index
                    element={<Login />}
                  />
                  <Route
                    path={PAGES_SLUGS.recoverPassword}
                    element={<PasswordRecovery />}
                  />
                  <Route
                    path={PAGES_SLUGS.createUser}
                    element={<NewClientForm />}
                  />
                  <Route
                    path={PAGES_SLUGS.createFastUser}
                    element={<NewAccountForm />}
                  />
                </Route>
                <Route
                  path='/'
                  element={<App />}
                >
                  <Route
                    index
                    element={<HomePage />}
                  />
                  <Route
                    path={PAGES_SLUGS.search}
                    element={
                      <ProductsListProvider>
                        <SearchPage />
                      </ProductsListProvider>
                    }
                  />
                  <Route
                    path={PAGES_SLUGS.news}
                    element={
                      <ProductsListProvider>
                        <LatestProducts />
                      </ProductsListProvider>
                    }
                  />
                  <Route
                    path={PAGES_SLUGS.myProducts}
                    element={
                      <ProductsListProvider>
                        <MyProducts />
                      </ProductsListProvider>
                    }
                  />
                  <Route
                    path={PAGES_SLUGS.campaigns}
                    element={<Campaigns />}
                  />
                  <Route
                    path={PAGES_SLUGS.brands}
                    element={<BrandsPage />}
                  />
                  <Route
                    path={`${PAGES_SLUGS.brands}/:brandSlug`}
                    element={<BrandPage />}
                  />
                  <Route
                    path={PAGES_SLUGS.contacts}
                    element={<ContactsPage />}
                  />
                  <Route
                    path={PAGES_SLUGS.cart}
                    element={<CartPage />}
                  />
                  <Route element={<ProtectedRoute />}>
                    <Route
                      path={PAGES_SLUGS.account}
                      element={<AccountPage />}
                    >
                      <Route
                        path={PAGES_SLUGS.myAccount.info}
                        element={<AccountInfo />}
                      />
                      <Route
                        path={PAGES_SLUGS.myAccount.accessData}
                        element={<AccountAccess />}
                      />
                      <Route
                        path={PAGES_SLUGS.myAccount.addresses}
                        element={<AccountAddresses />}
                      />
                      <Route
                        path={PAGES_SLUGS.myAccount.orders}
                        element={<AccountPurchases />}
                      />
                      {/* <Route
                          path={PAGES_SLUGS.myAccount.users}
                          element={<AccountUsers />}
                        /> */}
                      <Route
                        path={PAGES_SLUGS.myAccount.shoppingList}
                        element={<ShoppingLists />}
                      />
                      <Route
                        path={PAGES_SLUGS.myAccount.receipts}
                        element={<AccountReceipts />}
                      />
                      <Route
                        path={PAGES_SLUGS.myAccount.productsHistory}
                        element={<ProductsHistory />}
                      />
                    </Route>
                  </Route>
                  {/* NOT FOUND ROUTES */}
                  {[`${NOT_FOUND_ROUTE}`, '*'].map((path, index) => (
                    <Route
                      key={index}
                      path={path}
                      element={<NotFoundPage />}
                    />
                  ))}
                  {/* PRODUCTS LIST */}
                  {[
                    PAGES_SLUGS.allProducts,
                    `${PAGES_SLUGS.allProducts}/:collectionSlug`,
                    `${PAGES_SLUGS.allProducts}/:collectionSlug/:categorySlug`,
                    `${PAGES_SLUGS.allProducts}/:collectionSlug/:categorySlug/:subcategorySlug`,
                  ].map((path, index) => (
                    <Route
                      key={index}
                      path={path}
                      element={
                        <ProductsListProvider>
                          <ProductsPage />
                        </ProductsListProvider>
                      }
                    />
                  ))}
                  <Route
                    path={`${PAGES_SLUGS.productDetail}/:productSlug`}
                    element={<ProductPage />}
                  />
                  {/* INSTITUTIONAL PAGES */}
                  {[
                    PAGES_SLUGS.institutional.aboutUs,
                    PAGES_SLUGS.institutional.termsAndConditions,
                    PAGES_SLUGS.institutional.privacyPolicy,
                    PAGES_SLUGS.institutional.alternativeDisputeResolution,
                    PAGES_SLUGS.institutional.businessAreas,
                    PAGES_SLUGS.institutional.distribution,
                    // PAGES_SLUGS.institutional.faqs,
                  ].map((path, index) => (
                    <Route
                      key={index}
                      path={path}
                      element={<InstitucionalPages />}
                    />
                  ))}
                </Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
