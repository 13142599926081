import { toNumber } from 'lodash';
import React, { useState } from 'react';
import { SETTINGS } from '../../variables';
import QuantitySelector from './QuantitySelector';

const Quantity = ({
  product,
  setUpdatedProductQuantity,
  variant,
  disableQuantity,
}) => {
  const { quantity, stockPerBox } = product;
  const quantityMultiplier = 1;
  const isOutOfStock = stockPerBox <= 0;

  const [productQuantity, setProductQuantity] = useState(quantity);

  //  if any quantity (unit/box) cames filled » add it to default 'quantity type'
  const defaultQuantityType = Object.entries(productQuantity).filter(
    (item) => item[1] !== 0 && item[0]
  )?.[0]?.[0];

  const [quantityType, setQuantityType] = useState(
    defaultQuantityType || SETTINGS.defaults.quantityType
  );
  const [updatedQuantity, setUpdatedQuantity] = useState(
    productQuantity[quantityType]
  );

  const decrementDisabled =
    disableQuantity ||
    (variant && updatedQuantity === 0) ||
    (!variant && updatedQuantity <= quantityMultiplier);

  const changeQuantityType = (e) => {
    setQuantityType(e);

    if (updatedQuantity === 0) return;

    //  switch between quantities type » 'unit' & 'box'
    setProductQuantity((prevState) => {
      Object.keys(productQuantity).forEach((item) =>
        item === e ? (prevState[item] = updatedQuantity) : (prevState[item] = 0)
      );
      return prevState;
    });

    setUpdatedProductQuantity({
      ...product,
      quantity: productQuantity,
    });
  };

  const decrement = () => {
    if (updatedQuantity < quantityMultiplier) return;
    const newQuantity = updatedQuantity - quantityMultiplier;
    setUpdatedQuantity(newQuantity);
    setUpdatedProductQuantity({
      ...product,
      quantity: {
        ...productQuantity,
        [quantityType]: newQuantity,
      },
    });
  };

  const increment = () => {
    const newQuantity = updatedQuantity + quantityMultiplier;
    setUpdatedQuantity(newQuantity);
    setUpdatedProductQuantity({
      ...product,
      quantity: {
        ...productQuantity,
        [quantityType]: newQuantity,
      },
    });
  };

  const openFieldChange = (e) => {
    const newQuantity = e;

    //  IF number of caracteres is greater than 3 caracteres » return
    if (newQuantity.length > 3) return;
    const newQuantityNumbered = toNumber(newQuantity);
    setUpdatedQuantity(newQuantityNumbered);

    //  IF number of caracteres is equal 0 » return
    if (newQuantity.length === 0) return;
    setUpdatedProductQuantity({
      ...product,
      quantity: {
        ...productQuantity,
        [quantityType]: Math.round(newQuantityNumbered),
      },
    });
  };

  return (
    <QuantitySelector
      quantity={updatedQuantity}
      increment={increment}
      decrement={decrement}
      incrementDisabled={disableQuantity}
      decrementDisabled={decrementDisabled}
      openFieldChange={openFieldChange}
      openFieldDisabled={disableQuantity}
      quantityType={quantityType}
      setQuantityType={changeQuantityType}
      isOutOfStock={isOutOfStock}
    />
  );
};

export default React.memo(Quantity);
