import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../../../api/products';
import {
  setCartSubtotal,
  setCartTaxes,
  setCartTotal,
  setDiscount,
  updateCart,
} from '../../../state/cartSlice';
import { MyAccountTitle } from '../../../styles';
import PopupNotification from '../../PopupNotification/PopupNotification';
import CreateShoppingListModal from './CreateShoppingListModal';
import DeleteShoppingListModal from './DeleteShoppingListModal';
import ShoppingListProducts from './ShoppingListProducts';

const ShoppingLists = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const {
    user: { id: customerId },
    shoppingLists: { shoppingLists },
    cart: { campaignProducts },
  } = state;

  const [index, setIndex] = useState(0);
  const [isAddShoppingListOpen, setIsAddShoppingListOpen] = useState(false);
  const [isDeleteShoppingListOpen, setIsDeleteShoppingListOpen] =
    useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: '',
    errorMessage: '',
  });

  const addShoppingListProductsToCart = () => {
    const productsToCart = shoppingLists?.[index]?.products;

    //  GET products without any campaign on cart
    const productsWithoutCampaigns = campaignProducts.flatMap((campaign) =>
      productsToCart.filter((product) => product.id !== campaign.offerProductId)
    );

    addProductsToCart(
      !!productsWithoutCampaigns.length
        ? productsWithoutCampaigns
        : productsToCart,
      customerId
    )
      .then(({ cart_items, total_discount, taxes, subtotal, total }) => {
        dispatch(updateCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTaxes(taxes));
        dispatch(setCartSubtotal(subtotal));
        dispatch(setCartTotal(total));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
          successMessage: 'Produtos adicionados ao carrinho',
        }));
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));
          }
        }
      );
  };

  return (
    <>
      <Box
        sx={{
          flex: 3,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box marginBottom={4}>
          <MyAccountTitle>Listas de Compras</MyAccountTitle>
          <Typography variant='small'>
            Crie listas de compras e associe produtos separadamente.
          </Typography>
        </Box>

        {shoppingLists.length !== 0 ? (
          <Box>
            <FormControl
              sx={{
                width: { xs: '100%', md: '30%' },
              }}
            >
              <InputLabel>Lista selecionada</InputLabel>
              <Select
                label='Lista selecionada'
                value={index}
                onChange={(e) => setIndex(e.target.value)}
              >
                {shoppingLists.map(({ name, _id }, index) => (
                  <MenuItem
                    key={`shopping-list-${_id}`}
                    value={index}
                    title={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <ShoppingListProducts
              index={index}
              shoppingLists={shoppingLists}
            />
          </Box>
        ) : (
          <Typography>Não existem Listas de Compras criadas.</Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            gap: { xs: 1.5, sm: 2 },
            marginTop: 2,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'center', sm: 'initial' },
          }}
        >
          {shoppingLists.length !== 0 && (
            <Button
              variant='contained'
              title='Remover Lista de Compras'
              color='secondary'
              onClick={() => setIsDeleteShoppingListOpen(true)}
            >
              Remover Lista de Compras
            </Button>
          )}

          <Button
            variant='contained'
            color='secondary'
            title='Adicionar Lista de Compras'
            endIcon={<FiPlus size={14} />}
            onClick={() => setIsAddShoppingListOpen(true)}
          >
            Adicionar Lista de Compras
          </Button>

          {shoppingLists.length !== 0 &&
            shoppingLists?.[index]?.products.length !== 0 && (
              <Button
                variant='contained'
                title='Adicionar produtos ao carrinho'
                onClick={() => addShoppingListProductsToCart()}
                sx={{
                  marginLeft: { sm: 'auto' },
                }}
              >
                Adicionar produtos ao carrinho
              </Button>
            )}
        </Box>

        <CreateShoppingListModal
          isOpen={isAddShoppingListOpen}
          setIsOpen={setIsAddShoppingListOpen}
        />

        <DeleteShoppingListModal
          isOpen={isDeleteShoppingListOpen}
          setIsOpen={setIsDeleteShoppingListOpen}
          setIndex={setIndex}
          shoppingLists={shoppingLists}
        />
      </Box>
      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default ShoppingLists;
