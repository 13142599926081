import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import { FONT_STYLE } from '../../variables';
import Slider from '../Slider/Slider';

const MainBanner = ({ mainBanner }) => {
  const theme = useTheme();
  const DESKTOP_DEVICES = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container
      maxWidth='100%'
      sx={{
        paddingBottom: { xs: 2, md: 4 },
        paddingX: { xs: 0 },
      }}
    >
      <Slider loop>
        {mainBanner.map((banner, index) => {
          const { title, description, buttonTitle, buttonUrl, image } = banner;
          return (
            <SwiperSlide key={`home-slidebanner-${index}`}>
              <Card
                sx={{
                  position: 'relative',
                  boxShadow: 'none',
                  borderRadius: '0',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {(image.desktop || image.mobile) && (
                  <CardMedia
                    component='img'
                    image={DESKTOP_DEVICES ? image.desktop : image.mobile}
                    sx={{
                      filter: 'brightness(80%)',
                      height: { xs: '350px', md: '500px' },
                    }}
                  />
                )}

                <Container
                  sx={{
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    paddingLeft: { md: 8, xl: 3 },
                    paddingRight: { md: 8, xl: 3 },
                  }}
                >
                  <Box>
                    {title && (
                      <Typography
                        sx={{
                          fontSize: { xs: '1.5rem', md: '2rem' },
                          lineHeight: '1.15',
                          fontWeight: FONT_STYLE.black,
                          color: 'white',
                        }}
                      >
                        {title}
                      </Typography>
                    )}

                    {description && (
                      <Typography
                        sx={{
                          fontSize: { xs: '0.9rem', md: '1rem' },
                          color: '#fff',
                          marginTop: 1.5,
                        }}
                      >
                        {description}
                      </Typography>
                    )}

                    {buttonTitle && (
                      <Button
                        component={Link}
                        to={buttonUrl}
                        title={buttonTitle}
                        size='small'
                        sx={{
                          color: '#fff',
                          border: '2px solid #fff',
                          paddingX: 2.5,
                          marginTop: 3.5,
                        }}
                      >
                        {buttonTitle}
                      </Button>
                    )}
                  </Box>
                </Container>
              </Card>
            </SwiperSlide>
          );
        })}
      </Slider>
    </Container>
  );
};

export default MainBanner;
