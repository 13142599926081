import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatCurrency } from '../constants/utils';
import { splitString } from './splitString';

// define a generatePDF function that accepts a products argument
const generatePDF = (order) => {
  const {
    reference,
    expeditionType,
    deliveryDate,
    date,
    taxes,
    subtotal,
    total,
    items,
    billingAddress,
    shippingPrice,
    totalDiscount,
  } = order;

  let totalTaxes = 0;
  const pageBeginning = 15;
  const pageEnding = 195;
  const pageMiddle = 115;

  let billingAddressStreetIsSplitted = false;
  let billingAddressStreet = billingAddress?.street;
  const billingAddressStreetLength = billingAddressStreet.length;

  if (billingAddressStreetLength >= 40) {
    billingAddressStreetIsSplitted = true;
    billingAddressStreet = splitString(billingAddressStreet, 40);
  }

  const calculateSavedPrice = (price, quantity, discount) =>
    parseFloat(Math.fround(price * quantity * (discount / 100)).toFixed(2));

  // initialize jsPDF
  const doc = new jsPDF();

  // define the columns we want and their titles
  const tableColumn = [
    'Designação',
    'Quantidade',
    'Preço unitário',
    'Desconto',
  ];
  // define an empty array of rows
  const tableRows = [];

  // for each product pass all its data into an array
  items.map((product) => {
    const productHasDiscount = product?.discount !== 0;

    const quantity = product?.quantity?.unit + product?.quantity?.box;

    const discountCalc =
      productHasDiscount &&
      calculateSavedPrice(product?.box_price, quantity, product?.discount);

    const discountValue = productHasDiscount
      ? `${formatCurrency(discountCalc)} (${product?.discount}%)`
      : formatCurrency(product?.discount);

    const productData = [
      product?.title,
      quantity,
      formatCurrency(product?.box_price),
      discountValue,
    ];

    totalTaxes = taxes.reduce((acc, tax) => acc + tax.total, 0);

    return tableRows.push(productData);
  });

  //  GLOBAL STYLES
  doc.setFontSize(10);
  doc.setFont(undefined, 'bold');
  doc.text('Frescas Surpresas', pageBeginning, 15);
  doc.setFont(undefined, 'normal');
  doc.text('Av. de Trezeste Armazem 04 Entreposto 02', pageBeginning, 21);
  doc.text('4709-001 Braga', pageBeginning, 27);
  doc.text('NIF: 508565103', pageBeginning, 33);

  doc.setFont(undefined, 'bold');
  doc.text(billingAddress.name, pageMiddle, 15);
  doc.setFont(undefined, 'normal');
  doc.text(`${billingAddressStreet}`, pageMiddle, 21);
  doc.text(
    `${billingAddress.zip_code} ${billingAddress.city}`,
    pageMiddle,
    billingAddressStreetIsSplitted ? 31 : 27
  );
  doc.text(
    billingAddress.country,
    pageMiddle,
    billingAddressStreetIsSplitted ? 37 : 33
  );

  doc.setFont(undefined, 'bold');
  doc.text(`Encomenda nº: ${reference}`, pageBeginning, 56);
  doc.setFont(undefined, 'normal');
  doc.line(pageBeginning, 58, pageEnding, 58);
  doc.setFontSize(9);
  doc.text(`Data: ${date}`, pageBeginning, 63);
  doc.text(`Data entrega: ${deliveryDate}`, 65, 63);
  doc.text(`Método de envio: ${expeditionType}`, 125, 63);
  doc.setFontSize(10);

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    theme: 'plain',
    startY: 72,
    styles: { fontSize: 9 },
    headStyles: { fillColor: '#f5f5f8' },
  });

  doc.autoTable({
    body: [
      ['Subtotal', formatCurrency(subtotal)],
      ['Envio', formatCurrency(shippingPrice)],
      ['Desconto', formatCurrency(totalDiscount)],
      ['Total de I.V.A.', formatCurrency(totalTaxes)],
      [
        { content: 'TOTAL', styles: { fontStyle: 'bold' } },
        { content: formatCurrency(total), styles: { fontStyle: 'bold' } },
      ],
    ],
    theme: 'plain',
    styles: { fillColor: '#f5f5f8' },
    margin: { left: pageMiddle },
    startY: doc.autoTableEndPosY() + 50,
    tableWidth: pageEnding - pageMiddle,
    padding: { top: 2, right: 4, bottom: 2, left: 4 },
  });

  // we define the name of our PDF file.
  doc.save(`encomenda_${date}_${Math.floor(Date.now() / 1000)}.pdf`);
  // doc.output('dataurlnewwindow'); //to check pdf generate
};

export default generatePDF;
