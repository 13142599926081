import { createContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from '../helpers';
import { SETTINGS } from '../variables';

export const ProductsListContext = createContext();

export const ProductsListProvider = ({ children }) => {
  const { collectionSlug, categorySlug, subcategorySlug } = useParams();
  const [searchParams] = useSearchParams();
  const pageSearchParam = searchParams.get('page');
  const [layout, setLayout] = useLocalStorage('layout', 'grid');

  const [products, setProducts] = useState({
    list: [],
    total: null,
  });
  const [filters, setFilters] = useState({
    list: [],
    active: {},
  });
  const [pagination, setPagination] = useState({
    itemsPerPage: SETTINGS.defaults.productList.itemsPerPage,
    current: Number(pageSearchParam) || 1,
    total: 0,
    onChange: (e, newPage) => {
      //  set new pagination number
      setPagination((prevState) => ({
        ...prevState,
        current: newPage,
      }));
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [getProductsTrigger, setGetProductsTrigger] = useState(false);

  useEffect(() => {
    //  update 'search params' values to array
    const searchParamsEntries = Object.values([
      ...searchParams,
      ['limit', String(pagination.itemsPerPage)],
    ]).reduce((acc, [key, value]) => ({ ...acc, [key]: value.split(',') }), {});

    //  merge 'search params' & 'product categories slugs'
    //  remove entrie if it has no 'value'
    //  IF 'key' exists » concat new 'value'
    const productParamsMerged = Object.entries({
      collection: collectionSlug,
      categories: categorySlug,
      subcategory: subcategorySlug,
    }).reduce(
      (acc, [key, value]) =>
        value
          ? {
              ...acc,
              [key]: acc?.[key] ? acc?.[key]?.concat(value) : value.split(','),
            }
          : { ...acc },
      { ...searchParamsEntries }
    );

    setFilters((prevState) => ({
      ...prevState,
      active: productParamsMerged,
    }));

    if (pagination.current !== Number(pageSearchParam)) {
      setPagination((prevState) => ({
        ...prevState,
        current: !!pageSearchParam ? Number(pageSearchParam) : 1,
      }));
    }

    setGetProductsTrigger(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    collectionSlug,
    categorySlug,
    subcategorySlug,
    searchParams,
    pagination.itemsPerPage,
  ]);

  return (
    <ProductsListContext.Provider
      value={{
        products,
        setProducts,
        filters,
        setFilters,
        pagination,
        setPagination,
        isLoading,
        setIsLoading,
        layout,
        setLayout,
        getProductsTrigger,
        setGetProductsTrigger,
      }}
    >
      {children}
    </ProductsListContext.Provider>
  );
};
