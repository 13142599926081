/*
|--------------------------------
| compare differences between two objects
|--------------------------------
*/
export const compareTwoObjects = (object1, object2) => {
  var diffObj = Array.isArray(object2) ? [] : {};
  Object.getOwnPropertyNames(object2).forEach(function (prop) {
    if (typeof object2[prop] === 'object') {
      diffObj[prop] = compareTwoObjects(object1[prop], object2[prop]);
      // if it's an array with only length property => empty array => delete
      // or if it's an object with no own properties => delete
      if (
        (Array.isArray(diffObj[prop]) && Object.getOwnPropertyNames(diffObj[prop]).length === 1) ||
        Object.getOwnPropertyNames(diffObj[prop]).length === 0
      ) {
        delete diffObj[prop];
      }
    } else if (object1[prop] !== object2[prop]) {
      diffObj[prop] = object2[prop];
    }
  });
  return diffObj;
};
