import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',

  initialState: {
    id: '',
    cart: [],
    taxes: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: '',
    deliveryAtAddress: '',
    deliveryAtStore: '',
    shippingNotes: '',
    campaignProducts: [],
    delayedPayments: false,
  },

  reducers: {
    setCartObject: (state, action) => (state = { ...state, ...action.payload }),

    setCartId: (state, action) => {
      state.id = action.payload;
    },

    // real use case: reset cart after order completion
    setCart: (state, action) => {
      state.cart = action.payload;
    },

    setCartSubtotal: (state, action) => {
      state.subtotal = action.payload;
    },

    setCartTotal: (state, action) => {
      state.total = action.payload;
    },

    setCartTaxes: (state, action) => {
      state.taxes = action.payload;
    },

    setDiscount: (state, action) => {
      state.discount = action.payload;
    },

    updateCart: (state, action) => {
      state.cart = [...action.payload];
    },

    // Removes variant from cart
    removeFromCart: (state, action) => {
      const { reference } = action.payload;

      const cart = state.cart;

      const foundProduct = cart.find(
        ({ reference: newReference }) => newReference === reference
      );

      const newQuantity = 0;

      const newProduct = { ...foundProduct, quantity: newQuantity };

      const updatedCart = cart.map((product) =>
        product.reference === reference ? newProduct : product
      );

      const newCart = updatedCart.filter(({ quantity }) => quantity !== 0);

      state.cart = [...newCart];
    },

    // Removes quantity, or product, from cart
    removeQuantityFromCartItem: (state, action) => {
      state.cart = [...action.payload];
      // const oldCart = state.cart;

      // const products = action.payload;

      // const updatedProducts = oldCart.map((product) => {
      //   const foundProduct = products.find(
      //     ({ reference }) => reference === product.reference
      //   );

      //   const newProduct = { ...product };

      //   const newQuantity = foundProduct?.quantity || 0;

      //   newProduct.quantity -= newQuantity || 0;

      //   return newProduct;
      // });

      // const updatedCart = updatedProducts.filter(
      //   ({ quantity }) => quantity !== 0
      // );

      // state.cart = [...updatedCart];
    },
  },
});

export const {
  setCartObject,
  setCartId,
  setCart,
  setCartSubtotal,
  setCartTotal,
  setCartTaxes,
  setDiscount,
  updateCart,
  removeFromCart,
  removeQuantityFromCartItem,
} = cartSlice.actions;

export default cartSlice.reducer;
