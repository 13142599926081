import { FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { ImFacebook } from 'react-icons/im';

const API_URL = `${process.env.REACT_APP_API_URL}/store`;
const PLACEHOLDER_IMAGE = `${
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_WEBSITE_URL
    : process.env.PUBLIC_URL
}/default_image.png`;
const NOT_FOUND_ROUTE = 'pagina-nao-encontrada';

//  CUSTOM COLORS
const CUSTOM_THEME_COLORS = {
  primary: '#9B2E35',
  lightPrimary: '#bb3841',
  gray: '#DDDDDD',
  lightGray: '#f7f7f7',
  darkGray: '#383838',
  red: '#9A031E',
  green: '#008148',
  lightGreen: '#4FD290',
  yellow: '#FFC01D',
  orange: '#ff8000',
};

//  FONT STYLES
const FONT_STYLE = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 600,
  black: 700,
};

//  STYLES DEFAULTS
const TRANSITION_DURATION = 200;

// info
const COMPANY_NAME = 'Frescas Surpresas';
const COMPANY_INFO = {
  name: 'Frescas Surpresas',
  address: {
    street: 'Av. de Trezeste Armazem 04 Entreposto 02',
    zipCode: '4709-001 Celeirós',
    city: 'Braga',
  },
  phone: {
    label: '(+351) 253 286 524',
    value: '+351253286524',
  },
  mobile: {
    label: '(+351) 915 904 189',
    value: '+351915904189',
  },
  fax: {
    label: '',
    value: '',
  },
  email: 'geral@frescassurpresas.com',
  mapsLink: 'https://maps.app.goo.gl/VvrD23whE1ftoDd89',
  socialMedia: [
    {
      title: 'Facebook',
      url: 'https://www.facebook.com/frescassurpresas',
      icon: <ImFacebook size={18} />,
    },
    {
      title: 'Linkedin',
      url: 'https://www.linkedin.com/company/frescassurpresas',
      icon: <FaLinkedinIn size={20} />,
    },
    {
      title: 'Instagram',
      url: 'https://www.instagram.com/frescassurpresas',
      icon: <FaInstagram size={20} />,
    },
  ],
};

const SETTINGS = {
  functionalities: {
    myAccount: true,
    shoppingList: true,
    hidePrices: true,
  },
  defaults: {
    quantityType: 'box',
    productList: {
      sortBy: 'name:asc',
      itemsPerPage: 24,
    },
  },
};

// login
const LOGIN_FORM_TITLE = 'Clientes Profissionais';

const LOGIN_PASS_RECOVERY_TEXT = 'Esqueceu-se da palavra-passe?';

const LOGIN_REGISTER_TEXT = 'Ainda não tem conta de cliente?';

const PASS_RECOVERY_TITLE = 'Recuperar Palavra-Passe';

const PASS_RECOVERY_TEXT =
  'Após recuperar a palavra-passe irá receber uma nova no seu email.';

const REGISTER_SUCCESS_TEXT =
  'O seu registo foi efetuado com sucesso. Receberá um email com a aprovação da conta.';

const PRODUCT_INFO_TABS = ['Detalhes Técnicos', 'Vídeo'];

// breakpoints
const SMALL_DEVICES_BREAKPOINT = '600px';
const MEDIUM_DEVICES_BREAKPOINT = '900px';
const LARGE_DEVICES_BREAKPOINT = '1200px';
const XLARGE_DEVICES_BREAKPOINT = '1536px';

//  PAGES SLUGS
const PAGES_SLUGS = {
  search: '/pesquisa',
  allProducts: '/produtos',
  budgetRequest: '/pedido-orcamento',
  news: '/novidades',
  campaigns: '/campanhas',
  brands: '/marcas',
  contacts: '/contactos',
  myProducts: '/meus-produtos',
  cart: '/carrinho',
  account: '/conta',
  login: '/login',
  productDetail: '/produto',
  recoverPassword: 'recuperar-palavra-passe',
  createUser: 'registo-novo-cliente',
  createFastUser: 'registo-nova-conta',
  institutional: {
    aboutUs: 'quem-somos',
    termsAndConditions: 'termos-e-condicoes',
    privacyPolicy: 'politica-de-privacidade',
    alternativeDisputeResolution: 'resolucao-alternativa-de-litigios',
    faqs: 'faqs',
    businessAreas: 'areas-de-negocio',
    distribution: 'distribuicao',
  },
  myAccount: {
    info: `dados-pessoais`,
    accessData: 'dados-acesso',
    receipts: 'conta-corrente',
    shoppingList: 'lista-compras',
    orders: 'encomendas',
    productsHistory: 'visualizacoes',
    users: 'utilizadores',
    addresses: 'moradas',
  },
};

const ACCOUNT_LINKS = {
  customer: [
    {
      title: 'Dados Pessoais',
      url: PAGES_SLUGS.myAccount.info,
      userMenu: true,
    },
    {
      title: 'Dados de Acesso',
      url: PAGES_SLUGS.myAccount.accessData,
      userMenu: false,
    },
    {
      title: 'Moradas',
      url: PAGES_SLUGS.myAccount.addresses,
      userMenu: true,
    },
    {
      title: 'Encomendas',
      url: PAGES_SLUGS.myAccount.orders,
      userMenu: true,
    },
    {
      title: 'Conta Corrente',
      url: PAGES_SLUGS.myAccount.receipts,
      userMenu: true,
    },
    {
      title: 'Listas de Compras',
      url: PAGES_SLUGS.myAccount.shoppingList,
      userMenu: false,
    },
    {
      title: 'Visualizações',
      url: PAGES_SLUGS.myAccount.productsHistory,
      userMenu: false,
    },
  ],
  seller: [
    {
      title: 'Dados Pessoais',
      url: PAGES_SLUGS.myAccount.info,
      userMenu: true,
    },
    {
      title: 'Dados de Acesso',
      url: PAGES_SLUGS.myAccount.accessData,
      userMenu: true,
    },
    {
      title: 'Encomendas',
      url: PAGES_SLUGS.myAccount.orders,
      userMenu: true,
    },
    {
      title: 'Conta Corrente',
      url: PAGES_SLUGS.myAccount.receipts,
      userMenu: true,
    },
  ],
};

// navbar
const NAVBAR_LINKS = [
  {
    title: 'Produtos',
    url: PAGES_SLUGS.allProducts,
    highlighted: false,
    categoryButton: true,
    menuMobile: false,
  },
  {
    title: 'Novidades',
    url: PAGES_SLUGS.news,
    highlighted: false,
    categoryButton: false,
    menuMobile: true,
  },
  {
    title: 'Campanhas',
    url: PAGES_SLUGS.campaigns,
    highlighted: false,
    categoryButton: false,
    menuMobile: true,
  },
  {
    title: 'Os meus produtos',
    url: PAGES_SLUGS.myProducts,
    highlighted: false,
    categoryButton: false,
    menuMobile: true,
  },
  {
    title: 'Contactos',
    url: PAGES_SLUGS.contacts,
    highlighted: false,
    categoryButton: false,
    menuMobile: true,
  },
];

// FOOTER MENUS
const FOOTER_MENUS = {
  menu1: [
    {
      title: 'Quem Somos',
      url: PAGES_SLUGS.institutional.aboutUs,
      internalLink: true,
      target: '',
    },
    {
      title: 'Áreas de Negócio',
      url: PAGES_SLUGS.institutional.businessAreas,
      internalLink: true,
      target: '',
    },
    {
      title: 'Distribuição',
      url: PAGES_SLUGS.institutional.distribution,
      internalLink: true,
      target: '',
    },
    {
      title: 'Contactos',
      url: PAGES_SLUGS.contacts,
      internalLink: true,
      target: '',
    },
  ],
  menu2: [
    {
      title: 'Termos e Condições',
      url: PAGES_SLUGS.institutional.termsAndConditions,
      internalLink: true,
      target: '',
    },
    {
      title: 'Política de Privacidade',
      url: PAGES_SLUGS.institutional.privacyPolicy,
      internalLink: true,
      target: '',
    },
    {
      title: 'Livro de Reclamações',
      url: 'https://www.livroreclamacoes.pt/Inicio/',
      internalLink: false,
      target: '_blank',
    },
  ],
};

const DELIVERY_METHODS = {
  // STORE: 'deliveryAtStore',
  ADDRESS: 'deliveryAtAddress',
};

export {
  ACCOUNT_LINKS,
  API_URL,
  COMPANY_INFO,
  COMPANY_NAME,
  CUSTOM_THEME_COLORS,
  DELIVERY_METHODS,
  FONT_STYLE,
  FOOTER_MENUS,
  LARGE_DEVICES_BREAKPOINT,
  LOGIN_FORM_TITLE,
  LOGIN_PASS_RECOVERY_TEXT,
  LOGIN_REGISTER_TEXT,
  MEDIUM_DEVICES_BREAKPOINT,
  NAVBAR_LINKS,
  NOT_FOUND_ROUTE,
  PAGES_SLUGS,
  PASS_RECOVERY_TEXT,
  PASS_RECOVERY_TITLE,
  PLACEHOLDER_IMAGE,
  PRODUCT_INFO_TABS,
  REGISTER_SUCCESS_TEXT,
  SETTINGS,
  SMALL_DEVICES_BREAKPOINT,
  TRANSITION_DURATION,
  XLARGE_DEVICES_BREAKPOINT,
};
