import { Typography } from '@mui/material';
import React from 'react';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../variables';

const ProductStock = ({ stock, hiddenLabel }) => {
  const lastUnits = stock > 0 && stock <= 3;
  const outOfStock = stock <= 0;

  if (lastUnits) {
    return (
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          fontSize: '0.85rem',
          fontWeight: FONT_STYLE.medium,
          color: CUSTOM_THEME_COLORS.yellow,
          '&::before': {
            content: '""',
            width: '8px',
            height: '8px',
            backgroundColor: CUSTOM_THEME_COLORS.yellow,
            borderRadius: '100%',
          },
        }}
      >
        {!hiddenLabel && 'Stock Reduzido'}
      </Typography>
    );
  }

  if (outOfStock) {
    return (
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          fontSize: '0.85rem',
          fontWeight: FONT_STYLE.medium,
          color: CUSTOM_THEME_COLORS.orange,
          '&::before': {
            content: '""',
            width: '8px',
            height: '8px',
            backgroundColor: CUSTOM_THEME_COLORS.orange,
            borderRadius: '100%',
          },
        }}
      >
        {!hiddenLabel && 'Disponível em 12 dias'}
      </Typography>
    );
  }

  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        fontSize: '0.85rem',
        fontWeight: FONT_STYLE.medium,
        color: CUSTOM_THEME_COLORS.green,
        '&::before': {
          content: '""',
          width: '8px',
          height: '8px',
          backgroundColor: CUSTOM_THEME_COLORS.green,
          borderRadius: '100%',
        },
      }}
    >
      {!hiddenLabel && 'Disponível'}
    </Typography>
  );
};

export default ProductStock;
