import { capitalize, kebabCase } from 'lodash';
import { PAGES_SLUGS, PLACEHOLDER_IMAGE } from '../variables';

const generatePath = (categoriesArray) => {
  return categoriesArray.reduce((acc, item, index, originalArray) => {
    const slug = originalArray
      .slice(0, index + 1)
      .map((slugItem) => kebabCase(slugItem))
      .join('/');
    acc.push({
      name: capitalize(item),
      slug: `${PAGES_SLUGS.allProducts}/${slug}`,
    });
    return acc;
  }, []);
};

function mapProduct(product) {
  // const randomPrice = generateRandomPrice();

  //  update interface of 'related'
  const hasRelated = Boolean(
    product?.related !== undefined && product?.related.length !== 0
  );

  //  update interface of 'variants'
  const hasVariants = Boolean(
    product?.variants && product?.variants.length !== 0
  );

  return {
    id: product?._id || product?.id,
    title: capitalize(product?.title),
    slug: product?.slug,
    path: generatePath([
      product?.collection,
      product?.category,
      product?.productType,
    ]),
    brand: product?.brand,
    description: product?.description,
    reference: product?.reference,
    images: product?.images?.length ? product?.images : [PLACEHOLDER_IMAGE],
    discount: product?.discount,
    discountPrice: product?.discountPrice,
    saleAmount: product?.saleAmount || 0,
    //  unit
    sellsUnit: product?.sellsUnit || false,
    onSale: product?.onSale || false,
    price: product?.price || 0,
    salePrice: product?.salePrice || 0,
    stock: product?.stock || 0,
    //  box
    sellsBox: product?.sellsBox || false,
    itemsPerBox: product?.itemsPerBox || 0,
    onSaleBox: product?.onSaleBox || false,
    boxPrice: product?.boxPrice || 0,
    boxSalePrice: product?.boxSalePrice || 0,
    stockPerBox: product?.stockPerBox || 0,
    quantity: {
      unit: product?.quantity?.unit || 0,
      box: product?.quantity?.box || 0,
    },
    video: product?.video,
    datasheet: product?.datasheet,
    params: product?.params,
    date: product?.erp_update_date || product?.date,
    badge: {
      new: product?.badge?.new || false,
      campaign: product?.badge?.campaign || false,
    },
    isVariant: product?.isVariant || false,
    related: hasRelated
      ? product?.related.map((related) => mapProduct(related))
      : [],
    variationField: product?.variationField,
    variants: hasVariants
      ? product?.variants?.map((variant) =>
          mapProduct({
            ...variant,
            id: product?._id || product?.id,
            slug: product?.slug,
            variationField: product?.variationField,
          })
        )
      : [],
  };
}

function mapCategory(collection) {
  return {
    ...collection,
    id: collection._id,
    title: capitalize(collection.title.toString()),
    url: `${PAGES_SLUGS.allProducts}/${collection.url}`,
    subcategories: collection.subcategories.map((category) => ({
      ...category,
      id: category._id,
      title: capitalize(category.title.toString()),
      url: `${PAGES_SLUGS.allProducts}/${collection.url}/${category.url}`,
      productTypes: category.productTypes.map((productTypes) => ({
        ...productTypes,
        id: productTypes._id,
        title: capitalize(productTypes.title.toString()),
        url: `${PAGES_SLUGS.allProducts}/${collection.url}/${category.url}/${productTypes.url}`,
      })),
    })),
  };
}

function mapFilters(filters) {
  return Object.entries(filters).map(([key, value]) => {
    const { title, values } = value;
    return {
      id: key,
      title: capitalize(title),
      options: values.map((value) => capitalize(value)),
    };
  });
}

export { generatePath, mapCategory, mapFilters, mapProduct };
