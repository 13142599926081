import { Box, Button, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { FiShoppingCart } from 'react-icons/fi';
import { imageOnError } from '../../helpers';
import { IconButton } from '../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE, SETTINGS } from '../../variables';
import { useSelector } from 'react-redux';

const CampaignsCard = ({
  campaign,
  addToCart,
  setIsModalOpen,
  setModalContent,
}) => {
  const theme = useTheme();

  const cartArray = useSelector((state) => state.cart.cart);

  //  GET product quantity IF it already exists inside cart
  const saleProductExistsInsideCart = cartArray.find(
    (product) => product.id === campaign.productSale.id
  )?.quantity?.[SETTINGS.defaults.quantityType];

  const startDate = new Date(campaign.startDate).toLocaleDateString('pt-PT');
  const endDate = new Date(campaign.endDate).toLocaleDateString('pt-PT');
  const productsToCart = [
    {
      ...campaign.productSale,
      quantity: {
        ...campaign.productSale.quantity,
        [SETTINGS.defaults.quantityType]: !!saleProductExistsInsideCart
          ? campaign.qttSale + saleProductExistsInsideCart
          : campaign.qttSale,
      },
    },
    {
      ...campaign.productOffer,
      discount: 100,
      discountPrice: 0,
      quantity: {
        ...campaign.productSale.quantity,
        [SETTINGS.defaults.quantityType]: campaign.qttOffer,
      },
    },
  ];

  return (
    <Grid
      xs={12}
      sm={6}
      md={4}
    >
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          marginY: { xs: 1, md: 2 },
        }}
      >
        <Box
          component='img'
          src={campaign.productSale.images?.[0]}
          alt={campaign.productSale.title}
          onError={(e) => {
            imageOnError(e);
          }}
          sx={{
            aspectRatio: '2/1',
            objectFit: 'cover',
            maxWidth: '100%',
            border: `1px solid ${CUSTOM_THEME_COLORS.gray}`,
            borderRadius: theme.shape.borderRadius / 10,
          }}
        />
        <Box
          sx={{
            display: 'grid',
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: '1.15rem',
              fontWeight: FONT_STYLE.bold,
            }}
          >
            {campaign.title}
          </Typography>
          {!!campaign.description && (
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                fontSize: '0.9rem',
              }}
            >
              {campaign.description}
            </Typography>
          )}
          <Typography
            sx={{
              fontSize: '0.9rem',
              color: CUSTOM_THEME_COLORS.primary,
            }}
          >
            {`Promoção válida de ${startDate} a ${endDate}`}
          </Typography>
          <Typography fontSize='0.8rem'>* Campanha não acumulável</Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              marginTop: 1,
            }}
          >
            <Button
              variant='contained'
              color='secondary'
              size='small'
              title='Ver detalhes'
              onClick={() => {
                return (
                  setModalContent({
                    ...campaign,
                    startDate: startDate,
                    endDate: endDate,
                  }),
                  setIsModalOpen(true)
                );
              }}
              sx={{
                paddingX: 2,
              }}
            >
              Ver detalhes
            </Button>
            <IconButton
              title='Adicionar'
              variant='contained'
              size='small'
              onClick={(e) => {
                e.preventDefault();
                return addToCart(campaign, productsToCart);
              }}
              sx={{
                paddingX: 2,
                gap: 1,
              }}
            >
              <FiShoppingCart size={16} />
              Adicionar
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default CampaignsCard;
