import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { kebabCase } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getProducts } from '../api/products';
import Breadcrumbs from '../components/Breadcrumbs';
import ProductsList from '../components/ProductsList/ProductsList';
import ProductsListFilters from '../components/ProductsList/ProductsListFilters';
import ProductsListTitle from '../components/ProductsList/ProductsListTitle';
import ProductsListToolbar from '../components/ProductsList/ProductsListToolbar';
import { ProductsListContext } from '../contexts/ProductsListContext';
import { MainContainer } from '../styles';
import { NOT_FOUND_ROUTE } from '../variables';

//	get page title » productTypes/subcategories/category
const getCategoryTitle = (
  filters,
  collectionSlug,
  categorySlug,
  subcategorySlug
) => {
  return filters
    .find(
      (filter) =>
        filter.id ===
        (subcategorySlug
          ? 'productTypes'
          : categorySlug
          ? 'categories'
          : 'collection')
    )
    ?.options?.find(
      (option) =>
        kebabCase(subcategorySlug || categorySlug || collectionSlug) ===
        kebabCase(option)
    );
};

const ProductsPage = () => {
  const {
    products,
    setProducts,
    filters,
    setFilters,
    pagination,
    setPagination,
    setIsLoading,
    getProductsTrigger,
    setGetProductsTrigger,
  } = useContext(ProductsListContext);

  const theme = useTheme();
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));

  const categoriesTree = useSelector((state) => state.categories.categories);

  const navigate = useNavigate();
  const { collectionSlug, categorySlug, subcategorySlug } = useParams();

  const [categoryTitle, setCategoryTitle] = useState('');
  const [breadcrumbsPath, setBreadcrumbsPath] = useState([]);

  const generateBreadcrumbs = () => {
    const categoriesSlugParams = [
      collectionSlug,
      categorySlug,
      subcategorySlug,
    ].filter((param) => param);

    return categoriesSlugParams
      .reduce((acc, item, index) => {
        //  COLLECTION
        if (index === 0) {
          acc.push(
            categoriesTree.find(
              (cat) => kebabCase(cat.title) === kebabCase(item)
            )
          );
        }
        //  CATEGORY
        if (index === 1) {
          acc.push(
            acc[0].subcategories.find(
              (cat) => kebabCase(cat.title) === kebabCase(item)
            )
          );
        }
        //  SUBCATEGORY
        if (index === 2) {
          acc.push(
            acc[1].productTypes.find(
              (cat) => kebabCase(cat.title) === kebabCase(item)
            )
          );
        }
        return acc;
      }, [])
      .map((categoryEntry) => ({
        name: categoryEntry?.title,
        slug: categoryEntry?.url,
      }));
  };

  useEffect(() => {
    if (!getProductsTrigger) return;
    setIsLoading(true);

    getProducts({
      searchParams: filters.active,
    })
      .then(({ displayedProducts, displayedFilters, productsTotal }) => {
        //  set page category title
        const pageTitle =
          //  IF this categories does not exist 'collectionSlug' & 'categorySlug' & 'subcategorySlug'
          !collectionSlug && !categorySlug && !subcategorySlug
            ? 'Produtos'
            : getCategoryTitle(
                displayedFilters,
                collectionSlug,
                categorySlug,
                subcategorySlug
              );
        setCategoryTitle(pageTitle);

        //  get categories hierarchy through filters
        setBreadcrumbsPath(generateBreadcrumbs());

        //  set products & products total
        setProducts({
          list: displayedProducts,
          total: productsTotal,
        });

        //  remove filters without options » sidebar filters
        //	remove 'collection' » sidebar filters
        //	IF 'categories' is present at url remove it » sidebar filters
        const filtersFiltered = displayedFilters.filter(
          (filter) =>
            filter.options &&
            filter.options.length !== 0 &&
            filter.id !== (collectionSlug && 'collection') &&
            filter.id !== (categorySlug && 'categories') &&
            filter.id !== (subcategorySlug && 'productTypes')
        );

        //  set filters
        setFilters((prevState) => ({
          ...prevState,
          list: filtersFiltered,
        }));

        //  rounds a number UP to the nearest integer
        setPagination((prevState) => ({
          ...prevState,
          total: Math.ceil(productsTotal / pagination.itemsPerPage),
        }));

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
        if (status === 404) navigate(`/${NOT_FOUND_ROUTE}`);
        setIsLoading(false);
      });

    setGetProductsTrigger(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProductsTrigger]);

  return (
    <MainContainer
      sx={{
        paddingTop: { xs: 3 },
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box marginBottom={1}>
              <Breadcrumbs pathArray={breadcrumbsPath} />
            </Box>
          </Grid>
          <Grid
            container
            xs={12}
          >
            {!MOBILE_DEVICES && (
              <Grid md={3}>
                {!!filters.list.length && <ProductsListFilters />}
              </Grid>
            )}
            <Grid
              md={8.5}
              mdOffset={0.5}
            >
              <Box sx={{ flex: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginBottom: { md: 3 },
                  }}
                >
                  <ProductsListTitle
                    title={categoryTitle}
                    productsTotal={products.total}
                  />
                  <ProductsListToolbar />
                </Box>
                <ProductsList />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ProductsPage;
