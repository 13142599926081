import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { toNumber } from 'lodash';
import React, { useState } from 'react';
import { formatCurrency } from '../../../constants/utils';
import QuantitySelector from '../../Quantity/QuantitySelector';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../../variables';

const DiscountSelectorModal = ({
  isApplyDiscountOpen,
  setIsApplyDiscountOpen,
  updateCartItemDiscount,
  product,
}) => {
  const {
    onSale,
    salePrice,
    price,
    onSaleBox,
    boxSalePrice,
    boxPrice,
    discount: productDiscount,
    quantity,
  } = product;

  const [discount, setDiscount] = useState(productDiscount ?? 0);

  const productOnSale = onSale || onSaleBox;
  const productPrice = quantity.unit ? price : boxPrice;
  const productSalePrice = quantity.unit ? salePrice : boxSalePrice;
  const pricePerUnit = quantity.unit //  unit price
    ? onSale
      ? salePrice
      : price
    : onSaleBox //  box price
    ? boxSalePrice
    : boxPrice;
  const priceWithDiscount = parseFloat(
    Math.fround(pricePerUnit - (pricePerUnit * discount) / 100).toFixed(2)
  );

  const decrement = () =>
    discount >= 0 && setDiscount((prevState) => (prevState -= 1));

  const increment = () =>
    discount <= 100 && setDiscount((prevState) => (prevState += 1));

  const openFieldChange = (e) => {
    //  IF number of caracteres is greater than 3 caracteres » return
    if (e.length > 3) return;
    setDiscount(toNumber(e));
  };

  return (
    <Dialog
      open={isApplyDiscountOpen}
      onClose={() => setIsApplyDiscountOpen(false)}
      PaperProps={{
        sx: {
          maxWidth: { xs: '100%', md: '600px' },
          margin: { xs: 2, md: 4 },
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: FONT_STYLE.bold, paddingY: 3 }}>
        Adicionar desconto
      </DialogTitle>

      <DialogContent sx={{ paddingBottom: 3 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Preço</TableCell>
                <TableCell>Preço c/ desconto</TableCell>
                <TableCell>Desconto (%)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        textDecoration: productOnSale && 'line-through',
                      }}
                    >
                      {formatCurrency(productPrice)}
                    </Typography>

                    {productOnSale && (
                      <>
                        <Typography sx={{ fontWeight: FONT_STYLE.medium }}>
                          {formatCurrency(productSalePrice)}
                        </Typography>
                      </>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontWeight: FONT_STYLE.medium,
                      color: CUSTOM_THEME_COLORS.red,
                    }}
                  >
                    {formatCurrency(priceWithDiscount)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <QuantitySelector
                    quantity={discount}
                    increment={increment}
                    decrement={decrement}
                    incrementDisabled={discount >= 100}
                    decrementDisabled={discount <= 0}
                    openFieldChange={openFieldChange}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 2,
            marginTop: 3,
          }}
        >
          <Button
            title='Cancelar'
            variant='contained'
            color='secondary'
            onClick={() => setIsApplyDiscountOpen(false)}
          >
            Cancelar
          </Button>

          <Button
            title='Aplicar desconto'
            variant='contained'
            onClick={() => {
              setIsApplyDiscountOpen(false);
              updateCartItemDiscount(product, discount, priceWithDiscount);
            }}
          >
            Aplicar desconto
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DiscountSelectorModal;
