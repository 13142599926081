import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { formatCurrency } from '../../../constants/utils';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../../variables';

const ProductVariantsDetails = ({ productsForCart, setProductsForCart }) => {
  useEffect(() => {
    //  calculate total units
    const totalUnits = productsForCart.products.reduce(
      (acc, product) => acc + product.quantity.unit + product.quantity.box,
      0
    );

    //  calculate total cost
    const total = productsForCart.products.reduce(
      (acc, product) =>
        acc +
        //  IF product 'onSale'
        (product.quantity.unit
          ? product.onSale
            ? product.salePrice
            : product.price
          : // ELSE
          product.onSaleBox
          ? product.boxSalePrice
          : product.boxPrice) *
          (product.quantity.unit + product.quantity.box),
      0
    );

    setProductsForCart((prevState) => ({
      ...prevState,
      totalUnits: totalUnits,
      total: total,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsForCart.products]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
        }}
      >
        <Typography variant='xsmall'>Total embalagens</Typography>
        <Typography
          component='span'
          sx={{
            fontSize: '1.4rem',
            fontWeight: FONT_STYLE.black,
            color: CUSTOM_THEME_COLORS.primary,
          }}
        >
          {productsForCart.totalUnits}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
        }}
      >
        <Typography variant='xsmall'>Total</Typography>
        <Typography
          component='span'
          sx={{
            fontSize: '1.5rem',
            fontWeight: FONT_STYLE.black,
            color: CUSTOM_THEME_COLORS.primary,
          }}
        >
          {formatCurrency(productsForCart.total)}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(ProductVariantsDetails);
