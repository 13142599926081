import { Box, Dialog, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteShoppingList } from '../../../api/user';
import { setShoppingLists } from '../../../state/shoppingListsSlice';
import { StyledTextButton } from '../../../styles';
import PopupNotification from '../../PopupNotification/PopupNotification';
import { FONT_STYLE } from '../../../variables';

const DeleteShoppingListModal = ({
  isOpen,
  setIsOpen,
  setIndex,
  shoppingLists,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: 'Lista de compras apagada',
    errorMessage: '',
  });

  const [selectShoppingList, setSelectShoppingList] = useState(
    shoppingLists?.[0]?._id
  );

  useEffect(() => {
    setSelectShoppingList(shoppingLists?.[0]?._id);
  }, [shoppingLists]);

  function handleShoppingListDelete() {
    deleteShoppingList(selectShoppingList)
      .then((lists) => {
        dispatch(setShoppingLists(lists));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));
          }
        }
      );

    setIsOpen(false);

    setIndex(0);
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '400px',
            padding: 3,
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: FONT_STYLE.bold,
            textTransform: 'uppercase',
            marginBottom: 2,
          }}
        >
          Remover lista de compras
        </Typography>

        <Select
          fullWidth
          value={selectShoppingList}
          onChange={(e) => setSelectShoppingList(e.target.value)}
        >
          {shoppingLists.map(({ name, _id }) => (
            <MenuItem
              key={_id}
              value={_id}
            >
              {name}
            </MenuItem>
          ))}
        </Select>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            marginTop: 3,
          }}
        >
          <StyledTextButton
            variant='contained'
            title='Cancelar'
            color='secondary'
            onClick={() => setIsOpen(false)}
          >
            Cancelar
          </StyledTextButton>

          <StyledTextButton
            variant='contained'
            title='Remover'
            onClick={handleShoppingListDelete}
          >
            Remover
          </StyledTextButton>
        </Box>
      </Dialog>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default DeleteShoppingListModal;
