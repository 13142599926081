import {
  Autocomplete,
  Box,
  Container,
  Divider,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCart, getShoppingLists, setCustomer } from '../../api/user';
import {
  setCart,
  setCartId,
  setCartObject,
  setCartSubtotal,
  setCartTaxes,
  setCartTotal,
  setDiscount,
} from '../../state/cartSlice';
import { setShoppingLists } from '../../state/shoppingListsSlice';
import { setSelectedClient } from '../../state/userSlice';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../variables';

const ClientSelectorSelect = styled(Autocomplete)(() => ({
  [`.MuiInputBase-root`]: {
    fontSize: '0.9em',
  },
  [`.MuiAutocomplete-option`]: {
    fontSize: '0.9em',
  },
}));

const ClientSelector = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const {
    user: { sellerInfo, selectedClient },
  } = state;
  const { clients } = sellerInfo || [];
  const cart_id = selectedClient?.cart_id;
  const selectedClientId = selectedClient?.id;

  const [clientId, setClientId] = useState(selectedClientId);
  const [messageIsVisible, setMessageIsVisible] = useState(false);

  useEffect(() => {
    selectedClientId &&
      selectedClientId !== clientId &&
      setCustomer({ customerId: selectedClientId })
        .then(() => {
          setClientId(selectedClientId);

          //  get cart items
          return getCart(cart_id)
            .then(
              ({
                _id: id,
                cart_items: cart,
                taxes,
                subtotal,
                total,
                total_discount,
                delayedPayments,
              }) => {
                dispatch(setCart(cart));
                dispatch(setCartId(id));
                dispatch(setDiscount(total_discount));
                dispatch(setCartTaxes(taxes));
                dispatch(setCartSubtotal(subtotal));
                dispatch(setCartTotal(total));
                dispatch(
                  setCartObject({
                    delayedPayments: delayedPayments,
                  })
                );

                //  get shipping lists
                return getShoppingLists()
                  .then((lists) => {
                    dispatch(setShoppingLists(lists));

                    //  navigate to 'homepage'
                    navigate('/');
                  })
                  .catch(({ response: { status } }) => {
                    if (status === 401) navigate('/login');
                  });
              }
            )
            .catch(({ response: { status } }) => {
              if (status === 401) navigate('/login');
            });
        })
        .catch(({ response: { status } }) => {
          if (status === 422) setMessageIsVisible(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, cart_id, dispatch]);

  return (
    <>
      <Box
        paddingY={1.5}
        sx={{
          backgroundColor: CUSTOM_THEME_COLORS.lightGray,
        }}
      >
        <Container>
          <Box
            sx={{
              display: 'flex',
              gap: { xs: 0.5, md: 2 },
              alignItems: 'center',
              justifyContent: 'end',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                color: CUSTOM_THEME_COLORS.darkGray,
              }}
            >
              <Typography variant='small'>O seu perfil:</Typography>
              <Typography
                variant='small'
                sx={{ fontWeight: FONT_STYLE.bold }}
              >
                Vendedor
              </Typography>
            </Box>

            {!!clients.length && (
              <>
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ borderColor: CUSTOM_THEME_COLORS.darkGray }}
                />

                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    color: CUSTOM_THEME_COLORS.darkGray,
                  }}
                >
                  <Typography variant='small'>O seu cliente:</Typography>

                  <ClientSelectorSelect
                    disablePortal
                    id='client-selection'
                    options={clients}
                    disableClearable={true}
                    getOptionLabel={(option) => option?.companyInfo?.name}
                    onChange={(event, newValue) =>
                      dispatch(setSelectedClient(newValue?.companyInfo?.name))
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.companyInfo?.name === value?.companyInfo?.name
                    }
                    defaultValue={selectedClient}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={params?.companyInfo?.name}
                        value={params?.companyInfo?.name}
                        sx={{
                          marginTop: 0,
                        }}
                      />
                    )}
                    size='small'
                    sx={{
                      width: 250,
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        </Container>
      </Box>

      <Snackbar
        autoHideDuration={6000}
        message='Houve um problema na alteração do cliente'
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={messageIsVisible}
        onClose={(reason) => {
          if (reason === 'clickaway') return;
          setMessageIsVisible(false);
        }}
        ContentProps={{
          sx: {
            backgroundColor: CUSTOM_THEME_COLORS.red,
          },
        }}
      />
    </>
  );
};

export default ClientSelector;
