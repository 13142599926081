import { Box, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { PAGES_SLUGS } from '../variables';

const Breadcrumbs = ({ pathArray, productDetail }) => {
  const path = [
    {
      name: 'Página inicial',
      slug: '/',
    },
    {
      name: 'Produtos',
      slug: PAGES_SLUGS.allProducts,
    },
    ...pathArray,
  ];

  return (
    <MuiBreadcrumbs aria-label='breadcrumb'>
      {path.map((param, index) => {
        const isLastParam = path.length - 1 === index;
        return (
          <Box
            key={`breadcrumb-item-${param}`}
            component={!isLastParam || productDetail ? Link : 'span'}
            to={!isLastParam || productDetail ? param.slug : null}
            title={param.name}
            sx={{
              fontSize: '0.9em',
              color: 'black',
              textDecoration:
                !isLastParam || productDetail ? 'underline' : 'none',
            }}
          >
            {param.name}
          </Box>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
