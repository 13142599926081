import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { BiDuplicate } from 'react-icons/bi';
import { GrDocumentPdf } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { duplicateOrder } from '../../../api/user';
import { formatCurrency } from '../../../constants/utils';
import generatePDF from '../../../helpers/generatePDF';

const OrdersTable = ({ shownOrders }) => {
  const navigate = useNavigate();
  const showRepeatOrderButton = false;

  return (
    <TableContainer
      component={Paper}
      sx={{ marginY: 2 }}
    >
      <Table
        size='small'
        id='orders-table'
      >
        <TableHead>
          <TableRow>
            <TableCell>Número</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Quantidade</TableCell>
            <TableCell>Total</TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {shownOrders.map((order) => {
            const {
              reference,
              date,
              status,
              total,
              items,
              customer_id,
              _id: orderId,
            } = order;

            const quantity = items.reduce(
              (previousValue, { quantity }) =>
                previousValue + (quantity.unit || quantity.box),
              0
            );

            return (
              <TableRow
                key={reference}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{reference}</TableCell>

                <TableCell sx={{ whiteSpace: 'nowrap' }}>{date}</TableCell>

                <TableCell>{status}</TableCell>

                <TableCell>{quantity}</TableCell>

                <TableCell>{formatCurrency(total)}</TableCell>

                <TableCell>
                  {showRepeatOrderButton && (
                    <IconButton
                      size='small'
                      title='Comprar novamente'
                      onClick={() => {
                        duplicateOrder(customer_id, orderId).then(() =>
                          navigate('/carrinho')
                        );
                      }}
                    >
                      <BiDuplicate />
                    </IconButton>
                  )}

                  <IconButton
                    size='small'
                    title='Exportar em PDF'
                    onClick={() => generatePDF(order)}
                  >
                    <GrDocumentPdf />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrdersTable;
