import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useContext } from 'react';
import { TbShoppingCartPlus } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { ProductCardContext } from '../../contexts/ProductCardContext';
import { imageOnError } from '../../helpers';
import { productDetailsInterface } from '../../helpers/productDetailsInterface';
import { IconButton } from '../../styles';
import {
  CUSTOM_THEME_COLORS,
  FONT_STYLE,
  PAGES_SLUGS,
  PLACEHOLDER_IMAGE,
  TRANSITION_DURATION,
} from '../../variables';
import ProductBadge from '../ProductBadge';
import ProductStock from '../ProductStock';
import ProductPrices from './ProductPrices';

const ProductCard = ({
  product,
  showPrice = true,
  showStockAvailability = true,
}) => {
  const { title, images, badge, variants, reference, slug } = product;

  const theme = useTheme();

  const { addToCart, setProductVariants, setIsVariationsModalOpen } =
    useContext(ProductCardContext);

  const productDetails = productDetailsInterface(product);

  return (
    <Card
      className='product-card'
      sx={{
        minWidth: '130px',
        maxWidth: '300px',
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingY: 2,
        '&:hover': {
          '.productCardTitle': {
            color: CUSTOM_THEME_COLORS.primary,
          },
          '.productCardActions': {
            opacity: 1,
          },
        },
      }}
      component={Link}
      to={`${PAGES_SLUGS.productDetail}/${slug}`}
      title={title}
    >
      <Box
        className='productCardImage'
        sx={{
          position: 'relative',
          transition: `transform ${TRANSITION_DURATION}ms ease`,
          border: `1px solid ${CUSTOM_THEME_COLORS.gray}`,
          borderRadius: theme.shape.borderRadius / 10,
        }}
      >
        {badge && <ProductBadge badge={badge} />}
        <CardMedia
          component='img'
          image={images.length !== 0 ? images?.[0] : PLACEHOLDER_IMAGE}
          onError={imageOnError}
          alt={title}
          sx={{
            maxWidth: '100%',
            objectFit: 'contain',
            aspectRatio: '1',
            borderRadius: theme.shape.borderRadius / 10,
          }}
        />
        <Box
          className='productCardActions'
          sx={{
            width: '100%',
            position: 'absolute',
            left: '0',
            bottom: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            opacity: 0,
            transition: `opacity ${TRANSITION_DURATION}ms ease`,
          }}
        >
          <IconButton
            title='Adicionar ao carrinho'
            variant='contained'
            onClick={(e) => {
              e.preventDefault();
              return productDetails.hasVariants
                ? (setProductVariants(variants), setIsVariationsModalOpen(true))
                : addToCart(product);
            }}
          >
            <TbShoppingCartPlus size={18} />
          </IconButton>
        </Box>
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          padding: `${theme.spacing(1.5)} 0 0 0 !important`,
        }}
      >
        <Box>
          <Typography
            className='productCardTitle'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              transition: `color ${TRANSITION_DURATION}ms ease`,
              fontWeight: FONT_STYLE.bold,
            }}
          >
            {title}
          </Typography>

          {reference && (
            <Typography
              sx={{
                marginTop: 0.5,
                fontSize: '0.85rem',
                color: CUSTOM_THEME_COLORS.darkGray,
              }}
            >{`Ref: ${reference}`}</Typography>
          )}
        </Box>

        <Box
          sx={{
            paddingTop: 1.5,
          }}
        >
          {showPrice && (
            <Box marginBottom={1}>
              <ProductPrices details={productDetails} />
            </Box>
          )}

          {showStockAvailability && (
            <ProductStock stock={productDetails.stock} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
