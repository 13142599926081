import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MainContainer } from '../styles';
import Grid from '@mui/material/Unstable_Grid2';
import { TitleInstitutionalPages } from '../components/InstitucionalPages/InstitucionalPages';

const InstitucionalPage = ({ props }) => {
  const [pageContent, setPageContent] = useState(props);
  const { title, description, image } = pageContent;

  useEffect(() => {
    setPageContent(props);
    return () => {
      setPageContent();
    };
  }, [props]);

  return (
    <MainContainer>
      <Container>
        <Grid container>
          {title && (
            <Grid xs={12}>
              <TitleInstitutionalPages variant='h1'>
                {title}
              </TitleInstitutionalPages>
            </Grid>
          )}
          <Grid container>
            <Grid
              xs={12}
              md={6}
            >
              {image && (
                <Box
                  component='img'
                  src={image}
                  alt={title}
                  sx={{ maxWidth: '100%' }}
                />
              )}
            </Grid>
            <Grid
              xs={12}
              md={5.5}
              mdOffset={0.5}
              sx={{
                marginTop: { xs: 2, md: 0 },
              }}
            >
              {description && (
                <Typography dangerouslySetInnerHTML={{ __html: description }} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default InstitucionalPage;
