import { Box, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { CategoryButton } from '../../../styles';
import { CUSTOM_THEME_COLORS, NAVBAR_LINKS } from '../../../variables';
import CategoriesMenu from './CategoriesMenu/CategoriesMenu';

const NavBar = () => {
  const categories = useSelector((state) => state.categories.categories);

  const [isCategoriesMenuOpen, setIsCategoriesMenuOpen] = useState(false);
  const [categoriesTree] = useState(categories);

  const categoriesMenuRef = useRef(null);
  const navRef = useRef(null);

  return (
    <Box
      ref={navRef}
      sx={{
        position: 'relative',
        backgroundColor: CUSTOM_THEME_COLORS.primary,
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box component='nav'>
              <Box
                display='flex'
                sx={{
                  width: '100%',
                }}
              >
                {NAVBAR_LINKS.map(({ url, title, categoryButton }) => {
                  return (
                    <Box
                      key={`navbar-menu-${title}`}
                      ref={categoriesMenuRef}
                      onMouseEnter={() => {
                        categoryButton && setIsCategoriesMenuOpen(true);
                      }}
                      onMouseLeave={() => {
                        categoryButton && setIsCategoriesMenuOpen(false);
                      }}
                      sx={{
                        position: 'relative',
                        '&:first-of-type': {
                          marginLeft: -2.5,
                        },
                      }}
                    >
                      <CategoryButton
                        component={NavLink}
                        title={title}
                        to={url}
                        sx={{
                          paddingLeft: 2.5,
                          paddingRight: 2.5,
                          '&:hover': {
                            backgroundColor: CUSTOM_THEME_COLORS.lightPrimary,
                          },
                        }}
                      >
                        {title}
                      </CategoryButton>
                      {categoryButton && (
                        <CategoriesMenu
                          setIsCategoriesMenuOpen={setIsCategoriesMenuOpen}
                          isCategoriesMenuOpen={isCategoriesMenuOpen}
                          anchorEl={navRef.current}
                          categoriesTree={categoriesTree}
                        />
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NavBar;
