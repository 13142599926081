import { Box, Button, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { imageOnError } from '../../helpers';
import ThumbnailsCarousel from '../Carousel/ThumbnailsCarousel';
import ImageZoomModal from '../ImageZoomModal';
import { CUSTOM_THEME_COLORS } from '../../variables';
import ProductBadge from '../ProductBadge';

const ProductImages = ({ images, badge }) => {
  const theme = useTheme();
  const [shownImage, setShownImage] = useState(0);
  const [firstImageExists, setFirstImageExists] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState('');

  return (
    <>
      <Box
        sx={{
          maxWidth: { xs: '300px', md: '240px', lg: '300px' },
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
        gap={firstImageExists && images.length > 1 && 2.5}
      >
        <Button
          title='Ver imagem'
          onClick={() => {
            setZoomImagePath(images[shownImage]);
            setModalIsOpen(true);
          }}
          sx={{
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              cursor: 'zoom-in',
            },
          }}
        >
          {badge && <ProductBadge badge={badge} />}
          <Box
            component='img'
            src={images[shownImage]}
            alt='Imagem destacada'
            onError={(e) => {
              imageOnError(e);
              setFirstImageExists(false);
            }}
            sx={{
              aspectRatio: '1',
              objectFit: 'contain',
              maxWidth: '100%',
              border: `1px solid ${CUSTOM_THEME_COLORS.gray}`,
              borderRadius: theme.shape.borderRadius / 10,
            }}
          />
        </Button>

        {firstImageExists && images.length > 1 && (
          <ThumbnailsCarousel
            images={images}
            shownImage={shownImage}
            setShownImage={setShownImage}
          />
        )}
      </Box>
      <ImageZoomModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        imagePath={zoomImagePath}
      />
    </>
  );
};

export default ProductImages;
