import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { ProductsListContext } from '../../contexts/ProductsListContext';
import { CUSTOM_THEME_COLORS } from '../../variables';
import ProductFilter from '../ProductFilter';

const ProductsListFilters = () => {
  const { filters, setPagination } = useContext(ProductsListContext);
  const headerHeight = document.getElementsByTagName('header')[0]?.offsetHeight;
  return (
    <Box
      sx={{
        position: 'sticky',
        top: `${headerHeight + 40}px`,
        height: `calc(100vh - ${headerHeight + 80}px)`,
        overflowY: 'auto',
        // CHROME
        '::-webkit-scrollbar': {
          width: '8px',
        },
        '::-webkit-scrollbar-track': {
          background: CUSTOM_THEME_COLORS.lightGray,
        },
        '::-webkit-scrollbar-thumb': {
          background: CUSTOM_THEME_COLORS.gray,
        },
        // FIREFOX
        scrollbarColor: CUSTOM_THEME_COLORS.gray,
        scrollbarWidth: 'thin',
      }}
    >
      {filters.list.map((filter, index) => (
        <ProductFilter
          key={`products-filter-${filter.id}`}
          index={index}
          filter={filter}
          activeFilters={filters.active}
          setPage={setPagination}
        />
      ))}
    </Box>
  );
};

export default ProductsListFilters;
