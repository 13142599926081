import { Box, Typography, useTheme } from '@mui/material';
import { formatCurrency } from '../../../constants/utils';
import { StyledOrderSummaryBox, StyledOrderSummaryText } from '../../../styles';
import { CUSTOM_THEME_COLORS, FONT_STYLE } from '../../../variables';

const OrderSummaryCard = ({ cart: cartObject, total, deliveryCost }) => {
  const theme = useTheme();

  const { cart, taxes, subtotal, discount } = cartObject;

  const totalUnits = cart.reduce(
    (acc, item) => acc + (item.quantity.unit + item.quantity.box),
    0
  );

  const cartTaxesList = taxes.map((tax) => ({
    text: `IVA (${tax.iva}%)`,
    value: formatCurrency(tax.total),
  }));

  const orderSummary = [
    {
      text: 'Total de unidades',
      value: totalUnits,
    },
    {
      text: 'Total de referências',
      value: cart.length,
    },
    {
      text: 'Subtotal',
      value: formatCurrency(subtotal),
    },
    ...cartTaxesList,
    {
      text: 'Envio',
      value: formatCurrency(deliveryCost),
    },
    {
      text: 'Descontos',
      value: formatCurrency(discount),
    },
  ];
  const headerHeight = document.getElementsByTagName('header')[0]?.offsetHeight;

  return (
    <Box
      sx={{
        position: { md: 'sticky' },
        top: { md: `${headerHeight + 20}px` },
        width: { sm: '60%', md: '30%' },
        height: '100%',
        padding: 2,
        marginBottom: 3,
        border: `1px solid ${CUSTOM_THEME_COLORS.darkGray}`,
        borderRadius: theme.shape.borderRadius / 10,
      }}
    >
      <Typography
        variant='h3'
        sx={{
          textAlign: 'center',
          fontWeight: FONT_STYLE.bold,
          marginBottom: 3,
        }}
      >
        Resumo
      </Typography>

      {orderSummary.map(({ text, value }) => (
        <StyledOrderSummaryBox
          key={text}
          color={text === 'Descontos' && CUSTOM_THEME_COLORS.red}
        >
          <StyledOrderSummaryText>{text}</StyledOrderSummaryText>
          <StyledOrderSummaryText>{value}</StyledOrderSummaryText>
        </StyledOrderSummaryBox>
      ))}

      <StyledOrderSummaryBox
        sx={{ marginTop: 3, marginBottom: 0, alignItems: 'baseline' }}
      >
        <StyledOrderSummaryText
          sx={{ fontSize: '1.2rem', fontWeight: FONT_STYLE.black }}
        >
          Total
        </StyledOrderSummaryText>
        <Typography sx={{ fontSize: '1.2rem', fontWeight: FONT_STYLE.black }}>
          {formatCurrency(total)}
        </Typography>
      </StyledOrderSummaryBox>
    </Box>
  );
};

export default OrderSummaryCard;
