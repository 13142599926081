import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPage } from '../api';
import { mapProduct } from '../api/mapApiInterface';
import Highlights from '../components/HomePage/Highlights';
import MainBanner from '../components/HomePage/MainBanner';
import SecondaryBanner from '../components/HomePage/SecondaryBanner';
import { MainContainer } from '../styles';

const HomePage = () => {
  const cart = useSelector((state) => state.cart.cart);
  const [mainBanner, setMainBanner] = useState([]);
  const [campaigns, setCampaigns] = useState(null);
  const [highlights, setHighlights] = useState([]);
  const navigate = useNavigate();
  const pageName = 'homepage';

  useEffect(() => {
    getPage(pageName)
      .then((page) => {
        const { mainBanner, campaigns, products } = page;
        const mappedProducts = products.map((product) => mapProduct(product));
        setMainBanner(mainBanner);
        setCampaigns(campaigns);
        setHighlights(mappedProducts);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }, [navigate, cart]);

  return (
    <MainContainer paddingTop='0 !important'>
      {mainBanner && <MainBanner mainBanner={mainBanner} />}
      {campaigns && <SecondaryBanner data={campaigns} />}
      {!!highlights.length && <Highlights highlights={highlights} />}
    </MainContainer>
  );
};

export default HomePage;
