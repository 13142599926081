import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as IconMultibancoColored } from '../../../assets/multibanco_colored.svg';
import { formatCurrency } from '../../../constants/utils';
import { FONT_STYLE } from '../../../variables';
import Modal, { ModalActions, ModalContent } from '../../Modal';

const PaymentDataModal = ({ modalIsOpened, setModalIsOpened, paymentData }) => {
  return (
    <Modal
      isOpened={modalIsOpened}
      setIsOpened={() => setModalIsOpened(false)}
      hideCloseButton
      muiComponentNativeProps={{
        PaperProps: {
          sx: {
            maxWidth: '100%',
            width: '400px',
            padding: 4,
            alignItems: 'center',
          },
        },
      }}
    >
      <ModalContent
        sx={{
          textAlign: 'center',
          marginTop: 0,
          marginBottom: 3,
        }}
      >
        <IconMultibancoColored
          width={60}
          height={60}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 10,
            marginTop: 3,
          }}
        >
          <Typography fontWeight={FONT_STYLE.bold}>Entidade</Typography>
          <Typography sx={{ marginTop: { xs: 0.5 } }}>
            {paymentData.entity}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 8,
            marginTop: 0.5,
          }}
        >
          <Typography fontWeight={FONT_STYLE.bold}>Referência</Typography>
          <Typography sx={{ marginTop: { xs: 0.5 } }}>
            {paymentData.reference}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 8,
            marginTop: 0.5,
          }}
        >
          <Typography fontWeight={FONT_STYLE.bold}>Montante</Typography>
          <Typography sx={{ marginTop: { xs: 0.5 } }}>
            {formatCurrency(Number(paymentData.amount))}
          </Typography>
        </Box>
      </ModalContent>
      <ModalActions
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 0,
        }}
      >
        <Typography
          variant='xsmall'
          sx={{
            textAlign: 'center',
            lineHeight: 1.75,
          }}
          dangerouslySetInnerHTML={{
            __html:
              '* A referência gerada é válida por 48 horas </br> ** Os dados para pagamento foram enviados para o seu email',
          }}
        />
        <Button
          title='Fechar'
          variant='contained'
          color='secondary'
          onClick={() => setModalIsOpened(false)}
        >
          Fechar
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default PaymentDataModal;
