import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { FiSearch } from 'react-icons/fi';
import { useOutletContext } from 'react-router-dom';

const AccountFilters = ({
  type,
  selectedStatus,
  setSelectedStatus,
  selectedYear,
  setSelectedYear,
  _docType,
  setDocType,
  search,
  setSearch,
}) => {
  const [XSMALL_DEVICES] = useOutletContext();

  // BEGIN status filter
  const statusArray = [];

  // type can be orders or receipts
  const presentationalStatus = type.map((type) => {
    const inArray = statusArray.find((_status) => _status === type.status);

    if (inArray) {
      return null;
    }
    statusArray.push(type.status);

    return (
      <MenuItem
        key={type.reference}
        value={type.status}
      >
        {type.status}
      </MenuItem>
    );
  });

  const isValidStatus = statusArray.includes(selectedStatus);

  function onStatusChange(e) {
    setSelectedStatus(e.target.value);
  }

  // END status filter

  // BEGIN year filter
  const uniqueDates = new Set();

  type.forEach((type) => uniqueDates.add(new Date(type.date).getFullYear()));

  const dates = Array.from(uniqueDates);

  const sortedDates = dates.sort().reverse();

  const presentationalDates = sortedDates.map((date) => {
    return (
      <MenuItem
        key={date}
        value={date}
      >
        {date}
      </MenuItem>
    );
  });

  const isValidYear = dates.includes(selectedYear);

  function onYearChange(e) {
    setSelectedYear(e.target.value);
  }
  // END year filter

  // BEGIN docType filter

  function onDocTypeChange(e) {
    setDocType(e.target.value);
  }
  // END docType filter

  return (
    <Box
      component='form'
      sx={{
        marginBottom: 1,
        display: 'flex',
        flexDirection: XSMALL_DEVICES && 'column',
        gap: 1,
      }}
    >
      <FormControl sx={{ width: XSMALL_DEVICES ? '100%' : '20%' }}>
        <InputLabel>Estado</InputLabel>
        <Select
          label='Estado'
          fullWidth
          value={!isValidStatus ? 'all' : selectedStatus}
          onChange={onStatusChange}
          sx={{
            backgroundColor: '#fff',
          }}
        >
          <MenuItem value='all'>Todos</MenuItem>

          {presentationalStatus}
        </Select>
      </FormControl>

      <FormControl sx={{ width: XSMALL_DEVICES ? '100%' : '20%' }}>
        <InputLabel sx={{ marginBottom: 1 }}>Ano</InputLabel>
        <Select
          label='Ano'
          value={!isValidYear ? 'all' : selectedYear}
          onChange={onYearChange}
          sx={{
            backgroundColor: '#fff',
          }}
        >
          <MenuItem value='all'>Todos</MenuItem>

          {presentationalDates}
        </Select>
      </FormControl>

      <FormControl sx={{ width: XSMALL_DEVICES ? '100%' : '20%' }}>
        <InputLabel sx={{ marginBottom: 1 }}>Tipo de documento</InputLabel>
        <Select
          label='Tipo de documento'
          value={!isValidYear ? 'all' : selectedYear}
          onChange={onDocTypeChange}
          sx={{
            backgroundColor: '#fff',
          }}
        >
          <MenuItem value='all'>Todos</MenuItem>

          {presentationalDates}
        </Select>
      </FormControl>

      <TextField
        label='Procurar'
        value={search}
        onChange={(e) => setSearch(e.target.value.trim())}
        InputProps={{
          endAdornment: <FiSearch />,
        }}
        sx={{
          width: XSMALL_DEVICES ? '100%' : '20%',
          marginTop: 0,
          marginLeft: 'auto',
        }}
      />
    </Box>
  );
};

export default AccountFilters;
