import { Box, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { imageOnError } from '../../../../helpers';
import { BadgeDiscount } from '../../../../styles';
import {
  CUSTOM_THEME_COLORS,
  FONT_STYLE,
  PAGES_SLUGS,
} from '../../../../variables';
import ProductStock from '../../../ProductStock';

const SummaryProductInfo = ({
  slug,
  images,
  title,
  reference,
  showStockAvailability = true,
  stock,
  itemsPerBox,
  isProductOffer,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          marginRight: 2,
          display: ' flex',
          alignItems: ' center',
          justifyContent: 'center',
          border: `1px solid ${CUSTOM_THEME_COLORS.gray}`,
          borderRadius: theme.shape.borderRadius / 10,
          overflow: 'hidden',
          flexShrink: 0,
        }}
        component={Link}
        to={`${PAGES_SLUGS.productDetail}/${slug}`}
        title={title}
      >
        <Box
          component='img'
          src={images[0]}
          onError={imageOnError}
          alt={title}
          sx={{
            width: { xs: '60px', md: '100px' },
            objectFit: 'contain',
            aspectRatio: '1',
          }}
        />
      </Box>

      <Box
        sx={{
          textAlign: 'left',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            marginBottom: 0.5,
          }}
        >
          <Typography
            variant='link'
            sx={{ fontSize: '0.95rem', fontWeight: FONT_STYLE.bold }}
            component={Link}
            to={`${PAGES_SLUGS.productDetail}/${slug}`}
            title={title}
          >
            {title}
          </Typography>
          {!!isProductOffer && <BadgeDiscount>OFERTA</BadgeDiscount>}
        </Box>

        <Box
          sx={{
            marginTop: 0.5,
          }}
        >
          {reference && (
            <Typography
              variant='xsmall'
              sx={{ color: CUSTOM_THEME_COLORS.darkGray }}
            >{`Ref: ${reference}`}</Typography>
          )}
          {!!itemsPerBox && (
            <Typography
              variant='xsmall'
              sx={{
                color: CUSTOM_THEME_COLORS.darkGray,
              }}
            >
              Embalagem: {itemsPerBox} uni.
            </Typography>
          )}
        </Box>

        {showStockAvailability && (
          <Box marginTop={0.5}>
            <ProductStock stock={stock} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SummaryProductInfo;
