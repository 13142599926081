import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import SummaryTable from './SummaryTable/SummaryTable';
import DelayedPaymentsModal from '../DelayedPaymentsModal';

const CartSummary = ({
  setActiveStep,
  cartItems,
  cartSubtotal,
  selectedClient,
  role,
  delayedPayments,
}) => {
  const [modalIsOpened, setModalIsOpened] = useState(false);

  return (
    <>
      <Typography
        variant='h2'
        sx={{
          textAlign: 'center',
          marginBottom: { xs: 3, md: 4 },
        }}
      >
        Resumo
      </Typography>

      <SummaryTable
        cartItems={cartItems}
        cartSubtotal={cartSubtotal}
        selectedClient={selectedClient}
        role={role}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 2,
          marginTop: 3,
        }}
      >
        <Button
          variant='contained'
          color='secondary'
          href='/'
          title='Voltar à Loja'
        >
          Voltar à Loja
        </Button>

        <Button
          variant='contained'
          onClick={() =>
            delayedPayments ? setModalIsOpened(true) : setActiveStep(1)
          }
          title='Continuar'
        >
          Continuar
        </Button>
      </Box>

      <DelayedPaymentsModal
        modalIsOpened={modalIsOpened}
        setModalIsOpened={setModalIsOpened}
      />
    </>
  );
};

export default CartSummary;
