import {
  Autocomplete,
  Box,
  IconButton,
  MenuItem,
  TextField,
  useTheme,
} from '@mui/material';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';
import { getSearchedProducts } from '../../api/products';
import { CUSTOM_THEME_COLORS, PAGES_SLUGS } from '../../variables';

const SearchBar = () => {
  const SEARCH_ID = 'search';
  const navigate = useNavigate();
  const theme = useTheme();

  const [dropdownProducts, setDropdownProducts] = useState([]);
  const [shownProducts, setShownProducts] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const getOptionsDebounced = debounce(async (queryParam) => {
    const searchedProducts = await getSearchedProducts({
      searchParams: { query: queryParam },
    })
      .then(({ products }) => {
        return products.slice(0, 5);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    setDropdownProducts(searchedProducts);
  }, 500);

  useEffect(() => {
    const mappedProducts = dropdownProducts.map(({ id, slug, title }) => ({
      id: id,
      slug: slug,
      label: title,
    }));
    setShownProducts(mappedProducts);
  }, [dropdownProducts]);

  const handleInputChange = (e, newInputValue, reason) => {
    if (reason === 'reset') {
      setDropdownProducts([]);
      setInputValue('');
      return;
    }

    setInputValue(newInputValue);

    if (!newInputValue) {
      setDropdownProducts([]);
      return;
    }

    getOptionsDebounced(newInputValue);
  };

  const navigateToSearchPage = (id) => {
    if (id === SEARCH_ID && inputValue) {
      navigate({
        pathname: PAGES_SLUGS.search,
        search: createSearchParams({
          query: inputValue,
        }).toString(),
      });
    }
    setDropdownProducts([]);
    setInputValue('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') navigateToSearchPage(SEARCH_ID);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: { xs: '100%', md: '350px', lg: '400px' },
        marginTop: { xs: 1.5, md: 0 },
      }}
    >
      <Autocomplete
        disableClearable
        freeSolo
        fullWidth
        options={shownProducts}
        filterOptions={(options) => options}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label='O que está à procura?'
            onKeyPress={handleKeyPress}
            sx={{ color: 'black', marginTop: 0 }}
          />
        )}
        renderOption={(params, { id, slug, label }) => (
          <MenuItem
            {...params}
            component={Link}
            to={`${PAGES_SLUGS.productDetail}/${slug}`}
            sx={{
              width: '100%',
              fontSize: '0.95rem',
              fontWeight: id === SEARCH_ID ? 500 : 400,
            }}
          >
            {label}
          </MenuItem>
        )}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: CUSTOM_THEME_COLORS.gray,
          },
          '& .MuiFormLabel-root, & .MuiOutlinedInput-root': {
            fontSize: '0.85rem',
            lineHeight: 1,
          },
          '& .MuiFormLabel-root.Mui-focused, & .MuiFormLabel-root.MuiFormLabel-filled':
            {
              lineHeight: 1.75,
            },
          '& .MuiOutlinedInput-root': {
            paddingY: 1.5,
            paddingX: 2,
          },
          '& .MuiAutocomplete-input.MuiOutlinedInput-input': {
            padding: 0,
          },
        }}
      />
      <IconButton
        onClick={() => navigateToSearchPage(SEARCH_ID)}
        title='Pesquisar'
        sx={{
          position: 'absolute',
          right: theme.spacing(1),
          color: 'black',
        }}
      >
        <FiSearch size={20} />
      </IconButton>
    </Box>
  );
};

export default SearchBar;
