import { Typography } from '@mui/material';
import React from 'react';
import { CUSTOM_THEME_COLORS } from '../../variables';
import Modal, { ModalContent, ModalTitle } from '../Modal';

const CampaignsModal = ({ isModalOpen, setIsModalOpen, modalContent }) => {
  return (
    <Modal
      isOpened={isModalOpen}
      setIsOpened={setIsModalOpen}
      muiComponentNativeProps={{
        PaperProps: {
          sx: {
            width: '100%',
            maxWidth: '500px',
            paddingX: { xs: 3, md: 4 },
            paddingY: { xs: 4 },
          },
        },
      }}
    >
      <ModalTitle
        sx={{
          fontSize: { xs: '1.3rem', md: '1.5rem' },
          fontWeight: 700,
          marginBottom: 1.5,
        }}
      >
        {modalContent.title}
      </ModalTitle>
      <ModalContent
        sx={{
          display: 'grid',
          gap: 1.5,
          margin: 0,
        }}
      >
        {!!modalContent.description && (
          <Typography variant='small'>{modalContent.description}</Typography>
        )}
        <Typography
          variant='small'
          sx={{
            color: CUSTOM_THEME_COLORS.primary,
          }}
        >
          {`Promoção válida de ${modalContent.startDate} a ${modalContent.endDate}`}
        </Typography>
      </ModalContent>
    </Modal>
  );
};

export default CampaignsModal;
