import { Box, Container, Typography } from '@mui/material';
import ProductsCarousel from '../Carousel/ProductsCarousel';

const Highlights = ({ highlights }) => {
  return (
    <Container sx={{ paddingY: { xs: 2, md: 4 } }}>
      <Typography
        variant='h2'
        marginBottom={1}
      >
        Produtos em destaque
      </Typography>
      <Typography marginBottom={2}>
        Aqui poderá encontrar os nossos produtos destacados.
      </Typography>
      <Box sx={{ marginTop: 4 }}>
        <ProductsCarousel products={highlights} />
      </Box>
    </Container>
  );
};

export default Highlights;
