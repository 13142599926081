import { LoadingButton } from '@mui/lab';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeLogin } from '../../../api/login';
import PopupNotification from '../../PopupNotification/PopupNotification';

const AccessForm = () => {
  const navigate = useNavigate();
  const personalInfo = useSelector((state) => state.user.personalInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);
  const [userData, setUserData] = useState({
    email: personalInfo.email,
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: 'Palavra-passe alterada com sucesso',
    errorMessage: '',
  });

  function handleChange(e) {
    const { name, value } = e.target;

    setUserData((values) => ({ ...values, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    if (!userData.email || !userData.oldPassword || !userData.newPassword) {
      setIsLoading(false);
      return;
    }

    changeLogin(userData)
      .then(() => {
        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
      })
      .catch(({ response: { data, status } }) => {
        if (status === 401) {
          navigate('/login');
        }
        //  current password is incorrect
        if (status === 403) {
          setNotificationProps((prevState) => ({
            ...prevState,
            errorMessage: 'A palavra-passe atual está incorreta',
          }));
        }
        //  new password isnt a valid value
        if (status === 422) {
          setNotificationProps((prevState) => ({
            ...prevState,
            errorMessage: data?.errors?.[0]?.msg,
          }));
        }
        if (status >= 500) {
          setNotificationProps((prevState) => ({
            ...prevState,
            errorMessage: data?.msg,
          }));
        }

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'error',
        }));
      })
      .then(() => {
        setIsLoading(false);
        setUserData({
          email: personalInfo.email,
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      });
  }

  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          width: { xs: '100%', lg: '50%' },
        }}
      >
        <TextField
          type='email'
          name='email'
          label='Email'
          value={userData.email}
          fullWidth
          required
          disabled
        />

        <TextField
          fullWidth
          required
          name='oldPassword'
          label='Palavra-passe atual'
          type={showOldPassword ? 'text' : 'password'}
          value={userData.oldPassword}
          error={!userData.oldPassword}
          helperText={!userData.oldPassword && 'Campo obrigatório.'}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => setShowOldPassword(!showOldPassword)}
                >
                  {showOldPassword ? (
                    <FiEye size={18} />
                  ) : (
                    <FiEyeOff size={18} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          required
          name='newPassword'
          label='Nova palavra-passe'
          type={showNewPassword ? 'text' : 'password'}
          value={userData.newPassword}
          error={!userData.newPassword}
          helperText={!userData.newPassword && 'Campo obrigatório.'}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? (
                    <FiEye size={18} />
                  ) : (
                    <FiEyeOff size={18} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          required
          name='confirmPassword'
          label='Confirmar nova palavra-passe'
          type={showConfirmationPassword ? 'text' : 'password'}
          value={userData.confirmPassword}
          error={
            !userData.confirmPassword ||
            userData.confirmPassword !== userData.newPassword
          }
          helperText={
            !userData.confirmPassword
              ? 'Campo obrigatório.'
              : userData.confirmPassword !== userData.newPassword
              ? 'As palavras-passe não coincidem.'
              : null
          }
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() =>
                    setShowConfirmationPassword(!showConfirmationPassword)
                  }
                >
                  {showConfirmationPassword ? (
                    <FiEye size={18} />
                  ) : (
                    <FiEyeOff size={18} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          variant='contained'
          type='submit'
          title='Guardar alterações'
          disabled={
            !userData.oldPassword ||
            !userData.newPassword ||
            !userData.confirmPassword ||
            userData.confirmPassword !== userData.newPassword
              ? true
              : false
          }
          sx={{
            display: 'flex',
            marginTop: 3,
          }}
          loading={isLoading}
        >
          Guardar alterações
        </LoadingButton>
      </Box>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default AccessForm;
