import { Box, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { IconContext } from 'react-icons';
import { FiChevronRight } from 'react-icons/fi';
import store_location from '../assets/company-location.png';
import { COMPANY_INFO, FONT_STYLE } from '../variables';

const StoreLocations = () => {
  const {
    address: { street, zipCode, city },
    mobile,
    phone,
    fax,
    email,
  } = COMPANY_INFO;

  return (
    <Grid container>
      <Grid
        xs={12}
        md={5}
      >
        <Typography sx={{ marginTop: { xs: 2, md: 3 } }}>
          {street} <br /> {zipCode}
          <br />
          {city}
        </Typography>

        {mobile.value && (
          <Box sx={{ marginTop: 1.5 }}>
            <Typography
              component={'a'}
              href={`tel:${mobile.value}`}
              title={mobile.label}
            >
              <strong>Tel. </strong>
              {mobile.label}
            </Typography>
            <Typography variant='xsmall'>
              (Chamada para a rede móvel nacional)
            </Typography>
          </Box>
        )}

        {phone.value && (
          <Box sx={{ marginTop: 1.5 }}>
            <Typography
              component={'a'}
              href={`tel:${phone.value}`}
              title={phone.label}
            >
              <strong>Telf. </strong>
              {phone.label}
            </Typography>
            <Typography variant='xsmall'>
              (Chamada para a rede fixa nacional)
            </Typography>
          </Box>
        )}

        {fax.value && (
          <Typography marginTop={1.5}>
            <strong>Fax. </strong>
            {fax.label}
          </Typography>
        )}

        {email && (
          <Typography
            component={'a'}
            href={`mailto:${email}`}
            title={email}
            sx={{
              display: 'block',
              marginTop: 1.5,
            }}
          >
            <strong>E. </strong>
            {email}
          </Typography>
        )}
        <Typography
          component={'a'}
          href={COMPANY_INFO.mapsLink}
          target='_blank'
          rel='noreferrer'
          title='Obter direções'
          sx={{
            display: 'block',
            fontWeight: FONT_STYLE.bold,
            marginTop: 3,
          }}
        >
          Obter direções
          <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
            <FiChevronRight />
          </IconContext.Provider>
        </Typography>
      </Grid>
      <Grid
        md={6}
        mdOffset={1}
        sx={{
          display: { xs: 'none', md: 'initial' },
        }}
      >
        <Link
          href={COMPANY_INFO.mapsLink}
          target='_blank'
          rel='nofollow'
          sx={{ flex: 1 }}
        >
          <img
            src={store_location}
            width='100%'
            alt='Localização Frescas Surpresas'
          />
        </Link>
      </Grid>
    </Grid>
  );
};

export default StoreLocations;
