import { CssBaseline } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Cookies from './components/Cookies/Cookies';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  return (
    <CssBaseline>
      <div className='app'>
        <Header />
        <Outlet />
        <Footer />
        <Cookies />
      </div>
    </CssBaseline>
  );
};

export default App;
