import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setPriceVisibility } from '../../state/userSlice';
import { useCookies } from 'react-cookie';

const ProductsListTitle = ({ title, productsTotal }) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [cookies] = useCookies(['userToken']);
  const { priceIsVisible } = user;
  const MOBILE_DEVICES = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        display: MOBILE_DEVICES ? 'flex' : 'block',
        alignItems: 'center',
        width: MOBILE_DEVICES ? '100%' : 'auto',
        justifyContent: MOBILE_DEVICES ? 'space-between' : '-moz-initial',
        paddingInline: MOBILE_DEVICES ? 1 : 0,
        flexDirection: 'row',
      }}
    >
      <Typography
        variant='h2'
        sx={{
          flex: { md: '1' },
          fontSize: '1.5rem',
        }}
      >
        {!!title && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: MOBILE_DEVICES ? 'space-between' : '-moz-initial',
            }}
          >
            {capitalize(title)}
            {productsTotal !== null && (
              <Typography
                variant='overline'
                marginLeft={1}
                sx={{
                  textTransform: 'initial',
                }}
              >
                (
                {`${productsTotal} ${
                  productsTotal === 1 ? 'produto' : 'produtos'
                }`}
                )
              </Typography>
            )}
          </Box>
        )}
      </Typography>
      {MOBILE_DEVICES && !!cookies.userToken && (
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={!priceIsVisible} />}
            title='Ocultar preço'
            label='Ocultar preço'
            slotProps={{ typography: { variant: 'small' } }}
            onChange={() => dispatch(setPriceVisibility(!priceIsVisible))}
            sx={{
              marginRight: 0,
              marginLeft: MOBILE_DEVICES ? 2 : 0,
              alignSelf: 'end',
              fontSize: '15px',
            }}
          />
        </FormGroup>
      )}
    </Box>
  );
};

export default ProductsListTitle;
