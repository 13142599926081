import { Box, TextField } from '@mui/material';

const SellerInfo = ({ data }) => {
  const { name, email, phone } = data;

  return (
    <Box>
      <TextField
        label='Nome'
        defaultValue={name}
        disabled
        fullWidth
      />
      <TextField
        label='Telemóvel'
        defaultValue={phone}
        disabled
        fullWidth
      />
      <TextField
        label='Email'
        defaultValue={email}
        disabled
        fullWidth
      />
    </Box>
  );
};

export default SellerInfo;
