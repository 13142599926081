import { Box, Dialog, MenuItem, Select, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addProductToShoppingList } from '../../../api/products';
import { setShoppingLists } from '../../../state/shoppingListsSlice';
import { StyledTextButton } from '../../../styles';
import { FONT_STYLE, PAGES_SLUGS } from '../../../variables';
import PopupNotification from '../../PopupNotification/PopupNotification';

const ShoppingListSelectorModal = ({
  isOpen,
  setIsOpen,
  shoppingLists,
  productId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: 'Produto adicionado à lista de compras',
    errorMessage: '',
  });
  const [selectShoppingList, setSelectShoppingList] = useState(
    shoppingLists?.[0]?._id
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectShoppingList) return;

    //  check IF product added already exists in this list
    const productAlreadyExists = shoppingLists
      .filter((list) => list._id === selectShoppingList)?.[0]
      ?.products?.find((product) => product?._id === productId);

    //  IF product exists » show message and return
    if (productAlreadyExists) {
      setNotificationProps((prevState) => ({
        ...prevState,
        isOpened: true,
        type: 'error',
        errorMessage: 'Este produto já existe na lista selecionada',
      }));

      return;
    }

    addProductToShoppingList(selectShoppingList, productId)
      .then((lists) => {
        dispatch(setShoppingLists(lists));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));
          }
        }
      );

    setIsOpen(false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '400px',
            padding: 3,
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: FONT_STYLE.bold,
            textTransform: 'uppercase',
            marginBottom: 2,
          }}
        >
          Selecione a Lista de Compras
        </Typography>

        {selectShoppingList ? (
          <Box
            component='form'
            onSubmit={handleSubmit}
          >
            <Select
              fullWidth
              value={selectShoppingList}
              onChange={(e) => setSelectShoppingList(e.target.value)}
            >
              {shoppingLists.map(({ name, _id }) => (
                <MenuItem
                  key={_id}
                  value={_id}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                marginTop: 3,
              }}
            >
              <StyledTextButton
                variant='contained'
                title='Cancelar'
                color='secondary'
                onClick={() => setIsOpen(false)}
              >
                Cancelar
              </StyledTextButton>

              <StyledTextButton
                variant='contained'
                title='Adicionar'
                type='submit'
              >
                Adicionar
              </StyledTextButton>
            </Box>
          </Box>
        ) : (
          <Box>
            <Typography variant='small'>
              De momento não existem Listas de Compras criadas.
            </Typography>

            <StyledTextButton
              variant='contained'
              component={Link}
              to={`${PAGES_SLUGS.account}/${PAGES_SLUGS.myAccount.shoppingList}`}
              title='Criar lista de compras'
              sx={{
                marginTop: 3,
              }}
            >
              Criar lista de compras
            </StyledTextButton>
          </Box>
        )}
      </Dialog>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default ShoppingListSelectorModal;
