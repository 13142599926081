import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/system';
import React from 'react';
import { CUSTOM_THEME_COLORS } from '../../variables';

const NotificationBar = () => {
  return (
    <Box
      sx={{
        backgroundColor: CUSTOM_THEME_COLORS.lightPrimary,
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box
              sx={{
                paddingY: 1,
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Typography
                variant='link'
                component='a'
                href='https://loja.frescassurpresas.com'
                title='loja.frescassurpresas.com'
                sx={{
                  fontSize: '0.925rem',
                  color: 'white',
                }}
              >
                É um consumidor final? Por favor aceda aqui a
                <strong> loja.frescassurpresas.com</strong>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NotificationBar;
